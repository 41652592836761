import { cookie } from "./utils/common";

import "./permission"
/**
 * 记录来路
 * https://www.jackery.com/pages/user-guides?param=xxxxx
 * =>
 * {
 *  refName: 'www.jackery.com', refNation: "en-US", refPath: "/pages/user-guides",
 *  refSite: "https://www.jackery.com/", refUrl: "https://www.jackery.com/pages/user-guides?param=xxxxx"
 * }
 * 记录jackeryRef, 表示的是从shopify来登录的参数
 * shopify链接规则： base64({ "u": "https://www.jackery.com", "id": "shopify id", "back": "id系统路由目标" })
 * http://192.168.34.49:3104/login?jackeryRef=eyd1JzogJ2h0dHBzOi8vd3d3LmphY2tlcnkuY29tJywgJ24nOiAndXNhJ30=
 */
(function () {
  const _lang = cookie.get("_jk_lang") || localStorage.getItem('JACKERY_ID_LANG') || 'en-US'
  document.querySelector("html").setAttribute("lang", _lang.substring(0, 2))


  // jackery_account_env
  if(import.meta.env.MODE === 'production') {window.jackery_account_env = "prod"}
  if(import.meta.env.MODE === 'demo') {window.jackery_account_env = "demo"}
  if(import.meta.env.MODE === 'uat') {window.jackery_account_env = "uat"}
  console.log('welcome to jackery. For more information about us, please follow https://www.jackery.com/pages/about-us');

  if (_lang === 'ja-JP') {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = '/jp-font.css';
    document.head.appendChild(link);
  }
})();


import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index';
import store from './store';
import vueI18n from './lang';

import 'ant-design-vue/es/message/style/css';
import 'ant-design-vue/es/notification/style/css';
import 'ant-design-vue/es/badge/style/css';

// reset style
import './assets/main.less';
import './utils/CDP.js'

import '@unocss/reset/tailwind-compat.css'
import 'virtual:uno.css'


/** 页面首次加载时, 处理vuex状态, 分配路由, 设置主题等 start ----------- */
// localStorage.getItem('JACKERY_ID_USER') && store.commit('changeUser', JSON.parse(localStorage.getItem('JACKERY_ID_USER')));
// let meta = document.createElement('meta');
// meta.name = "version";
// meta.content = `v${config.version}`;
// document.getElementsByTagName('head')[0].appendChild(meta);
/** 页面首次加载时, 处理vuex状态, 分配路由, 设置主题等 end ----------- */
const app = createApp(App);

app.use(store).use(router).use(vueI18n).mount('#app')
