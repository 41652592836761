export default {
  "login": {
    "sign": "Iniciar sesión",
    "login": "Iniciar sesión de inmediato",
    "remember": "Recordarme",
    "about": "Sobre Nosotros",
    "illegal_email": "Formato de correo inválido",
    "illegal_password": "Tu contraseña requiere de 6 a 16 dígitos, y debe contener letras y números.",
    "password_matching": "Método de entrada inconsistente antes y después de la contraseña.",
    "enterEmail": "Correo/Jackery ID",
    "enter_code": "Por favor, ingresa Código",
    "forgot": "¿Olvidaste tu contraseña?",
    "create": "Cree una cuenta",
    "thirdLogin": "O inicie sesión con",
    "accountActivate": "Cuenta Activada",
    "bindEmail": "Vincula tu correo",
    "error": "Inicio de sesión fallido, inténtalo de nuevo más tarde",
    "quickLogin": "Inicio de sesión sin contraseña",
    "quickLoginA": "Iniciar sesión sin contraseña"
  },
  "policies": {
    "content": "Al iniciar sesión mediante un tercero, se dará por sentado que has leído y aceptado nuestra",
    "policy-link": "https://es.jackery.com/policies/privacy-policy",
    "policy": "Política de privacidad",
    "and": ", y los",
    "terms-link": "https://es.jackery.com/policies/terms-of-service",
    "terms": "Condiciones de uso",
    "comma": ".",
    "footer": "Jackery usará la información proviste por el tercero que hayas elegido para registrarte o iniciar sesión."
  },
  "reset": {
    "modify": "Cambia tu contraseña",
    "back": "Volver al inicio de sesión ",
    "success": "Se creó una contraseña nueva",
    "referrer": "Volverás a la página original en",
    "seconds": "segundos"
  },
  "register": {
    "title": "Registrar",
    "enterEmail": "Por favor, ingresa Correo",
    "agree": "He leído y aceptado respetar la política de ",
    "agree_after": "de uso de Jackery.",
    "getDeals": "Acepto recibir información sobre las últimas ofertas, promociones, e información de productos.",
    "login": "Inicio rápido de sesión",
    "success": "Tu cuenta '{email}' ha sido registrada",
    "welcomeEmail": "Se ha enviado un mensaje de bienvenida a tu correo."
  },
  "header": {
    "logout": 'Cerrar sesión',
    "userCenter": "Centro de cuenta",
    "personalCenter": "Mi cuenta",
    "shopping": "Ir a la tienda",
    "setting": "Configuraciones de la cuenta",
    "myJackery": "Centro de cuenta",
    "jackeryID": "Mi cuenta",
    "wishlist": "Mi lista de deseos",
  },
  "footer": {
    "cookieContent": "Usamos cookies, y cookies de terceros, para garantizar que el sitio funcione con fluidez, analizar el tráfico, ofrecer una experiencia social mejorada, y personalizar el contenido y las publicidades.",
    "cookieUrl": "#",
    "learn": "Ver más",
    "accept": "Aceptar cookies",
    "copyright": "Copyright © {date} Jackery Technology GmbH todos los derechos reservados.",
    "sitemap-link": "#",
    "sitemap": "Mapa del sitio"
  },
  "account": {
    "welcome": "Bienvenido",
    "email": "Correo",
    "registerEmail": "Correo de registro",
    "bindNewRegisterEmail": "Nueva dirección de correo",
    "emailIsEqual": "El nuevo correo es el mismo que el original, no necesitas modificarlo.",
    "settings": "Configuraciones de la cuenta",
    "settingTips": "Gestiona la información de tu cuenta y los detalles de seguridad",
    "titleProfiles": "Mi Perfil",
    "titleSecurity": "Seguridad de la cuenta",
    "titleAuthorization": "Vincula tu cuenta",
    "nickname": "Nombre de usuario",
    "name": "Nombre",
    "gender": "Género",
    "birthday": "Fecha de nacimiento",
    "contactEmail": "Dirección de correo",
    "enterprise": "Cuenta de empresa",
    "setted": "Configurar",
    "unset": "Cancelar la configuración",
    "password": "Contraseña",
    "pop_password": "Configurar una contraseña",
    "apply": "Aplicar",
    "secrecy": "Confidencialidad",
    "male": "Masculino",
    "female": "Femenino",
    "currentPassword": "Contraseña actual",
    "newPassword": "Nueva contraseña",
    "newShowAgain": "Never show again",
    "againPassword": "Nunca mostrar de nuevo",
    "jackeryIdTips": "El Jackery ID puede ser editado una sola vez. El Jackery ID debe contener 6 caracteres como mínimo, puede incluir números y letras, pero no puede exceder los 32 caracteres.",
    "birthdayTips": "Ingresa tu fecha de nacimiento para que podamos ofrecerme nuestro servicio con la mejor calidad posible. Mantendremos la confidencialidad de toda tu información personal de forma estricta.",
    "nickNameTips": "El nombre de usuario puede tener letras y números, y no puede superar los 32 caracteres.",
    "nameTips": "Te recomendados encarecidamente que use tu nombre verdadero para que podamos contactarte. Mantendremos la confidencialidad de toda la información personal de forma estricta.",
    "genderTips": "Ingresa tu nombre verdadero para que podamos ofrecerme nuestro servicio con la mejor calidad posible. Mantendremos la confidencialidad de toda tu información personal de forma estricta.",
    "contactEmailTips": "Si usas tu correo de uso diario, te pondremos contactar de manera más conveniente. Mantendremos la confidencialidad de tu información personal de forma estricta.",
    "phoneNumberTips": "Si usas tu número de contacto de uso diario, te pondremos contactar de manera más conveniente. Mantendremos la confidencialidad de tu información personal de forma estricta.",
    "phoneNumberTips2": "Solo están soportados los números, corchetes y guiones.",
    "enterpriseNameTips": "Si usas una cuenta de empresa, bríndanos tu información de empresa pertinente para que podamos ofrecerme nuestro servicio con la mejor calidad posible.",
    "emailTips": "Tu cuenta de inicio de sesión será el correo con el que te registraste. Para proteger tu cuenta de usuario, se solicitará una verificación de seguridad para registrar el correo.",
    "passwordTips": "Por razones de seguridad, use una contraseña más fuerte.",
    "location": "Ubicación",

    "contactPhone": "Teléfono",
    "avatarSettings": "Configuración de avatares",
    "choosePicture": "Elegir imagen",
    "myProfile": "Mi Perfil",
    "changePassword": "Cambiar contraseña",
    "creatPassword": "Crear contraseña",
    "firstName": "Nombre",
    "lastName": "Apellido",
    "editPasswordTips": "Tu contraseña debe tener entre 6 a 16 caracteres, y debe incluir letras y números. Por razones de seguridad, crea una contraseña fuerte.",
    "subscriberTip": "Obtener {num} puntos por la primera suscripción de correo y las últimas noticias y ofertas de Jackery. ",
    "birthdayTip1": "Obtenga {num}  puntos al presentarse en tu cumpleaños.",
    "birthdayTip2": "Obtenga {num} puntos por cada cumpleaños y gane doble puntos de compra.",
    "birthdayTip3": "Solo una oportunidad para cambiar.",
    "phoneTip": "Obtener {num }puntos por mi primera suscripción por SMS y recibir las últimas noticias y ofertas de Jackery. Aceptar los Términos y Servicios y la Política de Privacidad de Jackery.",
    "countryRegion": "País Región",
    "successTip1": "Envió su perfil con éxito, ganará {num} puntos de Jackery dentro de las 24 horas",
    "successTip": "Envió su perfil con éxito.",
    "errorTip": "No pudo enviar, revise y vuelva a subirlo.",
    "copySuccessfully": "copiado"
  },
  "router": {
    "memberIndex": "MemberShip",
    "memberGuide": "MemberShip Guide",
    "home": "Hogar",
    "404": "404",
    "500": "500",
    "about": "sobre",
    "register": "Registrar",
    "login": "Inicio de",
    "reset": "Reiniciar",
    "dashboard": "Dashboard",
    "commonSetting": "Configuraciones generales",
    "account": "Configuraciones de la cuenta",
    "accountOverview": "Vista general",
    "myProfile": "Mi Perfil",
    "myOrders": "Mi Pedido",
    "personalSettings": "Configuraciones personales",
    "addressBook": "Mi Dirección",
    "accountSettings": "Configuraciones de la cuenta",
    "myDevices": "Mi Dispositivo",
    "activate": "Activa la cuenta",
    "myWallet": "Mi Billetera",
    "myService": "Mi servicio",
    "myReturn": "Mi Devolución",
    "myJackeryLife": "Jackery Life",
    "myWishlist": "Mi lista de deseos",
    "myReferral": "My Referral",
  },
  "description": {
    "memberIndex": "Jackery",
    "memberGuide": "Jackery",
    "home": "Jackery",
    "404": "404",
    "500": "500",
    "about": "sobre",
    "register": "Crea tu Jackery ID para convertirte en un miembro de Jackery.",
    "login": "Iniciar sesión como cliente",
    "reset": "Reiniciar",
    "dashboard": "Dashboard",
    "commonSetting": "Configuraciones generales",
    "account": "Centro de la cuenta de Jackery ID",
    "accountOverview": "Vista general",
    "myProfile": "Mi Perfil",
    "myOrders": "Mi Pedido",
    "personalSettings": "Configuraciones personales",
    "addressBook": "Mi Dirección",
    "accountSettings": "Configuraciones de la cuenta",
    "myDevices": "Mi Dispositivo",
    "activate": "Crea tu contraseña para activar tu cuenta",
    "registerActivate": "Haz clic abajo para activar tu cuenta. Una vez que actives tu cuenta, podrás usarla para iniciar sesión en nuestro sitio web.",
    "myReturn": "Mi Devolución",
    "myWallet": "Mi Billetera",
    "myService": "Mi servicio",
    "myJackeryLife": "Jackery Life",
  },
  "operate": {
    "enable": "Habilitar",
    "disable": "Deshabilitar",
    "confirm": "Confirmar",
    "cancel": "Cancelar",
    "remove": "Remover",
    "search": "Buscar",
    "reset": "Reiniciar",
    "add": "Añadir",
    "close": "Cerrar",
    "edit": "Editar",
    "yes": "Sí",
    "no": "No",
    "submit": "Enviar",
    "next": "Siguiente",
    "prev": "Prev",
    "back": "Pedido"
  },
  "tips": {
    "sure": "¿Estás seguro?",
    "placeholder": "Por favor, ingresa",
    "enterConsultContent": "Ingresa el contenido de tu consulta",
    "all": "Todo",
    "custom": "Personalizado",
    "pending": "Pendiente",
    "loading": "Procesando...",
    "loading2": "Procesando",
    "cancelled": "Cancelado",
    "loaded": "Proceso completado",
    "loaded2": "Completado",
    "changeShop": "Cambiando la tienda",
    "logoutConfirm": "¿Estás seguro de querer cerrar sesión?",
    "loggedOut": "Sesión cerrada con éxito",
    "refreshed": "Actualizado",
    "noDescribe": "Sin descripción",
    "reminder": "Un cordial recordatorio",
    "commError": "Algo salió mal",
    "reLogin": "Inicia sesión de nuevo",
    "verify": "Código de verificación",
    "sendVerify": "Se ha enviado el código de verificación, revisa tu bandeja de entrada o de correo no deseado.",
    "getVerifyCode": "Recibe un código de verificación",
    "verifyError": "Código de verificación incorrecto",
    "homePage": "Página de inicio",
    "404": "Esta página no existe.",
    "404Tips": "Revisa que la dirección IP del servidor sea correcta, o haz clic para visitar el enlace.",
    "500": "Ups, algo salió mal.",
    "500Tips": "Error del servidor,",
    "500Tips2": "inténtalo de nuevo más tarde.",
    "urlExpired": "El URL de verificación ha expirado",
    "accountNotActivated": "La cuenta no ha sido habilitada",
    "accountActivatedSuccess": "Haz activado tu cuenta.",
    "requestFrequently": "No realices muchas acciones seguidas, inténtalo de nuevo una vez hecha la verificación.",
    "submitSuccess": "Enviarlo con éxito.",
    "zipVerifyError": "Verificación del código postal fallida",
    "otherError": "Algo salió mal, ponte en contacto con el administrador. code: {code}",
    "wrongPasswordMultipleTimes": "Ingresaste muchas contraseñas incorrectas. Inténtalo de nuevo {minute} minutos más tarde",
    "networkError": "Error de Red",
    "thirdLoginError": "Inicio de sesión fallido, inténtalo de nuevo con tu cuenta.",
    "fileTypeError": "Error con el tipo de archivo, solo se permite subir: el archivo tipo {type}"
  },
  "placeholder": {
    "email": "Dirección de correo *",
    "emailOrJackeryID": "Correo/Jackery ID *",
    "password": "Contraseña *",
    "passwordAgain": "Confirma tu contraseña nueva *",
    "enterprise": "Nombre de la empresa",
    "code": "Código *",
    "firstName": "Nombres",
    "lastName": "Apellido",
    "select": "Selecciona",
    "address1": "Calle",
    "address2": "Departamento, habitación, etc. (opcional)",
    "country": "País/región",
    "province": "Provincia",
    "city": "Ciudad ",
    "region": "Región",
    "zip": "",
    "zipError": "Código Postal",
    "phone": "Tu teléfono ",
    "company": "Nombre de la empresa (opcional)",
    "isNotEmpty": "Esta opción no puede quedar vacía",
    "pobox": "No hacemos envíos a apatado de correos",
    "minChar2": "Un mínimo de 2 caracteres",
    "inputChar": "Debe tener un largo entre los {length} caracteres"
  },
  "order": {
    "sn": "Número de orden",
    "detail": "Ver detalles del envío",
    "productName": "Nombre del producto",
    "quantity": "Cantidad",
    "price": "Precio ",
    "status": "Estado de la orden",
    "consult": "Consultar",
    "contact": "Contáctanos",
    "total": "Total",
    "empty": "No se encontraron órdenes por el momento",
    "questionType": "Tipo de problema",
    "shipped": "Enviado",
    "return": "Devolver",
    "applied": "Devuelto y reembolsado",
    "revoke": "Revocar",
    "serviceType": "Seleccione el tipo de servicio",
    "serviceType_1": "Devuelto y reembolsado",
    "serviceType_2": "Cambios y reemplazos",
    "packageArrived": "¿Recibiste los artículos?",
    "reason": "Ingresa una razón",
    "reason_1": "Devolución De {day} Días Sin Preguntas",
    "reason_2": "Problemas de calidad",
    "reason_3": "Otro",
    "quality": "Ingresa el problema de calidad",
    "productSerialNumber": "Ingresa el número de serie del producto",
    "selectReturnProduct": "Selecciona el producto a devolver",
    "returnQuantity": "Ingresa el número de devolución/cambio",
    "shippingAddress": "Dirección de envío",
    "returnAddress": "Dirección de devolución",
    "revise": "Editar",
    "uploadVideo": "Subir video/archivo",
    "uploadLoading": "Subiendo",
    "videoLimit": "¡El tamaño del archivo no puede exceder los {limit}M!",
    "uploadVideoError": "Subida fallida",
    "failureDetermination": "Solución de la calidad del producto",
    "steps_1": "1. Pasos de solución de problenas de la estación de energía.",
    "steps_1_content": "1) Pulsa la pantalla para ver si la luz indicadora está encendida.\n2) Reinicia la estación de energía y déjala a un lado por un tiempo antes de recargar el dispositivo de nuevo.\n---Presiona el botón 'PANTALLA' durante 10 s cuando la estación de energía sea del modelo E160/E240/E500/E1000\n---Presiona el botón \" PANTALLA+USB\" durante 10 s cuando la estación de energía sea del modelo E1500/E2000\n3) Pulsa el botón en la parte superior de la salida de CA/CC para ver si la luz está encendida, antes de enchufar tu dispositivo y de cargarlo.\n4) Vuelve a revisar que tu dispositivo pueda ser cargado normalmente.\n\nSi los pasos de arriba no funcionan, es probable que tu estación de energía tenga un defecto.\n\n",
    "steps_2": "2. Pasos de solución de problemas del panel solar",
    "steps_2_content": "1) Para ver si tu estación de energía funciona, usa el cargador CA provisto para cargarla.\n2) Pruébala bajo la luz solar fuerte del mediodía. Conecta el panel solar en la estación de energía, y asegúrate de que no haya sombras en el panel.\n\nSi la estación puede ser cargada con el cargador CA, pero no funciona con el panel solar, está defectuosa.",
    "learnMore": "Más",
    "shrink": "Reducir",
    "noReason_30_day": "1. Jackery ofrece una garantía de devolución de tu dinero por 30 días, para todos los productos comprados en Jackery.com de forma directa. Estamos seguros de que amagarás tu nuevo Jackery. Pero, si por alguna razón, no estás del todo satisfecho, puedes pedir una devolución o reembolso dentro de 30 días.\n\n2. Ten en cuenta: Las devoluciones, sin ninguna razón, y que superen los 30 días, no serán aceptadas.\n\n3. Para devolver tu producto dentro de la política de devolución de 30 días, asegúrate de: Devolver el producto en su paquete original, con todos los materiales incluidos, en condición nueva, o casi nueva.",
    "selectDelivery": "Selecciona un servicio de logística para la devolución",
    "enterTrackNumber": "Ingresa el número de seguimiento",
    "illegal_trackNumber": "Ingresa el número de seguimiento correcto",
    "enterDescription": "Ingresa una descripción específica",
    "comingSoon": 'Muy pronto',
    "cancelled": 'Cancelado',
    "points": "puntos",
    "comingSoonTip": ' (Los puntos de Jackery se activarán el trigésimo primer día después de que se envíe el producto).',
    "pointsBalance": "Saldo de puntos",
    "tipTitle": 'Beneficios de miembro',
    "shopNow": "Comprar ahora",
    "tip1": `① <a> Al realizar pedidos desde </a> el sitio web oficial de Jackery, obtendrás una garantía extendida automática (hasta 5 años) y obtendrá puntos de Jackery (cada 1 euro gastado = 1 punto ganado).`,
    "tip2": `② <a> Al registrar productos </a> que compraste desde canales no oficiales, se le extenderá tu garantía (hasta 5 años).`,
    "tip3": `③ <a> Al realizar un pedido que supere los {num} </a> desde el sitio web oficial de Jackery luego de registrarse, obtendrás un regalo por única vez.`
  },
  "bank": {
    "name": "Nombre del banco",
    "branchName": "nombre de la sucursal",
    "type": "Tipo de tarjeta bancaria",
    "cuenta": "Número de la tarjeta bancaria",
    "realName": "Nombre del titular de la tarjeta"
  },
  "return": {
    "orderNumber": "Número de orden",
    "status": "Estado de la devolución/cambio",
    "attachment": "Archivo adjunto",
    "confirmRevoke": "¿Deseas cancelar la solicitud de devolución/cambio?",
    "returnPolicy_link": "https://es.jackery.com/pages/policy",
    "returnPolicy_1": "Si tienes alguna pregunta relacionada a la solicitud de reparación, devolución, cambio, e intercambio, consulta la",
    "returnPolicy_2": "Política Postventa.",
    "returnPolicy_3": "Si tienes alguna pregunta, ponte en contacto",
    "returnPolicy_4": "support{'@'}jackery.com",
    "returnPolicy_5": ""
  },
  "devices": {
    "empty": "No hay información de dispositivo",
    "more": "Más",
    "extendedWarrantyDeadline": "Fecha de caducidad de la garantía",
    "warrantyStatus": "Revista tu estado (Garantía Extendida)",
    "existWarranty": "Garantía extendida",
    "nonExistentWarranty": "Sin garantía extendida",
    "userManual": "Manual del usuario",
    "warrantyInfo": "Haz clic en 'Enviar' para activar tu garantía extendida.",
    "newsletter": "Suscríbete a nuestro boletín informativo",
    "warrantySuccess": "Garantía extendida",
    "download": "Descargar",
    "none": "Ninguno",
    "addNewDevice": "Agregar nuevo dispositivo",
    "shopNow": "Comprar ahora"
  },
  "address": {
    "empty": "No se guardó ninguna dirección ~",
    "add": "Añadir dirección nueva",
    "edit": "Editar la dirección",
    "userName": "Nombre de usuario",
    "address1": "Dirección 1",
    "address2": "Dirección 2",
    "country": "País/región",
    "country_code": "País/código postal",
    "province": "Provincia",
    "province_code": "Estado/provincia/código postal",
    "city": "Ciudad",
    "zip": "Código postal",
    "phone": "Número de teléfono",
    "company": "Empresa",
    "default": "Establecer la dirección de envío por defecto",
    "isDefault": "Direccion por defecto",
    "error": "La dirección de envío es anormal o está incompleta, revísala"
  },
  "character": {
    "comma": ","
  },
  // Following is server language pack
  "msg": {
    "success": "Éxito"
  },
  "user": {
    "log": {
      "expire": "Inicia sesión de nuevo"
    }
  },
  "message": {
    "order_status_shipping": "Enviado",
    "order_status_completed": "Completado",
    "order_status_processing": "Procesando",
    "order_status_archived": "Enviado",
    "order_status_canceled": "Cancelado",
    "order_status_unpaid": "Sin pagar",
    "device_not_found": "El dispositivo no existe",
    "device_disable_can_not_set_warranty": "El dispositivo actual no es válido y no se puede configurar la garantía extendida",
    "device_was_warranty": "Se ha extendido el equipo actual.",
    "device_not_support_warranty": "El dispositivo actual no es compatible con la garantía extendida"
  },
  "create": {
    "address": {
      "fail": "No se puedo añadir la dirección"
    }
  },
  "activation": {
    "email": {
      "expire": "El mensaje ha expirado"
    }
  },
  "select_country": {
    "title": "Selecciona Tu País/Región"
  },


  // 会员首页 多语言
  "member_index" : {
    "common": {
      "login": "Iniciar sesión",
      "register": "Registrarse",
      "already_a_member": "¿Ya eres miembro?",
      "points": "Puntos",
      "gift_card": "gift card",
      "redeem": "Redención",
      "rules": "Reglas",
      "copy_successfully": "copiado",
      "outOfStock": "Agotado",
      "quick_login": "Iniciar sesión/Registrarse"
    },
    // Banner 标题
    "banner": {
      "title": `Jackery<br/>Membresía`,
      "description": `Gana puntos Jackery, Canjea ventajas exclusivas, Haz algo significativo con Jackery`,
      "points_balance": "Saldo de puntos",
      "coming_soon": "Próximamente",
      "my_rewards": "Mis recompensas",
      "my_activity": "Mi actividad",
      "unused": "Sin usar",
      "to_be_redeemed": "Descapotable",
      "no_unused_rewards": "No hay recompensas disponibles",
      "no_convertible_rewards": "Sin recompensas convertibles",
      "no_activity_records": "Actualmente no hay actividad de puntos",
      "time": "Tiempo",
      "action": "Acción",
      "points": "Puntos",
    },
    "section_tabs": {
      "membership_benefits": "Ventajas de la membresía",
      "redeem_points": "Canjear puntos",
      "earn_points": "Ganar puntos",
      "guide": "Instrucciones",
      "faq": "Preguntas frecuentes",
    },
    // Benefits
    "membership_benefits": {
      "title": "Ventajas exclusivas de ser miembro de Jackery",
      "banner_text": "Ganar puntos Jackery con tu compra y canjear premios exclusivos",
      "coupon_benefit": "Obtener {num} de descuento en tu primera inscripción",
      "giftcard_benefit": "Regalo gratis para tu pedido de más de {num} por una vez",
      "product_benefit": "Recibir recordatorios periódicos sobre el cuidado de los productos",
      "gift_benefit": "Obtener un 15% de descuento una vez que expire la garantía"
    },
    // Redeem Points
    "redeem_points": {
      "title": "Obtener recompensas canjeando tus puntos",
      "unusedTips": "¡Utilízalo al comprar antes de que caduque!",
      "pointTips": "Tienes {0} puntos de Jackery.",
      "category_tabs": {
        "coupon": "Cupón",
        "gift_card": "Tarjeta Regalo",
        "jackery_products": "Producto Jakcery",
        "virtualCard": "Extras",
      },
      "code": "Código",
      "redeem_confirm_title": "Se utilizarán {0} puntos. No son reembolsables una vez tramitados.",
      "redeem_confirm_subtitle": "",
      "redeem_confirm_content": "¿Seguro que quieres canjearlo?",
      "successfully_redeemed": "Canjeado con éxito",
      "information_tips": " Los detalles sobre la tarjeta de regalo ha sido enviada a tu correo electrónico, por favor revisa tu bandeja de entrada.",
      "copy_and_use": "Copiar y usar",
      "day": "D",
      "hour": "H",
      "minute": "M",
      "seconds": "S",
      "count_down_info": "La campaña ha finalizado y el recompensas no puede canjearse. Por favor, actualiza la página."

    },
    // 获取积分方式
    "earn_points": {
      "title": "Formas de ganar puntos",
      "go_now": "Ir ahora",
      "points_earned": "Puntos obtenidos",
      "membership_registration": "Registro de miembros",
      "membership_registration_desc": "Puntos {0} Jackery al inscribirse.",
      "email_subscribtion": "Suscripción por Email",
      "email_subscribtion_desc": "{0} puntos Jackery suscribiéndote a Jackery por Email.",
      "sms_subscribtion": "Suscripción por SMS",
      "sms_subscribtion_desc": "{0} puntos Jackery suscribiéndote a Jackery por SMS.",
      "fill_in_your_birthday": "Introducir tu fecha de nacimiento",
      "fill_in_your_birthday_desc": "Consiguir {0} Puntos rellenando tu fecha de cumpleaños.<br/>Ganar {1} puntos en cada cumpleaños y consiguir el doble de puntos en tus compras.",
      "extend_your_warranty": "Ampliar tu garantía",
      "extend_your_warranty_desc": `
        consiguir {0} Puntos registrando productos en la web oficial de Jackery cada vez.
        <br/>
        (Para clientes que ha comprado en tiendas de terceros.)
      `,
      "shop_and_earn": "Comprar y ganar",
      "shop_and_earn_desc": "Completar la compra, 1 euro gastado = 1 punto ganado<br/>{0} puntos por el {1} pedido<br/>{2} puntos por pedidos superiores a {3}",
      "tip": "Las suscripciones multi sitio solo cuenta como una puntuación de suscripción."
    },
    "guide": {
      "title": "¿Cómo funciona?",
      "step_title_1": "1. Inscribirse",
      "step_content_1": "Crear una cuenta en Jackery y disfrutar de ventajas exclusivas.",
      "step_title_2": "2. Ganar puntos",
      "step_content_2": `Completar tareas para ganar puntos (por ejemplo, rellenar datos personales \/ comprar productos \/ participar en eventos, etc.)`,
      "step_title_3": "3. Ganar puntos Obtener recompensas",
      "step_content_3": `Canjear ofertas exclusivas (como cupones, tarjetas regalo, etc.) con tus Puntos Jackery.`,
      "learn_more": "Ver más",
    },
    // 注册 指引页
    "register_guide": {
      "title": "¿Cómo obtener las recompensas <br/>por ser miembro de Jackery??",
      "step_1_title": "Paso 1 - Registro e inicio de sesión",
      "step_1_subtitle_1": "[Registrarse]",
      "step_1_content_1": `① Vaya a la <a class="text-primary hover:text-primary" href="https://account.jackery.com/register?CR=ES" target="_blank">página de registro</a> o elija "Crear una cuenta" en <a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=ES" target="_blank">account.jackery.com</a>`,
      "step_1_content_2": "② Complete su dirección de correo electrónico y contraseña.",
      "step_1_content_3": "③ Confirme su nueva contraseña e introduzca el código de verificación obtenido a través de su correo electrónico.",
      "step_1_content_4": `④ Marque "Acepto la política de privacidad y las condiciones de servicio de Jackery" y "Acepto recibir las últimas noticias, ofertas, promociones e información de productos de Jackery." Lea la política de privacidad y las condiciones de servicio de Jackery para proteger tus derechos.`,
      "step_1_content_5": `⑤ Haga clic en "Enviar" para completar su registro.<br/>(Si ya se ha registrado, omita este paso o inicie sesión.)`,
      "step_1_subtitle_2": "[Iniciar sesión]",
      "step_1_content_6": `① 3 formas de iniciar sesión:`,
      "step_1_content_7": `Vaya a la <a class="text-primary hover:text-primary" href="https://account.jackery.com/login?CR=ES" target="_blank">página de inicio</a> de sesión`,
      "step_1_content_8": `Elija "Iniciar sesión ahora" en <a class="text-primary hover:text-primary" href="https://account.jackery.com/register?CR=ES" target="_blank">account.jackery.com</a>`,
      "step_1_content_9": `Haga clic en el icono "Cuenta" en la parte superior derecha de la página de inicio.`,
      "step_1_content_10": `② Complete su dirección de correo electrónico y contraseña. También puede iniciar sesión con Google o Amazon.`,
      "step_1_content_11": `③ Haga clic en "INICIAR SESIÓN" para entrar a su cuenta principal.`,
      "step_1_img_1_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/1_6fbc9678-281c-4fcc-b8c0-b2d0313d1206.png?v=1690169000",
      "step_1_img_2_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/2_f7b44d39-7fa1-40ae-a8d8-f509274347dd.png?v=1690169000",
      "step_1_img_3_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/4_ff09282d-6b20-45c1-b11a-ab8baaccb3e9.png?v=1690169000",
      "step_1_img_4_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/3_e789355d-dc25-408b-8853-3de0db9c5048.png?v=1690168999",
      "step_1_img_5_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/5_c63b876a-01b0-4036-983d-bcdea5eaa694.png?v=1690169000",
      "step_1_img_6_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/6.1.png?v=1690168999",
      "step_1_img_1_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/1_0ab7b673-9c65-4daf-a4b2-cdde8121da38.png?v=1690169284",
      "step_1_img_2_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/2_5c5cb755-c01a-4fb9-b600-2239e6291cb0.png?v=1690169279",
      "step_1_img_3_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/3.1.png?v=1690169278",
      "step_1_img_4_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/3.2.png?v=1690169279",
      "step_1_img_5_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/3.3.png?v=1690169281",
      "step_1_img_6_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/3.4.png?v=1690169278",

      "step_2_title": "Paso 2 - Gane Puntos Jackery",
      "step_2_content_1": `Visite "Formas de Ganar Puntos" en la <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=ES" target="_blank">página de Membresía</a> y aprenda cómo ganar puntos Jackery.`,
      "step_2_img_1_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/6.2.png?v=1690168997",
      "step_2_img_1_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/3.5.png?v=1690169277",

      "step_3_title": "Paso 3 - Revise los puntos Jackery",
      "step_3_content_1": `Después de iniciar sesión en su cuenta, puede consultar su saldo de puntos en el <a class="text-primary hover:text-primary" href="https://account.jackery.com/account/overview?CR=ES" target="_blank">centro de cuentas</a> o en la <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=ES" target="_blank">página de membresía</a>.`,
      "step_3_content_2": `① Centro de cuentas: Existen tres formas de comprobar tus puntos: "Visión general", "Mi perfil" y "Mi monedero".`,
      "step_3_content_3": `② Si hace clic en "Saldo de puntos", podrá acceder a la <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=ES" target="_blank">página de membresía</a>, donde también podrá consultar su saldo de puntos.`,
      "step_3_content_4": `Hay dos tipos de puntos: Puntos acreditados y puntos pendientes.`,
      "step_3_content_5": `Los puntos acreditados se mostrarán en "saldo de puntos", y se pueden canjear.`,
      "step_3_content_6": `Los puntos pendientes se mostrarán en la sección "Próximamente", y no se pueden canjear.`,
      "step_3_content_7": `Ahora bien, los puntos de la sección "Próximamente" se transferirán al "Saldo de puntos" en la fecha especificada.`,
      "step_3_content_8": `Por ejemplo, los puntos obtenidos en una compra se mostrarán en la sección "Próximamente" cuando se envíe la mercancía. A los 30 días, esos puntos se trasladarán al "Saldo de puntos".`,
      "step_3_img_1_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/7_fe560598-91dd-47b6-9b29-24ae0f5d3562.png?v=1690168998",
      "step_3_img_2_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/8_702b87dd-1755-405c-8ebb-09663aa6c39d.png?v=1690168997",
      "step_3_img_3_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/9_2e990afa-c1b7-4fdb-8add-06c50b2badf9.png?v=1690168998",
      "step_3_img_4_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/10_07fa0da8-d3f3-49a9-bcfa-19531bc58684.png?v=1690168999",
      "step_3_img_1_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/3.6.png?v=1690169279",
      "step_3_img_2_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/3.7.png?v=1690169279",
      "step_3_img_3_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/3.8.png?v=1690169279",
      "step_3_img_4_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/3.9.png?v=1690169280",

      "step_4_title": "Paso 4 - Canjear los puntos Jackery",
      "step_4_content_1": `① Ingrese a la <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=ES" target="_blank">página de membresía</a>, haga clic en "Mis Recompensas- Para canjear" o navegue por el tablero de "Obtenga Recompensas canjeando sus puntos"`,
      "step_4_content_2": `Ahí aparecerán resaltadas las recompensas que puedes obtener y las puedes canjear con tu saldo actual de puntos.`,
      "step_4_content_3": `② Elija la recompensa que desee y haga clic en "Canjear", pulse "Confirmar" en la ventana emergente y obtendrá la recompensa.`,
      "step_4_img_1_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/11_fb0c9e59-3b83-41f8-b186-cebb15424f56.png?v=1690168999",
      "step_4_img_2_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/12_539ed429-044a-4a5a-bd83-e972744b9477.png?v=1690169000",
      "step_4_img_3_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/16_2eabc7e7-d19d-4843-ab22-bce958be1c0c.png?v=1690168997",
      "step_4_img_4_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/13_b06aef85-4b7a-46f1-a774-a59b20422868.png?v=1690168997",
      "step_4_img_1_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/4.1.png?v=1690169280",
      "step_4_img_2_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/4.2.png?v=1690169279",
      "step_4_img_3_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/4.3.png?v=1690169279",
      "step_4_img_4_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/7_b1b84090-7d07-468b-b7d4-64dfd077d886.png?v=1690169279",

      "step_5_title": "Paso 5 - Aprovecha las recompensas",
      "step_5_content_1": `Si canjeó puntos Jackery por cupones o tarjetas de regalo, haga clic en "Copiar y Usar", y se habrá copiado el código. Si compra en el sitio web oficial de Jackery, utilice el código en la caja, y podrá ahorrar más en su compra.`,
      "step_5_content_2": `Si canjeó puntos Jackery por el producto Jackery, puede llevárselo gratis al pasar por caja insertando el código de descuento.`,
      "step_5_img_1_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/14_30ccaf50-8f83-4360-8e9b-ccb6f5b8ceb9.png?v=1690168997",
      "step_5_img_2_pc": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/15_ae372496-5d09-473c-836b-6885c220ead1.png?v=1690168997",
      "step_5_img_1_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/8_25f6b8cf-3851-4a78-b49e-16141679fb33.png?v=1690169284",
      "step_5_img_2_mb": "https://cdn.shopify.com/s/files/1/0690/1370/0929/files/9_094074ea-ae18-4bfe-9179-cf6dac689a54.png?v=1690169278",
    },
    "faq": {
      "title": "Preguntas Frecuentes",
      "membership": {
        "title": "Jackery Membresía",
        "question_1": "1. ¿Qué es Jackery Membresía?",
        "answer_1": `Jackery Membresía es un programa basado en puntos en el cual nuestros clientes pueden ganar puntos en <a class="text-primary hover:text-primary" href="https://es.jackery.com">es.jackery.com</a>. Puedes reclamar tus puntos a cambio de recompensas. No tienes costo unirse a nosotros. Es nuestra manera de demostrarte cuanto apreciamos tu lealtad.`,
        "question_2": "2. ¿Cómo entro a la Jackery Membresía?",
        "answer_2": `
          Debes registrarte a una cuenta Jackery para ser parte de los miembros de Jackery.
          <br/>
          Si no posees una, regístrate ahora. Aquí te dejamos <a class="text-primary hover:text-primary" href="/index/guide" target="_blank">una guía paso a paso</a> si necesitas saber cómo hacerlo.
          <br/>
          Si ya posees una cuenta en el sitio oficial de Jackery, podrás activarla o iniciar sesión a tu cuenta actual.
        `,
        "question_3": "3. ¿Necesito volver a registrarme desde otro sitio de Jackery si ya me registré?",
        "answer_3": "Una vez que te hayas registrado, tu cuenta será compatible con todos los sitios oficiales de Jackery.",
        "question_4": "4. ¿Si poseo muchas cuentas de Jackery, puedo unirlas en una sola?",
        "answer_4": "Si te registraste con diferentes direcciones de correo, la identificación de Jackery será diferentes y no podrá unir.",
        "question_5": "5. ¿Cómo cancelo la Jackery membresía?",
        "answer_5": `
          Contáctate con nuestro equipo de servicio de atención al cliente para cancelar tu cuenta y se restablecerán los puntos a cero.
          <br/>
          Los usuarios que hayan cerrado sesión pueden volver a ser miembros de Jackery a través del proceso de registro y los puntos se volverán a calcular.
          <br/>
          Si deseas volver a registrarte como miembro, para asegurar un inicio de sesión exitoso, por favor utiliza otra dirección de correo electrónico para el registro.
        `,
      },
      "earn_points": {
        "title": "Ganar puntos",
        "question_1": "1. ¿Cómo gano puntos?",
        "answer_1": `
          Luego de convertirte en miembro de Jackery, podrás ganar puntos al completar tareas específicas en el sitio web oficial. Las siguientes son algunas notas para que tengas en cuenta.
          <br/>
          <br/>
          ① Registro de membresía: Si te registraste antes de abril de 2022, deberás activar tu membresía al iniciar sesión y verificar tu cuenta. Puedes ganar puntos al registrarte o activar por primera vez.Las suscripciones multi sitio solo cuenta como una puntuación de suscripción.
          <br/>
          ② Suscripción con correo: Puedes ganar puntos al suscribirte con tu correo por única vez. No obtendrás puntos al resuscribirte luego de darte de baja. Las suscripciones multi sitio solo cuenta como una puntuación de suscripción.
          <br/>
          ③ Suscripción por SMS: Puedes ganar puntos al suscribirte por SMS por única vez. Deberás ingresar tu número de teléfono en "Mi Cuenta-Mi Perfil" o en la página de "Extender tu garantía", no solo suscribirte desde el pie del sitio web. Las suscripciones multi sitio solo cuenta como una puntuación de suscripción.
          <br/>
          ④ Ingrese tu cumpleaños: Puedes ganar puntos al ingresar tu cumpleaños. Obtén puntos por cada cumpleaños y puntos dobles por comprar. Solo podrás cambiar tu cumpleaños una vez.
          <br/>
          ⑤ Extender tu garantía: Esto solo aplica para usuarios que compraron por plataformas de terceros como ser Amazon, Walmart, etc. Luego de que extienda tu garantía de producto desde el sitio web oficial con éxito, se enviarán los puntos de acuerdo a las reglas.
          <br/>
          ⑥ Puntos por compra: Luego de comprar productos desde el sitio web oficial de Jackery, podrás ganar puntos a partir del valor total de los pedidos. Además, el 2º/5º/5º pedido o el valor de la compra que alcance los 5000€/8000€/12000€ le permitirán ganar puntos adicionales respectivamente. Solo se calcularán los puntos de la cantidad de pedidos realizados en plataformas de tercero cuando estos pedidos se hayan registrado para extender la garantía. (1 moneda local gastada= 1 punto ganado)
          <br/>
          ⑦ Unirte a Actividades de Puntos Adicionales: Puedes obtener puntos al participar en actividades de puntos adicionales en el sitio web oficial de Jackery. Para más detalles, dirígete a la descripción de la actividad en la página de la Jackery membresía.
        `,
        "question_2": "2. ¿Cuándo se acreditarán los puntos a la cuenta?",
        "answer_2": `
          Existen dos tipos de tiempos de llegada de los puntos:
          <br/>
          <br/>
          ① Envío rápido: Luego de que completes una tarea especifica en el sitio web oficial, como registrarte, suscribirte, ingresar tu cumpleaños y extender la garantía, se enviarán los puntos a tu cuenta dentro de las 24 horas.
          <br/>
          ② Envío retrasado: Los puntos que se obtengan a partir de compras se enviaran como 'puntos a obtener' luego de que se envíen los artículos. A partir de los 30 días, se activarán como 'saldo de puntos'. (Cuando un pedido incluye diferentes productos, prevalecerá la fecha en la cual se envía el primer producto). La cantidad de puntos que recibirás por un pedido que califique se basará en el pago total realizado.
          <br/>
          <br/>
          Se debe tener en cuenta que los puntos pendientes no se podrán usar para reclamar los beneficios del sitio web oficial.
        `,
        "question_3": "3. ¿Qué debo hacer si mis puntos no se acreditaron de manera adecuada a mi cuenta?",
        "answer_3": `Si tienes cualquier consulta sobre su cuenta de membresía y el saldo de puntos, contáctate con nuestro Servicio de Atención al Cliente a <a class="text-primary hover:text-primary" href="mailto:hello.eu{'@'}jackery.com">hello.eu{'@'}jackery.com</a>.`,
        "question_4": "4. ¿Pueden vencerse los puntos?",
        "answer_4": `
          Los puntos Jackery poseen una validez de dos años. Los puntos que ganen los usuarios vencerán en 2 años a partir del lanzamiento oficial del Programa de Membresía (29 de junio de 2023), los puntos que se venzan se deducirán de tu cuenta.
          <br/>
          Con el fin de proteger tu derechos e intereses, revisa y reclama tus puntos disponibles a tiempo.
        `,
        "question_5": "5. ¿Recibiré puntos por las transacciones realizadas previamente al lanzamiento del Programa de Membresía Jackery?",
        "answer_5": "¡Sí! El lanzamiento oficial de la Membresía Jackery es el 29 de junio de 2023, se le enviaran los puntos originales correspondientes por tus compras o las tareas específicas completadas en el sitio web oficial de Jackery (a partir del 1 de enero de 2020), siempre y cuando te registres como un miembro de Jackery. Los puntos originales tendrán una validez de 2 años (a partir del 29 de junio de 2023).",
        "question_6": "6. ¿Se pueden acreditar puntos por los impuestos o cargos por envío?",
        "answer_6": "No. Al calcularse los puntos no se tiene en cuenta el pago de parte de un miembro por cargos por envío y todos los impuestos, incluyendo entre otros, impuestos o impuestos de consumo.",
        "question_7": "7. ¿Qué pasa con mis puntos si cancelo mi pedido, devuelvo el producto o aplico para un reembolso parcial de un producto?",
        "answer_7": "Luego de que te canceles, reembolses o devuelvas un pedido se revertirán o disminuirán los puntos que hayas recibido de esa compra. Se deducirán los puntos por reembolsos parciales provocados por ajustes de precios.",
        "question_8": "8. Declaración especial sobre los puntos de diferentes sitios / Tasa de cambio / Método de visualización de puntos",
        "answer_8": `
          Los puntos Jackery se pueden ganar y usar en todos los sitios oficiales de Jackery.
          <br/>
          Si posees saldo de puntos en diferentes sitios web oficiales de Jackery, el sistema le mostrará el valor total de puntos luego de convertir el saldo en diferentes sitios de acuerdo con las tasas de cambio.
          <br/>
          La cantidad total de puntos se convertirán basándose a partir de la tasa de cambio de euros el primero de cada mes, de esta manera el saldo de puntos cambiará de acuerdo a la tasa de cambio cada mes.
          <br/>
          Los puntos se forman de manera entera, y partes fraccionadas que se contarán en el valor de cada punto, pero no se mostrarán ni se podrán reclamar
        `,
      },
      "redeem": {
        "title": "Reclamar puntos",
        "setting1": "Los usuarios pueden canjearlo hasta {time} veces en los últimos {num} días.",
        "setting2": "Los usuarios solo pueden canjear {time} veces",
        "setting3": "Los usuarios pueden canjear las recompensas siempre que tengan suficiente saldo de puntos.",
        "question_1": "1. ¿Cómo reclamar los puntos Jackery por beneficios?",
        "answer_1": `
          Puedes iniciar sesión en la página de inicio para miembros (membership.jackery.com). Se resaltarán los beneficios que se pueden reclamar. Puedes elegir los beneficios y hacer clic en "Reclamar".
          <br/>
          Si inicias sesión en tu centro de cuenta, puede hacer clic en "Saldo de puntos" para dirigirte a la página de inicio para miembros para reclamar tus puntos por beneficios.
          <br/>
          Los puntos no son reembolsables después de ser reclamados. Asegúrate de estar seguro antes de reclamar.
        `,
        "question_2": "2. ¿Para qué beneficios se pueden reclamar los puntos Jackery?",
        "answer_2": `
          Te proporcionamos una gran cantidad de beneficios. Se pueden reclamar los puntos para cupones, tarjetas de regalo, un producto de Jackery, un buen regalo y mucho más. Los beneficios son solo validos al sitio en el cual se recolectaron o reclamado y no se pueden usar a través de sitios. Asegúrate de usar las recompensas durante el periodo de validez. Dirígete a la página de inicio para miembros para más detalles. Los usuarios pueden canjear recompensas un número limitado de veces dentro de cierto periodo de tiempo; recibirá una alerta del sistema cuando se haya alcanzado el límite de canje. Una vez que entre en un nuevo ciclo para puede volver a canjearlos.
          <br/>
          <br/>
          Notas
          <br/>
          ① Cupón Jackery: Cada cupón tiene un descuento especifico, cantidad de pedidos mínima, productos aplicables y un periodo de validez. Revisa la información detallada de cada cupón al usarlo. No se pueden combinar o sumar cupones con cualquier otra oferta incluyendo ventas, cupones, etc. {0}
          <br/>
          ② Tarjeta de regalo Jackery: Cada tarjeta de regalo tiene una denominación especifica y el periodo de validez. Revisa para más información por cada tarjeta de regalo al usarla. {1}
          <br/>
          ③ Prodotto Jackery：L’offerta non può essere combinata o cumulata con altre promozioni, compresi saldi e coupon. Per richiedere l’offerta, inserire il codice e l’indirizzo e-mail corrispondente al momento del pagamento. Ricorda che ogni coupon prodotto Jackery può essere utilizzato solo per l’acquisto di uno specifico articolo. {2}
          <br/>
          ④ Tarjetas de regalo que no son de Jackery: Hay una cantidad limitada disponible de tarjetas de regalo de que no son de Jackery. Luego de reclamar el total del inventario, es posible que recibas un mensaje de "agotado" y no podrás reclamar tus puntos. No se mostrarán las tarjetas de regalo que no son de Jackery cuando estén agotadas. Para ver más detalles sobre las reglas de uso de las tarjetas de regalo que no son de Jackery, dirígete al sitio web oficial de la marca correspondiente. {3}
        `,
        "question_3": "3. ¿Cómo puedo revisar los beneficios que reclamé?",
        "answer_3": `
          ① Inicia sesión en el centro de cuenta (<a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=ES">account.jackery.com</a>), revisa en 'Mi Billetera'. O inicia sesión en la página de inicio para miembros (membership.jackery.com), revisa en 'Mis Recompensas'.
          <br/>
          ② Recibirás correos sobre avisos para reclamar recompensas, incluyendo cupones, tarjetas de regalo, productos de Jackery, etc.
        `,
        "question_4": "4. ¿Se pueden convertir en efectivo mis recompensas, beneficios y puntos?",
        "answer_4": `
          Las recompensas, beneficios y puntos ganados a través del Programa de Membresía Jackery no tiene valor en efectivo, no se pueden convertir en efectivo, y son intransferibles. (Excepto por la tarjeta de regalo que puede enviar a tus amigos o familiares.)
          <br/>
          No se permite vender los beneficios que reclamaste a terceros.
          <br/>
          Jackery se reserva el derecho a tomar acciones legales en contra de transacciones no autorizadas.
        `,
        "question_5": "5. ¿Debo pagar costos de envío para productos reclamados?",
        "answer_5": "En la mayoría de los casos, ofrecemos envío gratis para productos reclamados. Sin embargo, es posible que debas pagar costos de envío para productos específicos.",
        "question_6": "6. ¿Puedo cancelar, devolver o cambiar las recompensas reclamadas con puntos?",
        "answer_6": `
          Luego de reclamar, no se podrá cancelar. No se pueden devolver ni cambiar las recompensas.
          <br/>
          Si el producto llega dañado, contáctate con el servicio de atención al cliente en <a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=ES" target="_blank">support.jackery.com</a> dentro de 7 días.
        `,
        "question_7": "7. ¿Se pueden enviar los productos reclamados con los productos que compres de Jackery?",
        "answer_7": `
          No. Los productos reclamados (un artículo obtenido por reclamar puntos o por sorteo) se enviarán de manera separada dentro de 60 días hábiles.
          <br/>
          Deberás enviar o confirmar la información del domicilio para que el sitio web oficial pueda organizar el envío a tiempo.
        `,
      },
      "others": {
        "title": "Otros",
        "question_1": "1. Contáctate con nosotros para más ayuda",
        "answer_1": `
          Si tienes más consultas, envía un correo a nuestro servicio de atención al cliente en <a class="text-primary hover:text-primary" href="mailto:hello.eu{'@'}jackery.com">hello.eu{'@'}jackery.com</a>, dirígete a <a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=ES" target="_blank">support.jackery.com</a> para más ayuda, o haz clic para contactarnos.
        `,
        "question_2": "2. Gestión de violaciones de las normas de puntos de membresía de Jackery",
        "answer_2": `Si un usuario actúa de forma maliciosa o falsa para obtener puntos, uso de puntos u obtener ventajas, Jackery se reserva el derecho a congelar o cancelar los puntos del usuario, dejar de proporcionar servicios o cancelar la cuenta del usuario, según sea apropiado, y no aceptar la solicitud del usuario para un nuevo registro.`,
        "question_3": "3. Declaración especial",
        "answer_3": `
          Jackery se reserva todos los derechos sobre la Membresía Jackery.
          <br/>
          Los beneficios de la membresía pueden cambiar periódicamente; por favor, consulta la página de membresía para obtener detalles.
        `,
        "question_4": "4. Registro malicioso (incluido el uso de información falsa, o crear múltiples cuentas para obtener recompensas adicionales) resultará en descalificación",
        "answer_4": `
        Jackery se reserva todos los derechos para cancelar recompensas o pedidos obtenidos a través de los siguientes métodos:
          <br/>
          ① Uso de Información Falsa: Está estrictamente prohibido usar nombres, direcciones, correos electrónicos, o cualquier otra información personal falsa para crear cuentas.
          <br/>
          ② Creación de Múltiples Cuentas: Está estrictamente prohibido para un solo usuario crear múltiples cuentas para obtener recompensas o descuentos adicionales. Esto incluye usar diferentes correos electrónicos o cualquier información de identidad para registrar múltiples cuentas.
          <br/>
          ③ Herramientas de Registro Automatizado: El uso de cualquier herramienta o script automatizado para el registro masivo de cuentas está estrictamente prohibido.
          <br/>
          ④ Comportamiento Malicioso: Cualquier otro comportamiento considerado malicioso, incluyendo la explotación de vulnerabilidades del sistema o participar en transacciones fraudulentas, está estrictamente prohibido.
        `,
      }
    }
  },
  // overview
  "overview": {
    "unit": 'puntos',
    "pointsBalance": "Saldo de puntos",
    "comingSoon": "Muy pronto",
    "benefitsForYou": "Beneficios para ti",
    "welcomeGift": "Regalo de bienvenida",
    "welcomeGiftInfo": "100% oportunidades de ganar, cupones/ tarjetas de regalo/ artículos reales que lo están esperando.",
    "firstRegistration": "Primer registro",
    "firstRegistrationInfo": "Obtén un descuento de {num} al registrarte por primera vez en la membresía de Jackery.",
    "largeOrderBenefits": "Beneficios por un gran pedido",
    "largeOrderBenefitsInfo": "Recibirás un regalo por tu pedido que supere los {num} por única vez.",
    "referral": "Recomienda a un amigo",
    "referralInfo": "Envía un descuento a tus amigos, ¡Obtén recompensas en su primera compra!",
  },
  // 我的钱包
  "wallet": {
    "unit": 'points',
    "jackeryPointsBalance": "Saldo de puntos",
    "comingSoon": "Muy pronto",
    "pointsDetails": "Mi Actividad",
    "redeemMyCoins": "Reclamar mis puntos",
    "coupon": "Cupón",
    "giftCard": "Tarjeta de regalo",
    "jackeryProducts": "Producto de Jackery",
    "extras": "Extra",
    "used": "Usado",
    "expired": "Vencido",
    "daysLeft": "{num} días restantes",
    "code": "Código",
    "rules": "Reglas",
    "copyAndUse": "Copiar y Usar",
    "noCoupon": "No hay cupones disponibles",
    "noGiftCard": "No hay tarjeta de regalo disponible",
    "noJackeryProducts": "No hay cupones de Jackery productos disponibles",
    "noExtras": "No hay extras disponibles",
    "redeemMyPoints": "Reclamar mis puntos",
    "viewMore": "Ver Más",
    "couponRules": {
      "one": {
        "title": "1. Qué es un cupón",
        "step1": "1) Los cupones son métodos de respuesta de los clientes que Jackery distribuye a los usuarios a través de actividades promocionales, marketing dirigido, canje de puntos de socio y otras formas para reducir los pagos de las compras.",
        "step2": "2) Los cupones lanzados por Jackery existen en formato electrónico y pueden utilizarse para comprar productos en el sitio web oficial de Jackery.",
        "step3": "3) Al comprar y pagar un pedido, introduce el código de descuento del cupón Jackery, y el descuento/importe de la tarjeta se deducirá automáticamente del importe total del pedido. El cupón no puede convertirse en efectivo.",
      },
      "two": {
        "title": "2. ¿Cómo comprobar los cupones?",
        "step1": "1) Regístrate como miembro de Jackery y comprueba los cupones (incluida la información de no utilizados, utilizados y caducados) en el Centro de Usuario - Mi Cartera - Cupones.",
        "step2": "2) Consulta el periodo de validez del cupón. Por favor, utilícelo dentro del periodo de validez, no se efectuará ningún reembolso si está vencido.",
      },
      "three": {
        "title": "3. Cómo utilizar el cupón:",
        "step1": "1) Cada cupón tiene un descuento específico, un importe mínimo de pedido, los productos aplicables y el periodo de validez. Compruebe la información detallada de cada cupón al utilizarlo.",
        "step2": "2) Sitios aplicables: El cupón es válido para el sitio en el que se recogió o canjeó y no puede utilizarse en otros sitios.",
        "step3": "3) Sólo se puede utilizar un cupón para el mismo pedido y no se puede combinar con promociones; si un pedido incluye varios artículos, el cupón sólo se aplica a los artículos que cumplan los requisitos.",
        "step4": "4) Algunos cupones están vinculados a una cuenta y son sólo para uso personal. La dirección de correo electrónico que aparece en la página de pedido debe coincidir con la dirección de correo electrónico del destinatario del cupón y no puede transferirse; algunos cupones pueden regalarse a otros usuarios para que los utilicen; consulte las instrucciones de uso del cupón para obtener más información.",
        "step5": "5) El cupón puede utilizarse junto con tarjetas regalo.",
        "step6": "6) Los afiliados pueden canjear puntos por cupones y, una vez deducidos los puntos, tanto éstos como los cupones son irrevocables. Puede haber un límite en el número de veces que los puntos de socio pueden canjearse por cupones dentro de un determinado periodo de tiempo.",
        "step7": "7) Si el pedido utiliza un cupón, éste no podrá devolverse en caso de reembolso."
      },
      "four": {
        "title": "4. Tratamiento de la violación de las normas de uso de cupones",
        "step1": "Si el usuario utiliza un cupón falsificado, comprado, vendido o transferido de otro individuo, Jackery tiene el derecho de congelar o invalidar el cupón, Jackery tiene el derecho de congelar o invalidar los cupones en poder de los usuarios pertinentes, cancelar los pedidos que utilizaron cupones, cancelar la calificación del cupón del usuario, dejar de prestar servicios, o cancelar la cuenta del usuario, y tiene el derecho de no aceptar la solicitud de reinscripción del usuario."
      }
    },
    "giftRules": {
      "one": {
        "title": "1. ¿Qué es una tarjeta regalo de Jackery?",
        "step1": "1) Las tarjetas regalo son métodos de respuesta de los clientes que Jackery distribuye a los usuarios a través de actividades promocionales, marketing dirigido, canje de puntos de miembro y otras formas para reducir los pagos de las compras.",
        "step2": "2) Las tarjetas regalo lanzadas por Jackery existen en formato electrónico y pueden utilizarse para comprar productos en el sitio web oficial de Jackery.",
        "step3": `3) Al realizar la compra de las órdenes de pago, introduce el código de la tarjeta regalo Jackery, y el importe de la tarjeta se deducirá automáticamente del importe total del pedido. Las tarjetas regalo no pueden convertirse en efectivo.`,
      },
      "two": {
        "title": "2. ¿Cómo comprobar las tarjetas regalo?",
        "step1": "1) Regístrarte como miembro de Jackery y comprueba las tarjetas regalo (incluida la información de no utilizadas, utilizadas y caducadas) en el Centro de Usuario - Mi Cartera - Tarjetas Regalo.",
        "step2": "2) Consulta el periodo de validez de la tarjeta regalo. Por favor, utilícelo dentro del periodo de validez y Jakcery no compensa los pagos atrasados.",
      },
      "three": {
        "title": "3. Instrucciones para utilizar las tarjetas regalo:",
        "step1": "1) Cada tarjeta regalo tiene una denominación y un periodo de validez específicos. Por favor, compruebe la información de cada tarjeta regalo cuando la utilice.",
        "step2": "2) Consulta el periodo de validez de la tarjeta regalo. Por favor, utilícelo dentro del periodo de validez y Jakcery no compensa los pagos atrasados.",
        "step3": "3) No se requiere un importe mínimo de pedido al utilizar una tarjeta regalo. Se aplica a todos los productos.",
        "step4": "4) Las tarjetas regalo no están limitadas al uso personal de la cuenta y pueden transferirse como regalo a amigos.",
        "step5": "5) Se pueden utilizar varias tarjetas regalo para el mismo pedido, se pueden combinar con promociones y se pueden utilizar junto con cupones.",
        "step6": "6) Los afiliados pueden canjear puntos por tarjetas regalo y, una vez deducidos los puntos, tanto éstos como las tarjetas regalo son irrevocables.",
        "step7": "7) Puede haber un límite en el número de veces que los puntos de socio pueden canjearse por tarjetas regalo en un determinado periodo de tiempo, y el sistema le recordará cuando se agote el número de veces.",
        "step8": "8) Si el pedido utiliza una tarjeta regalo, ésta no podrá devolverse en caso de reembolso.",
      },
      "four": {
        "title": "4. Manejo de la Violación de las Reglas de Uso de la Tarjeta de Regalo",
        "step1": "Si el usuario utiliza un cupón falsificado, comprado, vendido o transferido de otro individuo, Jackery tiene el derecho de congelar o invalidar la tarjeta de regalo, cancelar los pedidos que utilizaron tarjetas de regalo, cancelar la calificación de la tarjeta de regalo del usuario, dejar de prestar servicios, o cancelar la cuenta del usuario, y tiene el derecho de no aceptar la solicitud de reinscripción del usuario."
      }
    },
    "productRules": {
      "one": {
        "title": "1. ¿Qué es un cupón de Producto Jackery?",
        "step1": "1) Los regalos de productos Jackery son un tipo de cupón que Jackery distribuye a los usuarios a través de actividades promocionales, marketing dirigido, canje de puntos de miembro y otras formas como respuesta a los clientes.",
        "step2": "2) El Producto Jackery lanzado por Jackery existe en forma electrónica como cupones, y se puede comprar a través de la reducción total o parcial de crédito para los productos correspondientes en el sitio web oficial de Jackery.",
        "step3": "3) Al comprar y pagar un pedido, introduce el código de descuento del producto Jackery, y el descuento/importe de la tarjeta se deducirá automáticamente del importe total del pedido. Los cupones no pueden convertirse en efectivo.",
      },
      "two": {
        "title": "2. ¿Cómo comprobar el cupón de productos Jackery?",
        "step1": "1) Regístrate como miembro de Jackery y comprueba los cupones de Productos Jackery (incluyendo la información no utilizada, utilizada y caducada) en el Centro de Usuario - Mi Cartera - Productos Jackery.",
        "step2": "2) Consulta el periodo de validez del cupón de productos Jackery. Por favor, utilízalo dentro del periodo de validez y Jackery no compensarás ningún pago atrasado.",
      },
      "three": {
        "title": "3. Instrucciones para utilizar los cupones de productos Jackery:",
        "step1": "1) Cada cupón Jackery Product tiene un periodo de validez específico, es necesario comprobar la información detallada al utilizarlo.",
        "step2": "2) Sitios aplicables: Los cupones de productos Jackery reclamados o canjeados en este sitio son sólo para tu uso en este sitio y no se pueden utilizar en otros sitios.",
        "step3": "3) Sólo se puede utilizar un cupón para el mismo pedido y no se puede combinar con promociones; si aparecen varios productos en el mismo pedido, el cupón sólo es válido para el producto correspondiente y no es válido para otros productos.",
        "step4": "4) El cupón de productos Jackery está vinculado a una cuenta y es sólo para uso personal de la cuenta. La dirección de correo electrónico que aparece en la página de pago debe coincidir con la dirección de correo electrónico del destinatario del cupón y no puedes transferirse.",
        "step5": "5) Los miembros pueden utilizar los puntos para canjear cupones de productos Jackery y, una vez deducidos los puntos, tanto éstos como los cupones son irrevocables. Puede haber un límite en el número de veces que los puntos de socio pueden canjearse por cupones dentro de un determinado periodo de tiempo.",
        "step6": "6) Si el pedido utiliza un cupón, éste no podrá devolverse en caso de reembolso."
      },
      "four": {
        "title": "4. Condiciones de intercambio de regalos de productos Jackery",
        "step1": "1) Inicia sesión en el sitio web oficial de Jackery y vayas a la página de detalles del producto correspondiente, haz clic para comprar",
        "step2": "2) Introduce el código de descuento correspondiente en la página de pago (Pegue el código {XXX} al realizar el pago)",
        "step3": "3) Ten en cuenta que el correo electrónico de la página de pago es el correo electrónico para recibir cupones"
      },
      "five": {
        "title": "5. Tratamiento de la violación de las normas de uso de cupones",
        "step1": "Si el usuario utiliza un cupón falsificado, comprado, vendido o transferido de otro individuo, Jackery tiene derecho a congelar o invalidar los cupones, cancelar los pedidos que utilizaron cupones, cancelar la calificación del cupón del usuario, dejar de prestar servicios o cancelar la cuenta del usuario, y tiene derecho a no aceptar la solicitud de reinscripción del usuario."
      }
    },
    "extraRules": {
      "one": {
        "title": "1. ¿Qué son los Extras de Jackery?",
        "step1": "1) Los Extras de Jackery son regalos de marcas ajenas a Jackery, incluyendo vales, cupones, tarjetas regalo y regalos físicos.",
        "step2": "2) Jackery distribuye a los usuarios a través de actividades promocionales, marketing dirigido, canje de puntos de miembro y otras formas como retroalimentación a los clientes. Una vez canjeados los puntos por Extras de Jackery, se deducirán los puntos y no se podrán retirar ni los puntos ni los regalos.",
      },
      "two": {
        "title": "2. ¿Cómo comprobar Extras de Jackery?",
        "step1": "1) Regístrate como miembro de Jackery y visualízalo en Mi Centro de Usuario - Mi Billetera - Extra.",
        "step2": "2) Canjea tus ofertas dentro del periodo de validez. No puedes volver a expedirse una vez caducado.",
        "step3": "3) Los detalles de la oferta se enviarán por correo electrónico. Puedes consultar la información pertinente desde tu buzón de correo electrónico."
      },
      "three": {
        "title": "3. Reclamar un regalo extra de Jackery",
        "step1": {
          "title": "1) Reglas para reclamar un regalo físico:",
          "tip1": "① Para recibir los regalos físicos que no sean de Jackery se debe incluir una dirección claramente detallada.",
          "tip2": "② Tu artículo será enviado en 30-90 días después de reclamarlo.",
          "tip3": "③ Los detalles del envío, incluido el número de seguimiento, se enviarán por correo electrónico una vez enviado el artículo.",
          "tip4": "④ Si los premios físicos recibidos están dañados o tienen otros problemas, no duda en ponerse en contacto con nuestro servicio de atención al cliente.",
        },
        "step2": {
          "title": "2) Consideraciones sobre tarjetas regalo virtuales que no sean de la marca Jackery",
          "tip1": "① Los detalles de las tarjetas regalo virtuales que no sean de la marca Jackery, incluyendo 'qué y cómo' se enviarán por correo electrónico.",
          "tip2": "② El plazo para recibir tarjetas regalo virtuales de marcas ajenas a Jackery es de XX años, que se activan al recibirlas. Tras la activación, el periodo de validez de la tarjeta regalo se determinará en función del periodo de validez especificado por la marca correspondiente. Por favor, consulta el sitio web de la marca correspondiente para más detalles.",
          "tip3": "③ Las normas de uso específicas de las tarjetas regalo virtuales de marcas ajenas a Jacekry pueden consultarse en la introducción de las normas de la plataforma de canje de tarjetas regalo.",
        }
      },
      "four": "4. En caso de litigio, Jackery se reserva el derecho de decisión final."
    }
  },
  // My Service
  "service": {
    'imgText1': 'Bienvenido al soporte ',
    'imgText2': 'de Jackery',
    'pcImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_f2d45c3c-6199-4b74-85d8-bc1977a5d9b9.png?v=1683191312',
    'mbImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_efc7e4e4-a728-40e4-ab7b-31aab55839af.png?v=1683191345',
    'text': 'Dirígete al soporte de Jackery para un servicio inteligente.',
    'contactUs': 'Contactarse con nosotros',
    'viewSupport': 'Dirigirse a soporte',
  },
  // My Jackery Life
  "jackeryLife": {
    'title': 'Jackery Life',
    'imgText1': 'Jackery Life',
    'imgText2': 'Viviendo con Jackery Solar',
    'pcImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/2_2bf016ce-d9a0-4e4b-a163-e4272aba015d.png?v=1683189189',
    'mbImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/Jackery_life_8ab97bcf-fd91-4e1d-a1d4-8c378f824fdb.png?v=1683189235',
    'text': '¡Comparte tus historias con Jackery sobre la Vida Jackery, y gana la chance de ganar grandes premios!',
    'learnMore': 'Ver más',
  },
  "wishlist": {
    "empty": "No hay productos en la lista de deseos",
    "shopNow": "¡COMPRAR AHORA",
    "modalTitle": "¿Estás seguro/a de querer eliminar este producto de tu lista de deseos? ",
    "modalCancel": "Cancelar",
    "modalOk": "Confirmar",
    "removeToast": "El producto ha sido eliminado de tu lista de deseos.",
    "createdDesc": "Fecha de agregado más reciente",
    "createdAsc": "Fecha de agregado más temprano",
    "priceDesc": "Precio de mayor a menor",
    "priceAsc": "Precio de menor a mayor",
    "purchased": "COMPRADO",
    "buyNow": "COMPRAR AHORA",
    "selectAll": "Seleccionar todo",
    "selected": "Seleccionado",
    "amount": "Importa total",
    "addOn": "Añadido el",
    "notGoodsGotoBuy": 'Por favor, selecciona un artículo',
    "viewMoreHref": "https://es.jackery.com/"
  },
  "old-invite-new": {
    "myRewardsTitle": "My Rewards",
    "myRewardsSubTitle": "Manage and use rewards that have been claimed here. For expired and used rewards, please check them in My Wallet.",
    "myRewardsNoData": "Invite your friends to subscribe or purchase, and claim your rewards on the dashboard below.",
    "viewMore": "View More",
    "myReferralsTitle": "My Referrals",
    "myReferralsSubTitle": "Track the referral progress and claim the rewards. Click the reward for details.",
    "myReferralsNoData": "Invite your friends to subscribe or make a purchase, then claim your rewards here.",
    "claim": "Claim",
    "claimed": "Claimed",
    "expired": "Expired",

    // 补充
    "myReferral": "My Referral",
    "enterEmail": "Enter Your Email Address",
    "invalidEmail": "Invalid Email Address",
    "messageSent": "Message Sent",
    "copy": "COPY",
    "copied": "COPIED",
    "copiedSuccess": "COPIED SUCCESS!",
    "friendEmail": "Your Firend's Email",
    "referee": "Referee",
    "referralType": "Referral Type",
    "rewards": "Rewards",
    "status": "Status",
    "instagramCopySuccessTip": "Referral Link has copied, paste and share to Instagram",
  }
}
