import router from './router'
import store from './store';
import { getUrlParam, cookie } from "@/utils/common";
import { 
    handleSetLocal,
    handleParamsJkyAppLogin,
    handleThirdLoginParams,
    whiteList, 
    handleParamsUrlUpdate,
    noUserPath,
    setShopConfig
} from "./utils/helpers/app"
import { getUserToken } from "./utils/helpers/auth"
import { isThirdLogin, isTokenLogin } from "./utils/helpers/utils"

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { setLanguage } from '@/lang'

const handleBeforeAction = async () => {
    /* --- 入参处理 - start --- */

    // 处理参数携带jkyAppLogin 非jackery主域快捷登录兼容
    try {
        handleParamsJkyAppLogin();
    } catch (error) {
        console.error("permission: jkyAppLogin params init error");
    }
    /* --- 入参处理 - end --- */

    // 初始化设置站点信息 国家地区语言等
    // 通过store中language.current.name简单判断是否已经初始化
    if(!store.state.app.language.current.name) {
        try {
            await handleSetLocal()
            setShopConfig()
        } catch (error) {
            console.error("permission: area & language init error");
        }
    }
}

router.beforeEach(async (to, from, next) => {
    NProgress.start()
    await handleBeforeAction()

    // 处理cr值路由问题
    if(getUrlParam('cr') || getUrlParam('CR')) {
        const cr = getUrlParam('cr') || getUrlParam('CR')
        await setLanguage(cr.toUpperCase())
        if(!store.state.app.language.current.name) {
            try {
                await handleSetLocal()
                setShopConfig()
            } catch (error) {
                console.error("permission: area & language init error");
            }
        }
    }

    // FIX: 处理三方登录参数自动登录
    if(isThirdLogin()) {
        if(isThirdLogin().emailExist === 1) {
            await store.dispatch('app/thirdLogin', {
                ...isThirdLogin() 
             })
        } else { // 需要绑定邮箱 - login页面处理
            if(to.path !== "/login") {
                next({path: "/login", query: {...to.query, return_url: location.origin + location.pathname}})
            } else {
                next()
            }
        }
    }

    // FIX: 处理携带参数：Authorization进入自动登录
    if(isTokenLogin()) {
        await store.dispatch('app/loginByAuth', {token: isTokenLogin()})
        next(false)
    }

    // FIX: 处理携带参数：jackery_token进入自动登录
    // if(isJackeryTokenLogin()) {}

    if(getUserToken()) {
        // 通过语言切换来，需要从新更新路由信息
        if (getUrlParam('cr') || getUrlParam('CR')) {
            const cr = getUrlParam('cr') || getUrlParam('CR')
            const accessRoutes = await store.dispatch('permissionPlus/generateRoutes')
            const baseRoutes = {...store.state.permissionPlus.baseRoutes};
            const routes = accessRoutes[0]
            
            const filterRouter =  routes.children.filter(item => {
                if (item.meta && item.meta.permissions.includes(cr.toUpperCase())) {
                    return item
                }
            })
            routes.children = filterRouter
            baseRoutes.children= [routes]
            router.addRoute(routes)
            const routeList = router.getRoutes().map(item => item.path)
            handleParamsUrlUpdate("cr")
            handleParamsUrlUpdate("CR");
            // 判断要进入的路由是否具有该权限
            if (routeList.includes(to.path)) {
                next({ ...to, replace: true })
            } else {
                next('/account/overview')
            }
            NProgress.done()
        } else if (store.state.permissionPlus.addRoutes.length === 0) {
            const accessRoutes = await store.dispatch('permissionPlus/generateRoutes')
            const baseRoutes = {...store.state.permissionPlus.baseRoutes};
            const cr = store.state.app.language.current.cr
            const routes = accessRoutes[0]
            
            const filterRouter =  routes.children.filter(item => {
                if (item.meta && item.meta.permissions.includes(cr)) {
                    return item
                }
            })
            routes.children = filterRouter
            baseRoutes.children.push(routes)
            router.addRoute(routes)
            const routeList = router.getRoutes().map(item => item.path)
            // 判断要进入的路由是否具有该权限
            if (routeList.includes(to.path)) {
                next({ ...to, replace: true })
            } else {
                next('/account/overview')
            }
            NProgress.done()
        } else {
            next()
            NProgress.done()
        }
    } else { // 未登录
        const whiteRoutes = whiteList() || []
        if(whiteRoutes.indexOf(to.path) === -1) {
            let _path = to.path.startsWith('/') ? `${location.origin}${to.path}` : `${location.origin}/${to.path}`
            next(`/login?redirect_url=${_path}`)
            NProgress.done()
        } else {
            next()
            NProgress.done()
        }
    }
})

router.afterEach(async () => {
    if(getUserToken() && noUserPath()) {
        // 通过store中userInfo.uid简单判断是否已经初始化登录用户的用户信息
        const hasUserInfo = store.state.app.userInfo.uid
        if(!hasUserInfo) {
            try {
                await store.dispatch('app/getUserInfo');
            } catch (error) {
                console.error("permission afterEach: user info init error!")
            }
        }
    }

    /* --- 入参处理 - start --- */

    

    // 处理参数携带 更新url - 删除指定参数
    try {
        handleParamsUrlUpdate("cr");
        handleParamsUrlUpdate("CR");
        handleParamsUrlUpdate("jackery_token");
        handleParamsUrlUpdate("Authorization");
    } catch (error) {
        console.log("permission afterEach: update params url error");
    }

    try {
        await handleThirdLoginParams(); // 在前置拦截器无法获取当前路由信息，所以在该处处理
    } catch (error) {
        console.error("permission afterEach: handleThirdLoginParams params init error");
    }
    /* --- 入参处理 - end --- */
})