/**
 * axios 二次封装 和 拦截处理
 * 创建者: garfield
 * 创建日期: 2020-12-16
 */
import axios from "axios";
import store from '../store/index.js';
import { notification, message } from 'ant-design-vue';
import { cookie } from "../utils/common";
import i18n from "@/lang";
import router from "../router"

const { t } = i18n.global;

// 'Content-Type': 'application/x-www-form-urlencoded'
const httpAx = axios.create({
  responseType: 'json', timeout: 15000, headers: {'Content-Type': 'application/json'}
});

/**
 * http request 拦截器
 */
httpAx.interceptors.request.use(config => {
  let lang = cookie.get("_jk_lang") || localStorage.getItem('JACKERY_ID_LANG') || 'en-US',
    cr = cookie.get("_jk_cr") || localStorage.getItem('JACKERY_ID_CR') || 'US';

  // ID 跟 会员系统传参区别
  if (config.url.indexOf(import.meta.env.VITE_API_URL) >= 0) {
    const token = cookie.get("_jk_id");
    token && (config.headers.Authorization = token);
    config.headers["id-language"] = config.headers["Accept-Language"] = lang;
    config.headers["app_key"] = "web-client";
    config.headers.zoneOffset = new Date().getTimezoneOffset();
    config.headers.belongBrandChannel = import.meta.env.VITE_BRAND_CHANNEL
  } else {
    config.headers["lang"] = cr;
    const token = cookie.get("_jk_user") || localStorage.getItem('JACKERY_USER_TOKEN');
    token && config.url.indexOf("/v1/user/login") < 0 && config.url.indexOf("/v1/region/lists-not-auth") < 0 && (config.headers.jackeryToken = decodeURI(token))
  }

  if (config.method === 'post' || config.method === 'put') {
    config.data = { ...config.data, _t: Date.parse(new Date()) / 1000 }
  } else {
    config.params = { ...config.params, _t: Date.parse(new Date()) / 1000 }
  }
  return config
}, err => {
  console.log("request", err);
  return Promise.reject(err)
});

/** http response 拦截器 */
httpAx.interceptors.response.use(res => {
  if (res.status === 500) {
    return Promise.reject(new Error('500 error'))
  }

  const { data, code } = res.data, msg = res.data.msg || res.data.message || '';

  let auth = res.headers.Authorization || res.headers.authorization;
  // id接口 token 动态更新
  if (auth) {
    localStorage.setItem("JACKERY_ID_TOKEN", auth);
    cookie.set("_jk_id", auth)
  }

  /**
   * 登录过期自动退出
   *
   * @param {boolean} msg - 是否显示message提示
   */
   let timer = null;
  const handleLogout = (msg) => {
    console.log("handleLogout----", res, res.config.url.indexOf("/v1/user/logout") === -1)

    if(msg && (res.config.url.indexOf("/v1/user/logout") === -1 && location.pathname !== "/login" && location.pathname !== "/register")) {
      message.error({ content: t('tips.reLogin'), key: 'invalidTips' });
    clearTimeout(timer);
    }


    timer = setTimeout(() => store.dispatch('app/logout').then(res => {
      if(res) { // 需要登录的页面退回login
        router.push({ path: '/login' , query: {redirect_url: location.href}})
      }
    }), 1000);
  }

  switch (code) {
    case 0:
      let loseToken = data.find(item => item.parameter === 'token');
      loseToken ? handleLogout(true) : message.error(msg);
      return Promise.reject(new Error(`${msg}`));
    case -100:
      notification.error({ message: t('tips.reminder'), description: t('tips.otherError', {code}) });
      return Promise.reject(new Error(`${msg}`));
    case 200: return data;
    case 401: case -501: case -511: case 10000:
      // 登录失效
      console.log('msg', `${msg}`)
      handleLogout(true);
      return Promise.reject(new Error(`${msg}`));
      
    case 500:
      return Promise.reject(new Error(`${msg}`));
    case -513:
      message.error('Your visit is a bit frequent, friend :)');
      return Promise.reject(new Error(`visit is a bit frequent`));
    case -519:
      return Promise.reject({code});
    case -520:
      store.commit('changeMachineVerify', { visible: true, verificationCode: "", email: sessionStorage.getItem('TEMP_VERIFY') })
      return Promise.reject(new Error(`${msg}`));
    case -524:
      message.error(t('tips.wrongPasswordMultipleTimes', { minute: 5 }));
      return Promise.reject(new Error(`${msg}`));
    case -529:
      handleLogout(true);
      return Promise.reject(new Error(`${msg}`));
    case 10002: return { code, data, msg };
    case 10006:
      message.error(t('tips.dontClickRepeatedly'));
      return Promise.reject(new Error(`${msg}`));
    case 40004:
      message.error(t('tips.zipVerifyError'));
      return Promise.reject(new Error(`${msg}`));
    default:
      let m = msg.split(".").length > 1 ? t(msg) : msg;
      notification.error({ message: t('tips.reminder'), description: m });
      return Promise.reject(new Error(`${m}`));
  }
}, err => {
  console.log("response", err);
  notification.error({ message: t('tips.commError'), description: t('tips.networkError') });
  return Promise.reject(err);
});

export const get = (url, p) => httpAx.get(import.meta.env.VITE_API_URL + url, p);
export const post = (url, p) => httpAx.post(import.meta.env.VITE_API_URL + url, p);

export const uGet = (url, p) => httpAx.get(import.meta.env.VITE_USER_URL + url, p);
export const uPost = (url, p) => httpAx.post(import.meta.env.VITE_USER_URL + url, p);
