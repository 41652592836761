export default {
  "login": {
    "sign": "Accedi",
    "login": "Accedi immediatamente",
    "remember": "Ricordami",
    "about": "Chi Siamo",
    "illegal_email": "Formato email non valido",
    "illegal_password": "La password deve essere composta da 6-16 cifre e deve contenere lettere e numeri. Per motivi di sicurezza, imposta una password più sicura.",
    "password_matching": "Le due password non coincidono.",
    "enterEmail": "Indirizzo e-mail/ID Jackery",
    "enter_code": "Inserisci Codice di verifica",
    "forgot": "Hai dimenticato la password?",
    "create": "Crea un account",
    "thirdLogin": "Oppure accedi con",
    "accountActivate": "Account attivato",
    "bindEmail": "Associa la tua e-mail",
    "error": "Accesso non riuscito, riprova più tardi",
    "quickLogin": "Accesso senza password",
    "quickLoginA": "Accedere senza password"
  },
  "policies": {
    "content": "Effettuando l'accesso con una terza parte, l'utente dichiara di aver letto e accettato la nostra l'",
    "policy-link": "https://it.jackery.com/policies/privacy-policy",
    "policy": "Informativa sulla privacy",
    "and": ", e le",
    "terms-link": "https://it.jackery.com/policies/terms-of-service",
    "terms": "Condizioni di utilizzo",
    "comma": ".",
    "footer": "Jackery utilizzerà le informazioni fornite dalla terza parte con cui l’utente ha scelto di registrarsi o di effettuare l'accesso."
  },
  "reset": {
    "modify": "Cambia la password",
    "back": "Torna all'accesso ",
    "success": "Nuova password creata correttamente!",
    "referrer": "Torna alla pagina originale in",
    "seconds": "secondi"
  },
  "register": {
    "title": "Registrati",
    "enterEmail": "Inserisci indirizzo e-mail",
    "agree": "Ho letto e accetto di rispettare ",
    "agree_after": "di Jackery.",
    "getDeals": "Accetto di ricevere le ultime offerte, promozioni e informazioni sui prodotti.",
    "login": "Accesso rapido",
    "success": "Il tuo account è stato '{email}' registrato correttamente!",
    "welcomeEmail": "Ti è stato inviato un messaggio di benvenuto al tuo indirizzo e-mail."
  },
  "header": {
    "logout": 'Esci',
    "userCenter": "Centro account",
    "personalCenter": "Il mio profilo",
    "shopping": "Vai al negozio",
    "setting": "Impostazioni dell'account",
    "myJackery": "Centro account",
    "jackeryID": "Il mio account",
    "wishlist": "La mia lista dei desideri",
  },
  "footer": {
    "cookieContent": "Utilizziamo i cookie, inclusi i cookie di terze parti, per garantire che il sito web funzioni correttamente, analizzare il traffico, fornire una migliore esperienza sui social media e personalizzare contenuti e annunci.",
    "cookieUrl": "#",
    "learn": "Scopri di più",
    "accept": "Accetta i cookie",
    "copyright": "Copyright © {date} Jackery Technology GmbH All Rights Reserved.",
    "sitemap-link": "#",
    "sitemap": "Mappa del sito"
  },
  "account": {
    "welcome": "Benvenuto",
    "email": "Indirizzo e-mail",
    "registerEmail": "Registra e-mail",
    "bindNewRegisterEmail": "Nuovo indirizzo email",
    "emailIsEqual": "Il nuovo indirizzo e-mail è uguale a quello originale, non è necessario modificarlo.",
    "settings": "Impostazioni dell'account",
    "settingTips": "Gestisci rapidamente le informazioni sul tuo account e i dettagli di sicurezza",
    "titleProfiles": "Il mio profilo",
    "titleSecurity": "Sicurezza dell'account",
    "titleAuthorization": "Associa il tuo account",
    "nickname": "Soprannome",
    "name": "Nome",
    "gender": "Genere",
    "birthday": "Data di nascita",
    "contactEmail": "Indirizzo e-mail",
    "enterprise": "Account aziendale",
    "setted": "Set",
    "unset": "Non impostato",
    "password": "Password",
    "pop_password": "Passwort erstellen",
    "apply": "Applica",
    "secrecy": "segretezza",
    "male": "Uomo",
    "female": "Donna",
    "currentPassword": "Password attuale",
    "newPassword": "Nuova password",
    "newShowAgain": "Non mostrare più",
    "againPassword": "Conferma la nuova password",
    "jackeryIdTips": "L'ID Jackery può essere modificato una sola volta. L'ID Jackery deve contenere almeno 6 caratteri e può includere numeri e lettere, ma non può superare i 32 caratteri.",
    "birthdayTips": "Inserisci la tua data di nascita per consentirci di fornirti un servizio della massima qualità. Manterremo tutte le tue informazioni personali strettamente riservate.",
    "nickNameTips": "Il soprannome può essere composto da lettere o numeri e non può superare i 32 caratteri.",
    "nameTips": "Ti consigliamo vivamente di utilizzare il tuo vero nome, per permetterci di contattarti. Manterremo tutte le informazioni personali strettamente riservate.",
    "genderTips": "Inserisci il tuo vero nome per consentirci di fornirti un servizio della massima qualità. Manterremo tutte le tue informazioni personali strettamente riservate.",
    "contactEmailTips": "Utilizza l'indirizzo e-mail che usi più frequentemente, in modo da poterti contattare con facilità. Manterremo le tue informazioni personali strettamente riservate.",
    "phoneNumberTips": "Inserisci il numero di contatto che utilizzi più frequentemente, in modo da poterti contattare con facilità. Manterremo le tue informazioni personali strettamente riservate.",
    "phoneNumberTips2": "Sono supportati solo numeri, parentesi e trattini.",
    "enterpriseNameTips": "Se utilizzi un account aziendale, comunicaci le tue informazioni aziendali pertinenti per consentirci di fornirti un servizio della massima qualità.",
    "emailTips": "L'indirizzo e-mail con cui ti sei registrato coincide con quello del tuo account di accesso. Per proteggere il tuo account utente, è necessaria una verifica di sicurezza per modificare l'indirizzo e-mail registrato.",
    "passwordTips": "Per motivi di sicurezza, imposta una password più sicura.",
    "location": "Ubicazione",

    "contactPhone": "Telefono",
    "avatarSettings": "Impostazioni dell'avatar",
    "choosePicture": "Seleziona immagine",
    "myProfile": "Il mio profilo",
    "changePassword": "Modifica password",
    "creatPassword": "Passwort erstellen",
    "firstName": "Nome",
    "lastName": "Cognome",
    "editPasswordTips": "La password deve essere composta da 6-16 cifre e deve includere lettere e numeri. Per motivi di sicurezza,ti preghiamo di utilizzare un password forte.",
    "subscriberTip": "Ottieni {num} punti per la tua prima iscrizione e-mail e ricevi le ultime notizie e offerte di Jackery. Accetta i Termini di servizio e l'Informativa sulla privacy di Jackery.",
    "birthdayTip1": "Ottieni {num} punti inserendo la data del tuo compleanno.",
    "birthdayTip2": "Guadagna {num} punti per ogni compleanno e raddoppia i punti shopping.",
    "birthdayTip3": "Hai solo una possibilità per modificare.",
    "phoneTip": "Obtener {num} puntos por mi primera suscripción por SMS y recibir las últimas noticias y ofertas de Jackery. Aceptar los Términos y Servicios y la Política de Privacidad de Jackery.",
    "countryRegion": "Paese/Regione",
    "successTip1": "Hai inviato il tuo profilo con successo, {num} punti Jackery saranno disponibili entro 24 ore.",
    "successTip": "Hai inviato il tuo profilo con successo.",
    "errorTip": "Invio non riuscito, controlla e invia di nuovo."
  },
  "router": {
    "memberIndex": "MemberShip",
    "memberGuide": "MemberShip Guide",
    "home": "Casa",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "Registrati",
    "login": "Accedi",
    "reset": "Reset",
    "dashboard": "Dashboard",
    "commonSetting": "Impostazioni generali",
    "account": "Impostazioni dell'account",
    "accountOverview": "Overview",
    "myProfile": "Il mio profilo",
    "myOrders": "Il mio ordine",
    "personalSettings": "Impostazioni personali",
    "addressBook": "Il mio indirizzo",
    "accountSettings": "Impostazioni dell'account",
    "myDevices": "Il mio dispositivo",
    "activate": "Attivo",
    "myService": "Il mio servizio",
    "myReturn": "I miei resi",
    "myWallet": "Il mio portafoglio",
    "myJackeryLife": "My Jackey Life",
    "myWishlist": "La mia lista dei desideri",
    "myReferral": "My Referral",
  },
  "description": {
    "memberIndex": "Jackery",
    "memberGuide": "Jackery",
    "home": "Jackery",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "Crea un ID Jackery per diventare membro Jackery.",
    "login": "Accedi come cliente",
    "reset": "Reset",
    "dashboard": "Dashboard",
    "commonSetting": "Impostazioni generali",
    "account": "Centro account ID Jackery",
    "accountOverview": "Overview",
    "myProfile": "Il mio profilo",
    "myOrders": "Il mio ordine",
    "personalSettings": "Impostazioni personali",
    "addressBook": "Il mio indirizzo",
    "accountSettings": "Impostazioni dell'account",
    "myDevices": "Il mio dispositivo",
    "activate": "Crea una password per attivare il tuo account",
    "registerActivate": "Clicca qui sotto per attivare il tuo account. Una volta attivato, puoi utilizzare l'account per accedere al nostro sito web.",
    "myReturn": "I miei resi",
    "myService": "Il mio servizio",
    "myWallet": "Il mio portafoglio",
    "myJackeryLife": "My Jackey Life",
  },
  "operate": {
    "enable": "Abilita",
    "disable": "Disabilitato",
    "confirm": "Conferma",
    "cancel": "Annulla",
    "remove": "Rimuovi",
    "search": "Cerca",
    "reset": "Reset",
    "add": "Aggiungi",
    "close": "Chiusa",
    "edit": "Modifica",
    "yes": "Sì",
    "no": "No",
    "submit": "Invia",
    "next": "Successivo",
    "prev": "Indietro",
    "back": "ordine"
  },
  "tips": {
    "sure": "Sei sicuro?",
    "placeholder": "Inserisci",
    "enterConsultContent": "Inserisci il contenuto della tua richiesta",
    "all": "Tutto",
    "custom": "Personalizzato",
    "pending": "In sospeso",
    "loading": "Elaborazione...",
    "loading2": "Elaborazione",
    "cancelled": "Annullato",
    "loaded": "Processo completato",
    "loaded2": "Completato",
    "changeShop": "Cambiare negozio",
    "logoutConfirm": "Sei sicuro di voler uscire?",
    "loggedOut": "Disconnessione avvenuta con successo",
    "refreshed": "Aggiornato",
    "noDescribe": "Nessuna descrizione",
    "reminder": "Accorgimento utile",
    "commError": "Ops, qualcosa è andato storto.",
    "reLogin": "Effettua nuovamente l'accesso",
    "verify": "Codice di verifica", "sendVerify": "È stato inviato un codice di verifica al tuo indirizzo e-mail. Controlla la casella e-mail o la posta indesiderata.",
    "getVerifyCode": "Ricevi un codice di verifica",
    "verifyError": "Codice di verifica errato",
    "homePage": "Homepage",
    "404": "Questa pagina non esiste.",
    "404Tips": "Verifica che l'indirizzo IP del server sia corretto, oppure clicca sul link per accedere al sito.",
    "500": "Ops, qualcosa è andato storto.",
    "500Tips": "Errore del server,",
    "500Tips2": "Riprova più tardi",
    "urlExpired": "L'URL di verifica è scaduto",
    "accountNotActivated": "L'account non è stato attivato",
    "accountActivatedSuccess": "L'account è stato attivato correttamente.",
    "requestFrequently": "Non eseguire questa operazione ripetutamente, ma riprovare una volta che la verifica sia stata effettuata.",
    "submitSuccess": "Invio riuscito",
    "zipVerifyError": "Verifica del codice postale non riuscita",
    "otherError": "Si è verificato un errore. Contatta l'amministratore. code: {code}",
    "wrongPasswordMultipleTimes": "Hai inserito troppe password errate. Riprova tra {minute} minuti",
    "networkError": "Errore di Rete",
    "thirdLoginError": "Accesso non riuscito, riprova con il tuo account.",
    "fileTypeError": "Errore di formato del file: è consentito solo il caricamento di file {type}.",
    "copySuccessfully": "copiato"
  },
  "placeholder": {
    "email": "Indirizzo e-mail *",
    "emailOrJackeryID": "Indirizzo e-mail/ID Jackery *",
    "password": "Password *",
    "passwordAgain": "Confermare la nuova password *",
    "enterprise": "Account aziendale",
    "code": "Codice di verifica *",
    "firstName": "Nome",
    "lastName": "Cognome",
    "select": "Selezionare",
    "address1": "Via e numero di telefono",
    "address2": "Appartamento, suite, ecc. (opzionale)",
    "country": "Paese/Regione",
    "province": "Stato/Provincia/Regione",
    "city": "La tua città ",
    "region": "Regione",
    "zip": "Codice ZIP",
    "zipError": "CAP Errato",
    "phone": "Il tuo telefono ",
    "company": "Nome dell'azienda (facoltativo)",
    "isNotEmpty": "Il campo di questa opzione non può rimanere vuoto",
    "pobox": "Non inviamo a Caselle Postali",
    "minChar2": "Minimo 2 caratteri",
    "inputChar": "Il numero dei caratteri è massimo {length}"
  },
  "order": {
    "sn": "Numero d'ordine",
    "detail": "Tracciamento del mio ordine",
    "productName": "Nome del prodotto",
    "quantity": "Quantità",
    "price": "Prezzo ",
    "status": "Stato dell'ordine",
    "consult": "Consultare",
    "contact": "Contattaci",
    "total": "Totale",
    "empty": "Nessuna informazione sull'ordine",
    "questionType": "Tipo di problema",
    "shipped": "Spedito",
    "return": "Restituzione",
    "applied": "Restituito e rimborsato",
    "revoke": "Revoca",
    "serviceType": "Seleziona il tipo di servizio",
    "serviceType_1": "Restituito e rimborsato",
    "serviceType_2": "Scambi e Sostituzioni",
    "packageArrived": "Hai ricevuto gli articoli",
    "reason": "Inserisci un motivo",
    "reason_1": "{day} Giorni Di Restituzione Senza Domande",
    "reason_2": "Problemi di qualità",
    "reason_3": "Altri",
    "quality": "Immettere il problema di qualità specifico",
    "productSerialNumber": "Inserisci il problema di qualità specifico",
    "selectReturnProduct": "Seleziona un prodotto reso",
    "returnQuantity": "Inserisci il numero dei resi/sostituzioni",
    "shippingAddress": "Indirizzo di spedizione",
    "returnAddress": "Indirizzo mittente",
    "revise": "Modifica",
    "uploadVideo": "Carica video/immagine",
    "uploadLoading": "Caricamento in corso",
    "videoLimit": "La dimensione del file non può superare i {limit}M!",
    "uploadVideoError": "Caricamento file non riuscito",
    "failureDetermination": "Risoluzione dei problemi relativi alla qualità del prodotto",
    "steps_1": "1. Procedura di risoluzione dei problemi della stazione di ricarica.",
    "steps_1_content": "1) Premere il display per vedere se la spia è accesa.\n2) Ripristinare la stazione di ricarica e posizionarla in un punto qualsiasi prima di ricaricare nuovamente il dispositivo.\n---Tenere premuto il pulsante 'DISPLAY' per 10 S per i modelli E160/E240/E500/E1000\n---tenere premuto 'DISPLAY+USB' per 10 S per i modelli E1500/E2000\n3) Premere il pulsante sulla parte superiore della presa CA/CC per verificare se la spia è accesa prima di collegare il dispositivo e caricarlo.\n4) Verificare che il dispositivo possa essere ricaricato normalmente.\n\nSe seguendo i passi precedenti i problemi non possono essere risolti la stazione di ricarica è difettosa.\n\n",
    "steps_2": "2. Fasi di risoluzione dei problemi del pannello solare",
    "steps_2_content": "1) Utilizzare il caricabatterie CA in dotazione per caricare la stazione di ricarica e verificarne il funzionamento.\n2) Eseguire il test alla luce solare intensa di mezzogiorno. Collegare il pannello solare con la stazione di ricarica, assicurarsi che non vi siano ombre sul pannello.\n\nSe la stazione di ricarica può essere ricaricata correttamente con un caricabatterie CA ma non con il pannello solare significa che quest’ultimo non funziona correttamente.",
    "learnMore": "Altro",
    "shrink": "Restringere",
    "noReason_30_day": "1. Jackery offre una garanzia di rimborso di 30 giorni per i prodotti acquistati direttamente dal sito Jackery.com. Siamo certi che sarai entusiasta del tuo nuovo prodotto Jackery. Tuttavia, se non sei soddisfatto per qualsiasi motivo, richiedi semplicemente una sostituzione o un rimborso entro 30 giorni.\n\n2. Si prega di notare: i resi senza motivo richiesti oltre i 30 giorni non saranno accettati.\n\n3. Per restituire il prodotto in base alla politica di restituzione entro 30 giorni, assicurarsi che: il prodotto sia restituito nella confezione originale, con tutti i materiali inclusi, in condizioni nuove o come nuove.",
    "selectDelivery": "Selezionare la logistica di restituzione",
    "enterTrackNumber": "Immettere il numero di tracciamento",
    "illegal_trackNumber": "Immettere il numero di tracciamento corretto",
    "enterDescription": "Immettere una descrizione specifica",
    "comingSoon": 'In arrivo',
    "cancelled": 'Annullato',
    "points": "punti",
    "comingSoonTip": '(I punti Jackery verranno attivati il trentunesimo giorno dopo la spedizione del prodotto).',
    "pointsBalance": "Saldo punti",
    "tipTitle": 'Vantaggi per i membri',
    "shopNow": "Acquista ora",
    "tip1": `① <a> Effettuando ordini </a> sul sito ufficiale di Jackery, potrai usufruire della garanzia estesa automatica (fino a 5 anni) e ottenere punti Jackery (1 euro speso  = 1 punto guadagnato).`,
    "tip2": `② <a> Registrando i Prodotti </a> che hai acquistato da canali non ufficiali, estenderai la garanzia (fino a 5 anni).`,
    "tip3": `③ <a> Effettuando un ordine superiore a {num}  </a> sul sito ufficiale di Jackery dopo la registrazione, potrai ottenere un omaggio iniziale.`
  },
  "bank": {
    "name": "Nome della banca",
    "branchName": "nome della filiale",
    "type": "Tipo di carta bancaria",
    "account": "Numero della carta bancaria",
    "realName": "Nome del titolare della carta"
  },
  "return": {
    "orderNumber": "Numero d'ordine",
    "status": "Stato di restituzione/sostituzione",
    "attachment": "Allegato",
    "confirmRevoke": "Annullare la richiesta di restituzione/sostituzione?",
    "returnPolicy_link": "https://it.jackery.com/pages/policy",
    "returnPolicy_1": "Per le FAQ relative alle richieste di riparazioni, restituzioni, sostituzioni e cambi, fare riferimento alla",
    "returnPolicy_2": "Politica post-vendita.",
    "returnPolicy_3": "In caso di domande, contattare la",
    "returnPolicy_4": "support{'@'}jackery.com",
    "returnPolicy_5": ""
  },
  "devices": {
    "empty": "Nessuna informazione sul dispositivo",
    "more": "Altro",
    "extendedWarrantyDeadline": "Data di scadenza della garanzia",
    "warrantyStatus": "Controlla lo stato della tua (Garanzia estesa)",
    "existWarranty": "Estensione della garanzia",
    "nonExistentWarranty": "Garanzia non estesa",
    "userManual": "Manuale d'uso",
    "warrantyInfo": "Fai clic su 'Invia' per attivare la garanzia estesa.",
    "newsletter": "Iscriviti alla nostra newsletter",
    "warrantySuccess": "Estensione della garanzia",
    "download": "Scarica",
    "none": "Nessuno",
    "addNewDevice": "Aggiungi nuovo dispositivo",
    "shopNow": "Acquista ora"
  },
  "address": {
    "empty": "Nessun indirizzo salvato ~",
    "add": "Aggiungi un nuovo indirizzo",
    "edit": "Modifica l'indirizzo",
    "userName": "Nome utente",
    "address1": "Indirizzo 1",
    "address2": "Indirizzo 2",
    "country": "Paese/Regione",
    "country_code": "Paese/CAP",
    "province": "Stato/Provincia/Regione",
    "province_code": "Stato/Provincia/CAP",
    "city": "Città",
    "zip": "Codice Postale",
    "phone": "Numero di telefono",
    "company": "Azienda",
    "default": "Imposta l'indirizzo di spedizione predefinito",
    "isDefault": "Indirizzo predefinito",
    "error": "L'indirizzo di consegna non è corretto o le informazioni sono incomplete, verificare"
  },
  "character": {
    "comma": ","
  },
  // Following is server language pack
  "msg": {
    "success": "Riuscito"
  },
  "user": {
    "log": {
      "expire": "Effettua nuovamente l'accesso"
    }
  },
  "message": {
    "order_status_shipping": "Spedito",
    "order_status_completed": "Completato",
    "order_status_processing": "Elaborazione",
    "order_status_archived": "Spedito",
    "order_status_canceled": "Annullato",
    "order_status_unpaid": "Non pagato",
    "device_not_found": "Il dispositivo non esiste",
    "device_disable_can_not_set_warranty": "Il dispositivo corrente non è valido e non è possibile impostare la garanzia estesa",
    "device_was_warranty": "La garanzia dell'attuale dispositivo è stata estesa",
    "device_not_support_warranty": "Il dispositivo corrente non supporta l'estensione di garanzia"
  },
  "create": {
    "address": {
      "fail": "Impossibile aggiungere l'indirizzo"
    }
  },
  "activation": {
    "email": {
      "expire": "Il messaggio è scaduto"
    }
  },
  "select_country": {
    "title": "Seleziona Paese/Area"
  },



  // 会员首页 多语言
  "member_index" : {
    "common": {
      "login": "Accedi",
      "register": "Registrati",
      "already_a_member": "Sei già membro?",
      "points": "Punti",
      "redeem": "Riscatta",
      "rules": "Regole",
      "copy_successfully": "copiato",
      "outOfStock": "En rupture de stock",
      "quick_login": "Accedere/Registrare"
    },
    // Banner 标题
    "banner": {
      "title": `Jackery<br/>Membership`,
      "description": `Guadagna punti, ottieni vantaggi esclusivi: lascia il segno con Jackery`,
      "points_balance": "Saldo punti",
      "coming_soon": "In arrivo",
      "my_rewards": "I miei premi",
      "my_activity": "La mia attività",
      "unused": "Da usare",
      "to_be_redeemed": "Convertibili",
      "no_unused_rewards": "Nessuna ricompensa disponibile",
      "no_convertible_rewards": "Nessun premio convertibile",
      "no_activity_records": "Al momento non C'è alcuna attività dei punti",
      "time": "Tempo",
      "action": "Azione",
      "points": "Punti",
    },
    "section_tabs": {
      "membership_benefits": "Vantaggi dell'iscrizione",
      "redeem_points": "Riscatta i punti",
      "earn_points": "Guadagna i punti",
      "guide": "Istruzioni",
      "faq": "Domande frequenti (FAQ)",
    },
    // Benefits
    "membership_benefits": {
      "title": "Vantaggi esclusivi dell'iscrizione a Jackery",
      "banner_text": "Ad ogni acquisto guadagni punti Jackery da utilizzare per ottenere premi esclusivi",
      "coupon_benefit": "Ottieni uno sconto di {num} per la tua prima registrazione",
      "giftcard_benefit": "Un omaggio per il tuo primo ordine superiore a {num}",
      "product_benefit": "Ricevi regolarmente dei consigli per la cura dei prodotti",
      "gift_benefit": "Ottieni il 15% di sconto alla scadenza della garanzia"
    },
    // Redeem Points
    "redeem_points": {
      "title": "Ottieni i premi<br/>riscattando i tuoi punti",
      "unusedTips": "Usale al momento del pagamento prima della scadenza!",
      "pointTips": "Hai {0} punti Jackery.",
      "category_tabs": {
        "coupon": "Buono sconto",
        "gift_card": "Buono regalo",
        "jackery_products": "Prodotto Jackery",
        "virtualCard": "Extra",
      },
      "code": "Codice",
      "redeem_confirm_title": "Verranno usati {0} punti. Una volta terminata l'operazione non potranno essere restituiti.",
      "redeem_confirm_subtitle": "",
      "redeem_confirm_content": "Vuoi davvero riscattarli?",
      "successfully_redeemed": "Riscattati correttamente",
      "information_tips": "I dettagli della carta regalo sono stati inviati al tuo inidirzzo e-mail, controlla la tua casella di posta.",
      "copy_and_use": "Copia e usa",
      "day": "g",
      "hour": "h",
      "minute": "min",
      "seconds": "s",
      "count_down_info": "La campagna è terminata e non possibile redimere le ricompense. Si prega di aggiornare la pagina."
    },
    // 获取积分方式
    "earn_points": {
      "title": "Come guadagnare i punti",
      "go_now": "Vai subito",
      "points_earned": "Punti guadagnati",
      "membership_registration": "Iscrizione",
      "membership_registration_desc": "Ottieni {0} punti Jackery quando ti iscrivi.",
      "email_subscribtion": "Iscrizione tramite e-mail",
      "email_subscribtion_desc": "Ottieni {0} punti Jackery iscrivendoti tramite e-mail.",
      "sms_subscribtion": "Iscrizione tramite SMS",
      "sms_subscribtion_desc": "Ottieni {0} punti Jackery iscrivendoti tramite SMS.",
      "fill_in_your_birthday": "Inserisci il tuo compleanno",
      "fill_in_your_birthday_desc": "Ottieni {0} punti inserendo il tuo compleanno.<br/>Ad ogni compleanno ricevi {1} punti e raddoppi i tuoi punti per gli acquisti.",
      "extend_your_warranty": "Estendi la tua garanzia",
      "extend_your_warranty_desc": `
        Ottieni {0} punti registrando ogni volta i prodotti sul sito ufficiale di Jackery.
        <br/>
        (Per i clienti che hanno acquistato presso rivenditori terzi.)
      `,
      "shop_and_earn": "Acquista e guadagna",
      "shop_and_earn_desc": "Quando fai acquisti, 1 euro speso = 1 punto guadagnato<br/>{0} punti per {1} ordine<br/>{2} punti per gli ordini che superano i {3}",
      "tip": "Gli abbonamenti multi-sito contano come un solo punteggio di abbonamento."
    },
    "guide": {
      "title": "Come funziona",
      "step_title_1": "1. Iscriviti",
      "step_content_1": "Crea un account Jackery e approfitta dei vantaggi esclusivi dell'iscrizione.",
      "step_title_2": "2. Guadagna i punti",
      "step_content_2": `Completa le operazioni per guadagnare punti (ad esempio, inserisci i dati personali, acquista prodotti, partecipa a eventi, ecc.)`,
      "step_title_3": "3. Ottieni i premi",
      "step_content_3": `Riscatta offerte esclusive (come buoni sconto, buoni regalo, ecc.) usando i tuoi punti Jackery.`,
      "learn_more": "Scopri di più",
    },
    // 注册 指引页
    "register_guide": {
      "title": "Come ottenere i Premi per i membri di Jackery?",
      "step_1_title": "Passo 1 - Registrati e Accedi",
      "step_1_subtitle_1": "[Registrati]",
      "step_1_content_1": `① Vai alla <a class="text-primary hover:text-primary" href="https://account.jackery.com/register?CR=IT" target="_blank">pagina di registrazione</a> o scegli "Crea un account" su <a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=IT" target="_blank">account.jackery.com</a>`,
      "step_1_content_2": "② Inserisci il tuo indirizzo email e la password.",
      "step_1_content_3": "③ Conferma la tua nuova password e inserisci il tuo codice di verifica ottenuto tramite la tua email.",
      "step_1_content_4": `④ Seleziona "Accetto l'informativa sulla privacy e i termini di servizio di Jackery" e "Accetto di ricevere le ultime notizie, offerte, promozioni e informazioni sui prodotti di Jackery." Leggi l'Informativa sulla privacy e i Termini di servizio di Jackery per proteggere i tuoi diritti.`,
      "step_1_content_5": `⑤ Fai clic su "Invia" per completare la registrazione.<br/>(Se ti sei registrato, salta questo passaggio o accedi.)`,
      "step_1_subtitle_2": "[Accedi]",
      "step_1_content_6": `① Ci sono 3 modi per <a class="text-primary hover:text-primary" href="https://account.jackery.com/login?CR=IT" target="_blank">effettuare il login</a>:`,
      "step_1_content_7": `Vai alla pagina di accesso`,
      "step_1_content_8": `Scegli "Accedi ora" su <a class="text-primary hover:text-primary" href="https://account.jackery.com/register?CR=IT" target="_blank">account.jackery.com</a>`,
      "step_1_content_9": `Fai clic sull'icona "Account" in alto a destra nella home page.`,
      "step_1_content_10": `② Inserisci il tuo indirizzo email e la password. Puoi anche effettuare l’accesso con Google o Amazon.`,
      "step_1_content_11": `③ Fare clic su "ACCEDI" per accedere al centro account.`,
      "step_1_img_1_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/1_672be353-0ce6-4a8c-a0ab-5049c98100b9.png?v=1690168372",
      "step_1_img_2_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/2_3d5ec5e4-e6aa-44da-b9dc-eb1cefcbd1a7.png?v=1690168373",
      "step_1_img_3_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/4_2332aa19-3793-4027-8caf-91bc17e0cba7.png?v=1690168372",
      "step_1_img_4_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/3_ef573e9c-d651-4942-91c7-b24ab0206dcd.png?v=1690168372",
      "step_1_img_5_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/5_50eb8045-c1e7-4bb0-9d20-e3b2c8a423f5.png?v=1690168372",
      "step_1_img_6_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/6.1.png?v=1690168372",
      "step_1_img_1_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/1_705e07c4-65a3-4064-b362-a94a5538cb6d.png?v=1690168204",
      "step_1_img_2_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/2_2a0e4c69-428f-446b-8300-200a274c4ba1.png?v=1690168204",
      "step_1_img_3_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/3.1.png?v=1690168203",
      "step_1_img_4_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/3.2.png?v=1690168204",
      "step_1_img_5_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/3.3.png?v=1690168207",
      "step_1_img_6_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/3.4.png?v=1690168203",

      "step_2_title": "Passo 2 - Guadagna punti Jackery",
      "step_2_content_1": `Scopri come guadagnare punti Jackery nella <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=IT" target="_blank">pagina Iscrizione</a> alla sezione "Modalità per guadagnare punti".`,
      "step_2_img_1_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/6.2.png?v=1690168370",
      "step_2_img_1_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/3.5.png?v=1690168202",

      "step_3_title": "Passo 3 - Controlla i punti Jackery",
      "step_3_content_1": `Dopo aver effettuato l'accesso al tuo account, puoi controllare il tuo saldo punti tramite il <a class="text-primary hover:text-primary" href="https://account.jackery.com/account/overview?CR=IT" target="_blank">Centro Account</a> o la <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=IT" target="_blank">pagina dell'Iscrizione</a>.`,
      "step_3_content_2": `① Centro Account: Ci sono tre voci per controllare i tuoi punti: "Panoramica", "Il mio profilo" e "Il mio portafoglio".`,
      "step_3_content_3": `② Cliccando su "Saldo punti", puoi passare alla <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=IT" target="_blank">pagina dell'Iscrizione</a>, dove potrai anche visualizzare il tuo saldo punti.`,
      "step_3_content_4": `Esistono due tipi di punti: Punti accreditati e punti in sospeso.`,
      "step_3_content_5": `I punti accreditati verranno visualizzati nel "Saldo punti" e potranno essere riscattati.`,
      "step_3_content_6": `I punti in sospeso verranno mostrati nella sezione "Prossimamente" e non possono essere riscattati.`,
      "step_3_content_7": `Tuttavia, i punti nella sezione "Prossimamente" verranno trasferiti al "Saldo punti" alla data specificata.`,
      "step_3_content_8": `Ad esempio, i punti guadagnati da un acquisto verranno visualizzati nella sezione "Prossimamente" quando la merce viene spedita. Dopo 30 giorni quei punti saranno spostati nel "Saldo punti".`,
      "step_3_img_1_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/7_5217b1a8-3ecc-4216-af86-327126408e7c.png?v=1690168370",
      "step_3_img_2_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/8_b2bd8ec4-cb12-44c1-86c6-880686de2dd7.png?v=1690168369",
      "step_3_img_3_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/9_4b41b64a-232b-40c6-9e57-4c85b9e64d62.png?v=1690168370",
      "step_3_img_4_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/10_8cbff28d-13d2-4c02-8aaa-019d07e4826f.png?v=1690168370",
      "step_3_img_1_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/4.1.png?v=1690168205",
      "step_3_img_2_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/4.2.png?v=1690168206",
      "step_3_img_3_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/4.3.png?v=1690168206",
      "step_3_img_4_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/4.4.png?v=1690168207",

      "step_4_title": "Passo 4 - Riscatta punti jackery",
      "step_4_content_1": `① Accedi alla <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=IT" target="_blank">pagina Iscrizione</a>, fai clic su "I miei premi- Da riscattare" o sfoglia la scheda "Ottieni premi riscattando i tuoi punti"`,
      "step_4_content_2": `Le ricompense che possono essere riscattate con il tuo saldo punti corrente verranno evidenziate.`,
      "step_4_content_3": `② Scegli la ricompensa che desideri e fai clic su "Riscatta", tocca "Conferma" sulla finestra a comparsa e potrai ottenere le ricompense.`,
      "step_4_img_1_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/11.png?v=1690168371",
      "step_4_img_2_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/12_6e9e8f5d-ffa9-413e-ad41-4e8e50ae09ba.png?v=1690168370",
      "step_4_img_3_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/13_75abf958-b5a4-46c0-846c-5cd3e9e98288.png?v=1690168369",
      "step_4_img_4_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/14_28392a1b-6b02-4d13-a5ec-c3e63459819a.png?v=1690168370",
      "step_4_img_1_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/4.5.png?v=1690168206",
      "step_4_img_2_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/4.6.png?v=1690168205",
      "step_4_img_3_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/5_db35286b-ba9a-405e-9b69-94b747d4d98b.png?v=1690168206",
      "step_4_img_4_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/7_09f8104b-6d7e-41c0-92d0-dafc4d93af52.png?v=1690168206",

      "step_5_title": "Passo 5 - Usa le ricompense",
      "step_5_content_1": `Se hai riscattato i Punti Jackery con buoni sconto o carte regalo, clicca su "Copia e Usa" e il codice verrà copiato. Acquista sul sito ufficiale di Jackery, usa il codice al momento del pagamento, puoi risparmiare di più sul tuo acquisto.`,
      "step_5_content_2": `Se hai riscattato Punti Jackery sufficienti per un Prodotto Jackery, puoi ottenere il prodotto gratuitamente incollando il codice sconto al momento del pagamento.`,
      "step_5_img_1_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/15_643f6edd-2b41-4be3-a79c-504b4ebba99c.png?v=1690168370",
      "step_5_img_2_pc": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/16_e34f7af6-6346-46ba-93d8-9c3436cf2c4d.png?v=1690168368",
      "step_5_img_1_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/8_aa2bcfe5-1d04-4d55-98c9-bc643dde0cd2.png?v=1690168205",
      "step_5_img_2_mb": "https://cdn.shopify.com/s/files/1/0692/2443/1898/files/9_a6c64447-1674-4717-b781-e31368abe746.png?v=1690168204",
    },
    "faq": {
      "title": "Domande Frequenti",
      "membership": {
        "title": "Jackery Membership",
        "question_1": "1. Cos'è Jackery Membership?",
        "answer_1": `Il programma di Jackery Membership, è un programma a punti attraverso il quale i nostri clienti (come te!) guadagnano punti su <a class="text-primary hover:text-primary" href="https://it.jackery.com">it.jackery.com</a>. I tuoi punti possono essere riscattati in cambio di premio. L’iscrizione è completamente gratuita. È il nostro modo di ringraziarti e di dimostrarti quanto apprezziamo la tua fiducia.`,
        "question_2": "2. Come faccio a iscrivermi a Jackery?",
        "answer_2": `
          È necessario registrare un account Jackery per diventare uno dei membri Jackery.
          <br/>
          Se non ne hai uno, registrati ora. Ecco la guida <a class="text-primary hover:text-primary" href="/index/guide" target="_blank">passo-passo</a> per iscriverti.
          <br/>
          Se hai già un account sul sito ufficiale di Jackery, puoi attivare l’iscrizione o accedere al tuo account esistente.
        `,
        "question_3": "3. Devo registrarmi di nuovo dall'altro sito di Jackery se mi sono già registrato?",
        "answer_3": "Una volta che l'utente si è registrato, il suo account è compatibile con tutti i siti ufficiali Jackery.",
        "question_4": "4. Se ho molti account Jackery, posso unirli in uno solo?",
        "answer_4": "Se ti registri con indirizzi email differenti, l'ID Jackery sarà diverso e non potrà essere unito.",
        "question_5": "5. Come posso annullare l'iscrizione a Jackery?",
        "answer_5": `
          Contatta il nostro servizio clienti per cancellare il tuo account. I punti verranno azzerati.
          <br/>
          Gli utenti disconnessi possono diventare di nuovo membri di Jackery attraverso il processo di registrazione. I punti verranno ricalcolati.
          <br/>
          Se desideri registrarti nuovamente come membro, per garantire l'accesso, ti preghiamo di utilizzare un indirizzo email diverso per la registrazione.
        `,
      },
      "earn_points": {
        "title": "Guadagna punti",
        "question_1": "1. Come guadagnare punti?",
        "answer_1": `
          Quando diventi un membro di Jackery, puoi guadagnare punti completando le attività specificate sul sito ufficiale. Di seguito ti forniamo alcune note.
          <br/>
          <br/>
          ① Registrazione dell'iscrizione: Se ti sei registrato prima di aprile 2022, devi attivare la tua iscrizione accedendo e verificando il tuo account. Puoi guadagnare punti per la tua prima registrazione o attivazione.
          <br/>
          ② Iscrizione via e-mail: Puoi guadagnare punti iscrivendoti via Email la prima volta. Non riceverai alcun punto per una re-iscrizione dopo l'annullamento dell'iscrizione.Gli abbonamenti multi-sito contano come un solo punteggio di abbonamento.
          <br/>
          ③ Iscrizione via SMS: Puoi guadagnare punti iscrivendoti agli SMS la prima volta. Devi inserire il tuo numero di telefono in "Il mio account-Il mio profilo" o nella pagina di "Estensione della garanzia", oltre che iscriverti nel menu a piè di pagina del sito web.Gli abbonamenti multi-sito contano come un solo punteggio di abbonamento.
          <br/>
          ④ Inserisci la data del tuo compleanno: Puoi guadagnare punti inserendo la data del tuo compleanno. Ottieni punti per ogni compleanno e raddoppia i punti acquisto. Il tuo compleanno può essere modificato solo una volta.
          <br/> 
          ⑤ Estensione della garanzia: Solo per gli utenti che acquistano da piattaforme di terze parti come Amazon, Walmart, ecc. Una volta estesa correttamente la garanzia del prodotto dal sito Web ufficiale, i punti verranno emessi in base alle regole.
          <br/>
          ⑥ Punti di acquisto: Dopo aver acquistato i prodotti sul sito ufficiale di Jackery, è possibile ottenere punti in base al valore totale degli ordini. Inoltre, il {0} ordine o il valore di acquisto che raggiunge {1} consentono di ottenere punti extra.
          <br/>      
          ⑦ Partecipazione ad attività con punti extra: Puoi ottenere punti partecipando alle attività con punti extra sul sito ufficiale di Jackery. Per i dettagli, fare riferimento alla descrizione dell'attività nella pagina dell'iscrizione a Jackery.
        `,
        "question_2": "2. Quando verranno accreditati i punti sul mio account?",
        "answer_2": `
          Esistono due tipi di tempi di consegna dei punti:
          <br/>
          <br/>
          ① Emissione rapida: Dopo aver completato l'attività specificata sul sito Web ufficiale, come la registrazione, l'iscrizione, l’inserimento della data di compleanno e l'estensione della garanzia, i punti verranno accreditati sul tuo account entro 24 ore.
          <br/>
          ② Emissione ritardata: I punti relativi agli acquisti saranno emessi come "punti in arrivo" dopo la spedizione della merce. 30 giorni dopo, diventeranno attivi nel "saldo punti". (Quando un ordine include più prodotti, prevarrà la data di spedizione del primo prodotto). L'ammontare dei punti che ricevi per un ordine qualificato si basa sull'effettivo pagamento totale.
          <br/>
          <br/>
          Va notato che i punti in sospeso non possono essere utilizzati per riscattare i vantaggi dal sito web ufficiale.
        `,
        "question_3": "3. Cosa posso fare se i punti non sono stati accreditati correttamente sul mio account?",
        "answer_3": `Se hai domande relative al tuo account di iscrizione e al saldo dei punti, contatta il nostro Servizio Clienti all'indirizzo <a class="text-primary hover:text-primary" href="mailto:hello.eu{'@'}jackery.com">hello.eu{'@'}jackery.com</a>.`,
        "question_4": "4. I punti scadono?",
        "answer_4": `
          I punti Jackery sono validi per due anni. I punti guadagnati dagli utenti scadranno entro 2 anni dal lancio ufficiale del Programma d’iscrizione (29 giugno 2023) e i punti scaduti verranno detratti dal rispettivo account.
          <br/>
          Al fine di proteggere i tuoi diritti e interessi, ti preghiamo di controllare e riscattare i tuoi punti disponibili in tempo.
        `,
        "question_5": "5. Riceverò punti per le transazioni effettuate prima del lancio del Programma d’iscrizione Jackery?",
        "answer_5": "Sì! Jackery Membership verrà ufficialmente lanciata il 29 giugno 2023, ti verranno rilasciati punti corrispondenti originali per i tuoi acquisti o il completamento di attività specificate sul sito ufficiale di Jackery (a partire dal 1 ° gennaio 2020), a condizione che ti registri come membro di Jackery. I Punti Originali scadranno tra 2 anni (a partire dal 29 giugno 2023).",
        "question_6": "6. Le tasse o le spese di spedizione possono essere accreditate come punti?",
        "answer_6": "No. Il calcolo dei punti esclude il pagamento da parte del Membro delle spese di spedizione e di tutte le tasse, incluse, a titolo esemplificativo ma non esaustivo, le tasse federali, statali e locali o le tasse di utilizzo.",
        "question_7": "7. Cosa succede ai miei punti se annullo un ordine, restituisco un prodotto o richiedo un rimborso parziale?",
        "answer_7": "Qualsiasi ordine annullato, rimborsato o restituito annullerà o ridurrà i punti ricevuti per tale acquisto. I punti verranno detratti per i rimborsi parziali causati dall'adeguamento dei prezzi.",
        "question_8": "8. Dichiarazione speciale sui punti cross-site / Tasso di cambio / Metodo di visualizzazione dei punti",
        "answer_8": `
          I Punti Jackery possono essere accumulati e utilizzati su tutti i siti web ufficiali di Jackery.
          <br/>
          Se disponi di saldi di punti sui diversi siti ufficiali di Jackery, il sistema mostrerà il valore totale dei punti dopo aver convertito i saldi sui diversi siti grazie ai tassi di cambio.
          <br/>
          Il numero totale di punti viene convertito in base all'ultimo tasso di cambio del dollaro USA il 1° di ogni mese, quindi il tuo saldo punti cambierà ogni mese in base al tasso di cambio.
          <br/>
          I punti sono espressi in numeri interi; le frazioni saranno conteggiate nel valore dei punti, ma non saranno visualizzate e non potranno essere riscattate.
        `,
      },
      "redeem": {
        "title": "Riscatta i punti",
        "setting1": "Gli utenti possono riscattarli fino a {time} volte negli ultimi {num} giorni.",
        "setting2": "Gli utenti possono riscattare i premi solamente {time} volte.",
        "setting3": "Gli utenti possono riscattare i tutti i premi che rientrano nel proprio saldo punti.",
        "question_1": "1. Come posso riscattare i punti Jackery per ottenere i benefici?",
        "answer_1": `
          Puoi accedere alla pagina iniziale dedicata ai membri (membership.jackery.com). Verranno evidenziati i benefici che possono essere riscattati Puoi selezionare i benefici e cliccare su "Riscatta".
          <br/>
          Se accedi al tuo centro account, cliccando su "Saldo punti" potrai accedere alla pagina iniziale del sito per riscattare i tuoi punti e ottenere benefici.
          <br/>
          I punti non sono rimborsabili una volta riscattati. Assicurati di essere sicuro di voler eseguire il riscatto prima dell'elaborazione.
        `,
        "question_2": "2. Per quali benefici possono essere riscattati i punti Jackery?",
        "answer_2": `
          Offriamo una vasta gamma di benefici. I punti possono essere riscattati per ottenere buoni sconto, carte regalo, prodotti Jackery, regali interessanti e altro ancora. I benefici sono validi solo per il sito in cui sono stati accreditati o riscattati i punti e non possono essere utilizzati in altri siti. Si prega di utilizzare i premi entro il periodo di validità. Per maggiori dettagli, consulta la pagina iniziale dei membri. Gli utenti possono riscattare i premi per un numero limitato di volte entro un determinato periodo; riceverete un avviso di sistema quando il limite di riscatto è stato raggiunto. Una volta entrati in un nuovo ciclo, è possibile riscattare nuovamente i premi.
          <br/>
          <br/>
          Note
          <br/>
          ① Buono sconto Jackery: Ogni buono ha uno sconto specifico, un importo minimo dell'ordine, i prodotti applicabili e un periodo di validità. Si prega di controllare le informazioni dettagliate di ogni buono quando lo si utilizza. I buoni non possono essere combinati con altre offerte, tra cui vendite, buoni, ecc. {0}
          <br/>
          ② Carta regalo Jackery: Ogni carta regalo ha una denominazione e un periodo di validità specifici. Si prega di controllare le informazioni dettagliate di ogni carta regalo quando la si utilizza. {1}
          <br/>
          ③ ③Prodotto Jackery：L’offerta non può essere combinata o cumulata con altre promozioni, compresi saldi e coupon. Per richiedere l’offerta, inserire il codice e l’indirizzo e-mail corrispondente al momento del pagamento. Ricorda che ogni coupon prodotto Jackery può essere utilizzato solo per l’acquisto di uno specifico articolo. {2}
          <br/>
          ④ Carte regalo non-Jackery: È disponibile un numero limitato di carte regalo non Jackery. Se l'inventario totale è esaurito, potresti ricevere un messaggio di "esaurimento scorte" e non potrai riscattare i tuoi punti. Le carte regalo non Jackery non vengono visualizzate quando sono esaurite. Per i dettagli sull'utilizzo di buoni regalo non Jackery, visitare il sito Web ufficiale del marchio. {3}
        `,
        "question_3": "3. Come posso verificare i benefici che ho riscattato?",
        "answer_3": `
          ① Accedi al centro account (<a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=IT">account.jackery.com</a>), verifica in "Il mio portafoglio". Oppure accedi alla homepage dei membri (<a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=IT">membership.jackery.com</a>) e verifica in "I miei premi".
          <br/>
          ② Riceverai le e-mail di avviso per la riscossione dei premi, tra cui buoni sconto, carte regalo, prodotti Jackery, ecc.
        `,
        "question_4": "4. I miei premi, benefici e punti possono essere convertiti in denaro?",
        "answer_4": `
          I premi, i benefici e i punti guadagnati tramite il Programma di affiliazione Jackery non hanno alcun valore in denaro, non possono essere convertiti in denaro e non possono essere trasferiti a qualcun altro. (Ad eccezione delle carte regalo che puoi inviare ai tuoi amici o familiari.)
          <br/>
          Non è consentito vendere i benefici che hai riscattato a terzi.
          <br/>
          Jackery ha il diritto di intraprendere azioni legali contro transazioni non autorizzate.
        `,
        "question_5": "5. Devo pagare la spedizione per i prodotti riscattati?",
        "answer_5": "Nella maggior parte dei casi, offriamo la spedizione gratuita sui prodotti riscattati. Tuttavia, potrebbe essere richiesto il pagamento delle spese di spedizione per determinati prodotti.",
        "question_6": "6. Posso annullare, restituire o scambiare i premi che sono stati riscattati con i punti?",
        "answer_6": `
          Una volta completato il riscatto, non può essere annullato. Le ricompense non possono essere restituite o scambiate.
          <br/>
          Se il prodotto arriva danneggiato, si prega di contattare il servizio clienti all'indirizzo <a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=IT" target="_blank">support.jackery.com</a> entro 7 giorni.
        `,
        "question_7": "7. I prodotti riscattati possono essere spediti con i prodotti che ho acquistato da Jackery?",
        "answer_7": `
          No. I prodotti riscattati (un articolo ottenuto tramite riscatto in punti o dal sorteggio) saranno spediti separatamente e saranno consegnati entro 60 giorni lavorativi.
          <br/>
          Affinché il sito Web ufficiale possa organizzare la consegna in modo tempestivo, è necessario inviare o confermare le informazioni sull'indirizzo.
        `,
      },
      "others": {
        "title": "Altri",
        "question_1": "1. Contattaci per ulteriore assistenza",
        "answer_1": `
          Se hai ulteriori domande, invia un'e-mail al nostro team di assistenza clienti all'indirizzo <a class="text-primary hover:text-primary" href="mailto:hello.eu{'@'}jackery.com">hello.eu{'@'}jackery.com, visita <a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=IT" target="_blank">support.jackery.com</a> per assistenza o clicca per contattarci.
        `,
        "question_2": "2. Gestione delle violazioni delle regole sui punti dell’utente membro Jackery",
        "answer_2": `Se un utente ha agito malevolmente o in modo falso nell’ottenere punti, nell’utilizzare i punti o nell’ottenere vantaggi, Jackery si riserva il diritto di congelare o annullare i punti dell’utente, di interrompere la fornitura di servizi o di cancellare l’account dell’utente, a seconda dei casi, e di non accettare la richiesta di reiscrizione dell’utente.`,
        "question_3": "3. Dichiarazione speciale",
        "answer_3": `
          Tutti i diritti sull'iscrizione a Jackery riservati a Jackery.
          <br/>
          I premi del programma fedeltà Jackery sonno soggetti a cambiamenti; si prega di consultare l’hompage del programma per maggiori dettagli.
        `,
        "question_4": "4. Registrazione malevola (incluso l'uso di false informazioni o la creazione di più account per ottenere premi aggiuntivi) porterà alla squalifica",
        "answer_4": `
          Jackery si riserva tutti i diritti di annullare premi o ordini ottenuti tramite i seguenti metodi:
            <br/>
            ① Uso di Informazioni False: È severamente vietato utilizzare nomi, indirizzi, indirizzi email o altre informazioni personali false per creare account.
            <br/>
            ② Creazione di Più Account: È severamente vietato per un singolo utente creare più account per ottenere premi o sconti aggiuntivi. Ciò include l'utilizzo di diversi indirizzi email o qualsiasi informazione identitaria per registrare più account.
            <br/>
            ③ Strumenti di Registrazione Automatici: L'uso di qualsiasi strumento o script automatico per la registrazione di account in massa è severamente vietato.
            <br/>
            ④ Comportamento Malevolo: Qualsiasi altro comportamento ritenuto malevolo, incluso lo sfruttamento delle vulnerabilità del sistema o il coinvolgimento in transazioni fraudolente, è severamente vietato.
          `,
      }
    }
  },
  // overview
  "overview": {
    "unit": 'punti',
    "pointsBalance": "Saldo punti",
    "comingSoon": "In arrivo",
    "benefitsForYou": "Vantaggi per te",
    "welcomeGift": "Regalo di benvenuto",
    "welcomeGiftInfo": "100% vincente, buoni sconto/carte regalo/oggetti reali ti aspettano.",
    "firstRegistration": "Prima registrazione",
    "firstRegistrationInfo": "Ottieni {num} di sconto alla tua prima iscrizione su Jackery.",
    "largeOrderBenefits": "Vantaggi per i grandi ordini",
    "largeOrderBenefitsInfo": "Omaggio per i tuoi singoli ordini superiori a {num}.",
    "referral": "Raccomanda un amico",
    "referralInfo": "Invia uno sconto ai tuoi amici, Ricevi premi sul loro primo acquisto!",
  },
  // 我的钱包
  "wallet": {
    "unit": 'punti',
    "jackeryPointsBalance": "Saldo punti",
    "comingSoon": "In arrivo",
    "pointsDetails": "La mia attività",
    "redeemMyCoins": "Riscatta i tuoi punti",
    "coupon": "Buono sconto",
    "giftCard": "Buoni regalo",
    "jackeryProducts": "Prodotto Jackery",
    "extras": "Extra",
    "used": "Usato",
    "expired": "Scaduto",
    "daysLeft": "{num} giorni rimanenti",
    "code": "Codice",
    "rules": "Regole",
    "copyAndUse": "Copia e utilizza",
    "noCoupon": "Nessun buono disponibile",
    "noGiftCard": "Nessun buono regalo disponibile",
    "noJackeryProducts": "Nessun buono prodotto Jackery disponibile",
    "noExtras": "Nessun extra disponibile",
    "redeemMyPoints": "Riscatta i tuoi punti",
    "viewMore": "Visualizza Altro",
    "couponRules": {
      "one": {
        "title": "1. Che cos'è un buono sconto",
        "step1": "1) I buoni sconto sono forme di fidelizzazione del cliente che Jackery distribuisce agli utenti attraverso attività promozionali, marketing mirato, raccolta di punti fedeltà e altre modalità per ridurre il costo degli acquisti.",
        "step2": "2) I buoni sconto lanciati da Jackery sono disponibili solo in formato digitale e possono essere usati per l'acquisto di prodotti sul sito ufficiale.",
        "step3": "3) Al momento dell'acquisto e del pagamento di un ordine, basta inserire il codice sconto del buono di Jackery e lo sconto/importo relativo verrà automaticamente detratto dall'importo totale dell'ordine. Il buono sconto non può essere convertito in contanti.",
      },
      "two": {
        "title": "2. Come si controllano i buoni sconto?",
        "step1": "1) È necessario registrarsi come membro Jackery e andare su Centro utente - Il mio portafoglio - Buoni sconto. In questo modo si potranno controllare le informazioni di tutti i buoni sconto, sia quelli da usare, sia quelli già utilizzati o scaduti.",
        "step2": "2) Bisogna prestare attenzione al periodo di validità del buono. Si consiglia di utilizzarlo entro il periodo di validità; in caso di scadenza non verrà effettuato alcun rimborso.",
      },
      "three": {
        "title": "3. Come si usa un buono sconto:",
        "step1": "1) Ogni buono ha un determinato sconto, una spesa minima richiesta, dei prodotti a cui si applica e un periodo di validità. Controlla le informazioni dettagliate prima di utilizzare un buono sconto.",
        "step2": "2) Siti interessati: Un buono sconto è valido solo per il sito in cui è stato emesso e riscattato, non può essere utilizzato su altri siti.",
        "step3": "3) È possibile utilizzare un solo buono sconto per lo stesso ordine e non può essere combinato con altre promozioni. Se un ordine comprende più articoli, il buono sconto verrà applicato solo agli articoli idonei.",
        "step4": "4) Alcuni buoni sono associati a un account e possono essere utilizzati solo a titolo personale. L'indirizzo e-mail indicato nella pagina dell'ordine deve corrispondere all'indirizzo e-mail del destinatario del buono e non è trasferibile. Alcuni buoni possono essere regalati ad altri utenti, ma è necessario consultare le istruzioni di utilizzo del buono sconto per ulteriori dettagli.",
        "step5": "5) Il buono sconto può essere utilizzato insieme ai buoni regalo.",
        "step6": "6) I membri possono utilizzare i punti per ottenere buoni sconto e, dopo la detrazione dei punti, sia i punti che i buoni sconto sono irrevocabili. Potrebbe esserci un limite al numero di volte in cui i punti fedeltà possono essere riscattati per i buoni sconto nell'arco di un certo intervallo di tempo.",
        "step7": "7) Quando si utilizza un buon sconto in un ordine, il buono sconto non può essere restituito in caso del rimborso di tale ordine."
      },
      "four": {
        "title": "4. Gestione della violazione delle regole di utilizzo dei buoni sconto",
        "step1": "Qualora l'utente utilizzi un buono sconto falsificato, acquistato, venduto o trasferito da un altro account, Jackery ha il diritto di bloccare o annullare detto buono sconto e tutti i buoni detenuti dagli utenti interessati, annullare gli ordini in cui hanno utilizzato dei buoni sconto, bloccare la possibilità dell'utente di riscattarne di nuovi, interrompere la fornitura di servizi o eliminare l'account dell'utente. Jackery ha inoltre il diritto di non accettare eventuali nuove richieste di registrazione da parte dell'utente."
      }
    },
    "giftRules": {
      "one": {
        "title": "1. Che cos'è un buono regalo di Jackery",
        "step1": "1) I buoni regalo sono forme di fidelizzazione del cliente che Jackery distribuisce agli utenti attraverso attività promozionali, marketing mirato, raccolta di punti fedeltà e altre modalità per ridurre il costo degli acquisti.",
        "step2": "2) I buoni regalo lanciati da Jackery sono disponibili solo in formato digitale e possono essere usati per l'acquisto di prodotti sul sito ufficiale.",
        "step3": "3) Al momento del pagamento di un ordine, basta inserire il codice del buono regalo di Jackery e l'importo verrà automaticamente detratto dall'importo totale dell'ordine. I buoni regalo non possono essere convertiti in contanti.",
      },
      "two": {
        "title": "2. Come si controllano i buoni regalo?",
        "step1": "1) È necessario registrarsi come membro Jackery e andare su Centro utente - Il mio portafoglio - Buoni regalo. In questo modo si potranno controllare le informazioni di tutti i buoni regalo, sia quelli da usare, sia quelli già utilizzati o scaduti.",
        "step2": "2) Bisogna prestare attenzione al periodo di validità del buono regalo. Si consiglia di utilizzarla entro il periodo di validità, poiché Jackery non prevede rimborsi in caso di scadenza.",
      },
      "three": {
        "title": "3. Istruzioni per l'utilizzo dei buoni regalo:",
        "step1": "1) Ogni buono regalo ha un valore prestabilito e una scadenza specifica. Controlla le informazioni dettagliate prima di utilizzare un buono regalo.",
        "step2": "2) Siti interessati: Un buono regalo è valido solo per il sito in cui è stato emesso o riscattato, non può essere utilizzato su altri siti.",
        "step3": "3) Non è richiesta una spesa minima per l'utilizzo di un buono regalo. Si può applicare a tutti i prodotti.",
        "step4": "4) I buoni regalo non sono limitati all'uso nell'account personale e possono essere trasferiti come regalo ai propri amici.",
        "step5": "5) È possibile utilizzare più buoni regalo per lo stesso ordine, combinarli con le promozioni e utilizzarli insieme ai buoni sconto.",
        "step6": "6) I membri possono utilizzare i punti per ottenere buoni regalo e, dopo la detrazione dei punti, sia i punti che i buoni regalo sono irrevocabili.",
        "step7": "7) In un determinato arco di tempo, si possono usare i punti fedeltà per ottenere dei buoni regalo per un numero limitato di volte. Qualora si dovesse superare questo limite, il sistema invierà un avviso.",
        "step8": "8) Quando si utilizza un buono regalo in un ordine, il buon regalo non può essere restituito in caso del rimborso di tale ordine.",
      },
      "four": {
        "title": "4. Gestione della violazione delle regole di utilizzo dei buoni regalo",
        "step1": "Qualora l'utente utilizzi un buono sconto falsificato, acquistato, venduto o trasferito da un altro account, Jackery ha il diritto di bloccare o annullare detto buono regalo, annullare gli ordini in cui l'utente ha utilizzato dei buoni regalo, bloccare la possibilità dell'utente di richiederne di nuovi, interrompere la fornitura di servizi o eliminare l'account dell'utente. Jackery ha inoltre il diritto di non accettare eventuali nuove richieste di registrazione da parte dell'utente."
      }
    },
    "productRules": {
      "one": {
        "title": "1. Che cos'è un buono sconto sui prodotti Jackery",
        "step1": "1) Le offerte sui prodotti Jackery sono un tipo di buoni sconto che l'azienda distribuisce agli utenti attraverso attività promozionali, marketing mirato, raccolta di punti fedeltà e altre modalità per fidelizzare i clienti.",
        "step2": "2) Le offerte sui prodotti Jackery sono disponibili solo in formato digitale sotto forma di buoni sconto e si possono acquistare tramite una riduzione totale o parziale del costo dei i prodotti corrispondenti sul sito ufficiale di Jackery.",
        "step3": "3) Al momento dell'acquisto e del pagamento di un ordine, basta inserire il codice sconto del prodotto Jackery e lo sconto/importo relativo verrà automaticamente detratto dall'importo totale dell'ordine. I buoni sconto non possono essere convertiti in contanti.",
      },
      "two": {
        "title": "2. Come si controlla un buono sconto dei prodotti Jackery?",
        "step1": "1) È necessario registrarsi come membro Jackery e andare su Centro utente - Il mio portafoglio - Prodotto Jackery. In questo modo si potranno controllare le informazioni di tutti i buoni sconto sui prodotti Jackery, sia quelli da usare, sia quelli già utilizzati o scaduti.",
        "step2": "2) Bisogna prestare attenzione al periodo di validità del buono sconto sui prodotti Jackery. Si consiglia di utilizzarlo entro il periodo di validità, poiché Jackery non prevede rimborsi in caso di scadenza.",
      },
      "three": {
        "title": "3. Istruzioni per l'utilizzo dei buoni sconto sui prodotti Jackery:",
        "step1": "1) Ogni buono sconto sui prodotti Jackery ha una scadenza specifica: controlla le informazioni dettagliate prima dell'utilizzo.",
        "step2": "2) Siti interessati: I buoni sconto sui prodotti Jackery richiesti o riscattati su questo sito sono utilizzabili solo su questo sito e non possono essere utilizzati su altri siti.",
        "step3": "3) È possibile utilizzare un solo buono sconto per lo stesso ordine e non si può combinare con altre promozioni. Se un ordine comprende più articoli, il buono sconto verrà applicato solo agli articoli corrispondenti.",
        "step4": "4) Il buono sconto sui prodotti Jackery è associato a un account e può essere utilizzato solo a titolo personale. L'indirizzo e-mail indicato nella pagina di pagamento deve corrispondere all'indirizzo e-mail del destinatario del coupon e non può essere trasferito.",
        "step5": "5) I membri possono utilizzare i punti per ottenere buoni sconto sui prodotti Jackery e, dopo la detrazione dei punti, sia i punti che i buoni sconto sono irrevocabili. In un determinato arco di tempo, si possono usare i punti fedeltà per ottenere dei buoni regalo per un numero limitato di volte.",
        "step6": "6) Quando si utilizza un buono sconto in un ordine, il buono sconto non può essere restituito in caso del rimborso di tale ordine."
      },
      "four": {
        "title": "4. Condizioni per lo scambio di prodotti Jackery in regalo",
        "step1": "1) Accedi al sito ufficiale di Jackery e vai alla pagina dei dettagli del prodotto interessato, fai clic per acquistare",
        "step2": "2) Inserisci il relativo codice sconto nella pagina di pagamento (incolla il codice {XXX} al momento del pagamento)",
        "step3": "3) L'indirizzo e-mail sulla pagina di pagamento deve corrispondere a quella del titolare del codice sconto"
      },
      "five": {
        "title": "5. Gestione della violazione delle regole di utilizzo dei buoni sconto",
        "step1": "Qualora l'utente utilizzi un buono sconto falsificato, acquistato, venduto o trasferito da un altro account, Jackery ha il diritto di bloccare o annullare detto buono sconto, annullare gli ordini in cui l'utente ha utilizzato dei buoni sconto, bloccare la possibilità dell'utente di richiederne di nuovi, interrompere la fornitura di servizi o eliminare l'account dell'utente. Jackery ha inoltre il diritto di non accettare eventuali nuove richieste di registrazione da parte dell'utente."
      }
    },
    "extraRules": {
      "one": {
        "title": "1. Cosa sono gli extra di Jackery?",
        "step1": "1) Gli extra di Jackery sono dei regali di altre aziende, come ad esempio coupon, buoni sconto, buoni regalo e regali materiali.",
        "step2": "2) Jackery li distribuisce agli utenti attraverso attività promozionali, marketing mirato, raccolta di punti fedeltà e altre modalità per fidelizzare i clienti. Dopo aver riscattato i punti per ottenere gli extra di Jackery, i punti verranno detratti e sia i punti che i regali non potranno essere revocati.",
      },
      "two": {
        "title": "2. Come si controllano gli extra di Jackery?",
        "step1": "1) Registrati come membro Jackery e vai su Centro utente - Il mio portafoglio - Extra.",
        "step2": "2) Riscatta le tue offerte prima della scadenza. Una volta scaduta, l'offerta non può essere riutilizzata.",
        "step3": "3) I dettagli dell'offerta saranno inviati tramite e-mail. Potrai visualizzare le relative informazioni nella casella di posta elettronica."
      },
      "three": {
        "title": "3. Riscattare il regalo extra di Jackery",
        "step1": {
          "title": "1) Regole per la richiesta di un regalo materiale:",
          "tip1": "① I regali fisici al di fuori di Jackery devono essere indicati in modo chiaro.",
          "tip2": "② L'articolo verrà spedito entro 30-90 giorni dalla richiesta.",
          "tip3": "③ I dettagli della spedizione, compreso il numero di tracciamento, saranno inviati via e-mail una volta spedito l'articolo.",
          "tip4": "④ Se i premi materiali ricevuti sono danneggiati o presentano altri problemi, non esitare a contattare il nostro servizio clienti.",
        },
        "step2": {
          "title": "2) Considerazioni sui buoni regalo virtuali di aziende esterne a Jackery",
          "tip1": "① Tutti i dettagli sui buoni regalo virtuali di altre aziende saranno inviati via e-mail.",
          "tip2": "② I buoni regalo virtuali di aziende esterne a Jackery hanno una validità di XX anni e vengono attivati al momento della ricezione. Dopo l'attivazione, la scadenza del buono regalo sarà determinata in base alla validità specificata dalla relativa azienda. Per ulteriori informazioni, consulta il sito web dell'azienda corrispondente.",
          "tip3": "③ Le regole di utilizzo specifiche per i buoni regalo virtuali di aziende esterne a Jackery sono riportate nell'introduzione alle regole della piattaforma per il riscatto dei buoni regalo.",
        }
      },
      "four": "4. In caso di controversia, Jackery si riserva il diritto di adottare provvedimenti definitivi."
    }
  },
  // My Service
  "service": {
    'imgText1':  `Benvenuto nell'Assistenza`,
    'imgText2': 'Jackery',
    'pcImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_f2d45c3c-6199-4b74-85d8-bc1977a5d9b9.png?v=1683191312',
    'mbImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_efc7e4e4-a728-40e4-ab7b-31aab55839af.png?v=1683191345',
    'text': `Vai all'assistenza Jackery per un servizio intelligente.`,
    'contactUs': 'Contattaci',
    'viewSupport': 'Vai al supporto',
  },
  // My Jackery Life
  "jackeryLife": {
    'title': 'Jackery Life',
    'imgText1': 'Jackery Life',
    'imgText2': 'Viviendo con Jackery Solar',
    'pcImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/2_2bf016ce-d9a0-4e4b-a163-e4272aba015d.png?v=1683189189',
    'mbImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/Jackery_life_8ab97bcf-fd91-4e1d-a1d4-8c378f824fdb.png?v=1683189235',
    'text': 'Condividi le tue storie con Jackery su Jackery Life e avrai la possibilità di vincere fantastici premi!',
    'learnMore': 'Scopri di più',
  },
  "wishlist": {
    "empty": "Nessun prodotto nella tua lista dei desideri",
    "shopNow": "COMPRA ORA",
    "modalTitle": "Sei sicuro di voler rimuovere questo prodotto dalla tua lista dei desideri? ",
    "modalCancel": "Annulla",
    "modalOk": "Conferma",
    "removeToast": "Il prodotto è stato rimosso dalla tua lista dei desideri.",
    "createdDesc": "Aggiunta più recente",
    "createdAsc": "Aggiunta più vecchia",
    "priceDesc": "Prezzo dal più alto al più basso",
    "priceAsc": "Prezzo dal più basso al più alto",
    "purchased": "ACQUISTATO",
    "buyNow": "ACQUISTA ORA",
    "selectAll": "Seleziona tutto",
    "selected": "Selezionato",
    "amount": "Importo totale",
    "addOn": "Aggiunto il",
    "notGoodsGotoBuy": 'Seleziona un articolo',
    "viewMoreHref": "https://it.jackery.com/"
  },
  "old-invite-new": {
    "myRewardsTitle": "My Rewards",
    "myRewardsSubTitle": "Manage and use rewards that have been claimed here. For expired and used rewards, please check them in My Wallet.",
    "myRewardsNoData": "Invite your friends to subscribe or purchase, and claim your rewards on the dashboard below.",
    "viewMore": "View More",
    "myReferralsTitle": "My Referrals",
    "myReferralsSubTitle": "Track the referral progress and claim the rewards. Click the reward for details.",
    "myReferralsNoData": "Invite your friends to subscribe or make a purchase, then claim your rewards here.",
    "claim": "Claim",
    "claimed": "Claimed",
    "expired": "Expired",

    // 补充
    "myReferral": "My Referral",
    "enterEmail": "Enter Your Email Address",
    "invalidEmail": "Invalid Email Address",
    "messageSent": "Message Sent",
    "copy": "COPY",
    "copied": "COPIED",
    "copiedSuccess": "COPIED SUCCESS!",
    "friendEmail": "Enter Your Friend's Email",
    "referee": "Referee",
    "referralType": "Referral Type",
    "rewards": "Rewards",
    "status": "Status",
    "instagramCopySuccessTip": "Referral Link has copied, paste and share to Instagram",
  }
}
