export default {
  "login": {
    "sign": "Se connecter",
    "login": "Se connecter immédiatement",
    "remember": "Se Souvenir de Moi",
    "about": "À Propos de Nous",
    "illegal_email": "Format d'email non valide",
    "illegal_password": "Le mot de passe doit comporter 6 à 16 caractères, et comprendre des chiffres et des lettres.",
    "password_matching": "Méthode de saisie incohérente avant et après le mot de passe.",
    "enterEmail": "Adresse e-mail/identifiant Jackery",
    "enter_code": "Veuillez entrer Code de vérification",
    "forgot": "Vous avez oublié votre mot de passe ?",
    "create": "Créer un compte",
    "thirdLogin": "Ou connectez-vous avec",
    "accountActivate": "Compte activé",
    "bindEmail": "Lier votre e-mail",
    "error": "La connexion a échoué, veuillez réessayer plus tard",
    "quickLogin": "Connexion sans mot de passe",
    "quickLoginA": "Connexion sans mot de passe"
  },
  "policies": {
    "content": "Si vous vous connectez avec une tierce partie, cela signifie que vous avez lu et accepté notre",
    "policy-link": "https://fr.jackery.com/policies/privacy-policy",
    "policy": "Politique de confidentialité",
    "and": "et",
    "terms-link": "https://fr.jackery.com/policies/terms-of-service",
    "terms": "Conditions d'utilisation",
    "comma": ".",
    "footer": "Jackery utilisera les informations fournies par le tiers avec lequel vous avez choisi de vous enregistrer ou de vous connecter."
  },
  "reset": {
    "modify": "Changez votre mot de passe",
    "back": "Retour à la connexion ",
    "success": "Nouveau mot de passe créé avec succès!",
    "referrer": "Retour à la page d'origine en",
    "seconds": "secondes"
  },
  "register": {
    "title": "Enregistrer",
    "enterEmail": "Veuillez entrer Adresse email",
    "agree": "Je déclare avoir lu et accepté la",
    "agree_after": "d'Obey Jackery.",
    "getDeals": "J'accepte de recevoir les dernières offres, promotions et informations relatives aux produits.",
    "login": "Connexion rapide",
    "success": "Votre compte '{email}' a été enregistré avec succès!",
    "welcomeEmail": "Un message de bienvenue a été envoyé à votre adresse e-mail."
  },
  "header": {
    "logout": 'Se déconnecter',
    "userCenter": "Centre de compte",
    "personalCenter": "Mon compte",
    "shopping": "Aller à la boutique",
    "setting": "Paramètres du compte",
    "myJackery": "Centre de compte",
    "jackeryID": "Mon compte",
    "wishlist": "Ma liste d'envies",
  },
  "footer": {
    "cookieContent": "Nous utilisons des cookies, y compris des cookies tiers, pour assurer le bon fonctionnement du site web, analyser le trafic, fournir une expérience améliorée des médias sociaux et personnaliser le contenu et les annonces.",
    "cookieUrl": "#",
    "learn": "En savoir plus",
    "accept": "Accepter les cookies",
    "copyright": "Copyright © {date} Jackery Technology GmbH All Rights Reserved.",
    "sitemap-link": "#",
    "sitemap": "Plan du site"
  },
  "account": {
    "welcome": "Bienvenue",
    "email": "Adresse email",
    "registerEmail": "Enregistrer l'email",
    "bindNewRegisterEmail": "Nouvelle adresse e-mail",
    "emailIsEqual": "La nouvelle adresse e-mail est la même que celle d'origine, pas besoin de la modifier.",
    "settings": "Paramètres du compte",
    "settingTips": "Gérez rapidement les informations de votre compte et les détails de sécurité.",
    "titleProfiles": "Mon profil",
    "titleSecurity": "Sécurité du compte",
    "titleAuthorization": "Lier votre compte",
    "nickname": "Pseudo",
    "name": "Nom",
    "gender": "Genre",
    "birthday": "Date de naissance",
    "contactEmail": "Adresse email",
    "contactPhone": "Numéro de contact",
    "enterprise": "Compte professionnel",
    "setted": "Définir",
    "unset": "Désactivé",
    "password": "Mot de passe",
    "pop_password": "Créer un mot de passe",
    "apply": "Appliquer",
    "secrecy": "secret",
    "male": "Homme",
    "female": "Féminin",
    "currentPassword": "Mot de passe actuel",
    "newPassword": "Nouveau mot de passe",
    "newShowAgain": "Ne plus afficher",
    "againPassword": "Confirmer le nouveau mot de passe",
    "jackeryIdTips": "L'identifiant Jackery ne peut être modifié qu'une seule fois, L'identifiant Jackery ne peut être modifié qu'une seule fois. L'identifiant Jackery doit contenir au moins 6 caractères, et peut inclure des chiffres et des lettres, mais ne peut pas dépasser 32 caractères.",
    "birthdayTips": "Veuillez saisir votre date de naissance afin que nous puissions vous offrir un service de la plus haute qualité. Nous garderons toutes vos informations personnelles strictement confidentielles.",
    "nickNameTips": "Le surnom peut être composé de toutes les lettres ou chiffres, et ne peut pas dépasser 32 caractères.",
    "nameTips": "Nous vous conseillons vivement d'utiliser votre vrai nom pour que nous puissions vous contacter. Toutes les informations personnelles resteront strictement confidentielles.",
    "genderTips": "Veuillez saisir votre vrai nom afin que nous puissions vous fournir un service de qualité. Nous garderons toutes vos informations personnelles strictement confidentielles.",
    "contactEmailTips": "Nous vous invitons à utiliser votre adresse e-mail préférée, afin que nous puissions vous contacter facilement. Nous garderons vos informations personnelles strictement confidentielles.",
    "phoneNumberTips": "Veuillez saisir votre numéro de téléphone le plus fréquemment utilisé, afin que nous puissions vous contacter facilement. Nous garderons vos informations personnelles strictement confidentielles. ",
    "phoneNumberTips2": "Seuls les chiffres, les parenthèses et les traits d'union sont pris en charge",
    "enterpriseNameTips": "Si vous utilisez un compte professionnel, veuillez nous communiquer les informations relatives à votre entreprise, afin que nous puissions vous fournir un service de la plus haute qualité.",
    "emailTips": "L'adresse e-mail avec laquelle vous vous êtes inscrit est votre compte de connexion. Pour sécuriser votre compte utilisateur, une vérification de sécurité est nécessaire pour modifier l'adresse e-mail enregistrée.",
    "passwordTips": "Pour des raisons de sécurité, veuillez définir un mot de passe plus fort.",
    "location": "Localisation",
    "contactPhone": "Téléphone",
    "avatarSettings": "Paramètres d'avatar",
    "choosePicture": "Sélectionner l'image",
    "myProfile": "Mon profil",
    "changePassword": "Modifier le mot de passe",
    "creatPassword": "Créer un mot de passe",
    "firstName": "Prénom",
    "lastName": "Nom de famille",
    "editPasswordTips": "Votre mot de passe doit être composé de 6 à 16 chiffres et doit comprendre des lettres et des chiffres. Pour des raisons de sécurité, veuillez utiliser un mot de passe fort.",
    "subscriberTip": "Obtenir {num} points pour la première inscription par e-mail et recevoir les dernières nouvelles et offres de Jackery. J'accepte les Conditions d'utilisation et la Politique de confidentialité de Jackery.",
    "birthdayTip1": "Gagnez {num} points en remplissant votre date d'anniversaire.",
    "birthdayTip2": "Gagnez { num } points à chaque anniversaire et doublez vos points shopping.",
    "birthdayTip3": "Une seule chance de modifier.",
    "phoneTip": "Obtenir {num} points pour le premier abonnement SMS et recevoir les dernières nouvelles et offres de Jackery. J'accepte les Conditions d'utilisation et la Politique de confidentialité de Jackery.",
    "countryRegion": "Pays|Région",
    "successTip1": "Vous avez soumis votre profil avec succès, {num} points Jackery vous seront attribués dans les 24 heures.",
    "successTip": "Vous avez soumis votre profil avec succès.",
    "errorTip": "La soumission a échoué, veuillez vérifier et soumettre à nouveau."
  },
  "router": {
    "memberIndex": "MemberShip",
    "memberGuide": "MemberShip Guide",
    "home": "Accueil",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "Enregistrer",
    "login": "Se connecter",
    "reset": "Réinitialiser",
    "dashboard": "Dashboard",
    "commonSetting": "Paramètres généraux",
    "account": "Paramètres du compte",
    "accountOverview": "Vue d'ensemble",
    "myProfile": "Mon profil",
    "myOrders": "Ma commande",
    "personalSettings": "Paramètres personnels",
    "addressBook": "Mon adresse",
    "accountSettings": "Paramètres du compte",
    "myDevices": "Mon appareil",
    "activate": "Actif",
    "myWallet": "Mon portefeuille",
    "myService": "Mon service",
    "myReturn": "Mes retours",
    "myJackeryLife": "Jackery Life",
    "myWishlist": "Ma liste d'envies",
    "myReferral": "Mes recommandations",
  },
  "description": {
    "memberIndex": "Jackery",
    "memberGuide": "Jackery",
    "home": "Jackery",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "Créez un identifiant Jackery, pour devenir un membre de Jackery.",
    "login": "Se connecter en tant que client",
    "reset": "Réinitialiser",
    "dashboard": "Dashboard",
    "commonSetting": "Paramètres généraux",
    "account": "Centre de compte identifiant Jackery",
    "accountOverview": "Vue d'ensemble",
    "myProfile": "Mon profil",
    "myOrders": "Ma commande",
    "personalSettings": "Paramètres personnels",
    "addressBook": "Mon adresse",
    "accountSettings": "Paramètres du compte",
    "myDevices": "Mon appareil",
    "activate": "Créez votre mot de passe pour activer votre compte",
    "registerActivate": "Veuillez cliquer ci-dessous pour activer votre compte. Une fois activé, vous pouvez utiliser le compte pour vous connecter à notre site web.",
    "myReturn": "Mes retours",
    "myWallet": "Mon portefeuille",
    "myService": "Mon service",
    "myJackeryLife": "Jackery Life",
  },
  "operate": {
    "enable": "Activer",
    "disable": "Désactivé",
    "confirm": "Confirmer",
    "cancel": "Annuler",
    "remove": "Supprimer",
    "search": "Recherche",
    "reset": "Réinitialiser",
    "add": "Ajouter",
    "close": "Fermer",
    "edit": "Modifier",
    "yes": "Oui",
    "no": "Non",
    "submit": "Soumettre",
    "next": "Suivant",
    "prev": "Préc",
    "back": "Retour"
  },
  "tips": {
    "sure": "Êtes-vous certain ?",
    "placeholder": "Veuillez entrer",
    "enterConsultContent": "Veuillez entrer le contenu de votre demande",
    "all": "Tous",
    "custom": "Personnalisé",
    "pending": "En attente",
    "loading": "Traitement...",
    "loading2": "Traitement",
    "cancelled": "Annulé",
    "loaded": "Processus terminé",
    "loaded2": "Terminé",
    "changeShop": "Changement de magasin",
    "logoutConfirm": "Êtes-vous sûr de vouloir vous déconnecter ?",
    "loggedOut": "Déconnexion réussie",
    "refreshed": "Rafraîchissement du site",
    "noDescribe": "Pas de description",
    "reminder": "Un rappel chaleureux",
    "commError": "un problème est survenu",
    "reLogin": "Veuillez vous reconnecter",
    "verify": "Code de vérification", "sendVerify": "Un code de vérification a été envoyé à votre adresse e-mail - vérifiez votre boîte e-mail ou vos courriels indésirables.",
    "getVerifyCode": "Recevoir un code de vérification",
    "verifyError": "Code de vérification incorrect",
    "homePage": "Page d'accueil",
    "404": "Cette page n'existe pas.",
    "404Tips": "Veuillez vérifier si l'adresse IP du serveur est correcte, ou cliquez pour continuer à visiter le lien.",
    "500": "Oups, un problème est survenu.",
    "500Tips": "Erreur de serveur,,",
    "500Tips2": "veuillez réessayer plus tard.",
    "urlExpired": "L'URL de vérification a expiré",
    "accountNotActivated": "Le compte n'a pas été activé",
    "accountActivatedSuccess": "Votre compte a été activé avec succès.",
    "requestFrequently": "Veuillez ne pas agir fréquemment, réessayez une fois vérifié.",
    "submitSuccess": "Soumettez-le avec succès.",
    "zipVerifyError": "La vérification du code postal a échoué",
    "otherError": "Il y a un problème, veuillez contacter l'administrateur. code: {code}",
    "wrongPasswordMultipleTimes": "Vous avez saisi trop de mots de passe erronés. Veuillez réessayer {minute} minutes plus tard.",
    "networkError": "Erreur de Réseau",
    "thirdLoginError": "La connexion a échoué, veuillez réessayer avec votre compte.",
    "fileTypeError": "Erreur de type de fichier, seul le téléchargement est autorisé : {type} type de fichier",
    "copySuccessfully": "copié"
  },
  "placeholder": {
    "email": "Adresse email *",
    "emailOrJackeryID": "Adresse email/Jackery ID *",
    "password": "Mot de passe *",
    "passwordAgain": "Confirmez le nouveau mot de passe *",
    "enterprise": "Compte professionnel",
    "code": "Code de vérification *",
    "firstName": "Prénom",
    "lastName": "Nom de Famille",
    "select": "Veuillez sélectionner",
    "address1": "Rue et numéro de téléphone",
    "address2": "Appartement, suite, etc. (facultatif)",
    "country": "Pays/Région",
    "province": "État/Province/Région",
    "city": "Ville ",
    "region": "Région",
    "zip": "Code postal",
    "zipError": "Code postal incorrect",
    "phone": "Votre téléphone ",
    "company": "Nom de l'entreprise (facultatif)",
    "isNotEmpty": "Cette option ne peut être vide",
    "pobox": "Nous ne faisons pas de livraison à des boîtes postales",
    "minChar2": "2 caractères au minimum",
    "inputChar": "le nombre de caractères est limité à {length} chiffres",
    "inputChar1": "Le nombre de caractères est limité à {length} chiffres",
  },
  "order": {
    "sn": "Numéro de commande",
    "detail": "Afficher les détails de l'expédition",
    "productName": "Nom du produit",
    "quantity": "Quantité",
    "price": "Prix ",
    "status": "Statut de la commande",
    "consult": "Consulter",
    "contact": "Nous contacter",
    "total": "Au total",
    "empty": "Aucune commande trouvée actuellement",
    "questionType": "Type de problème",
    "shipped": "Expédié",
    "return": "Retourner",
    "applied": "Retourné et remboursé",
    "revoke": "Révoquer",
    "serviceType": "Veuillez sélectionner le type de service",
    "serviceType_1": "Retour et Remboursement",
    "serviceType_2": "Échanges et remplacements",
    "packageArrived": "Avez-vous reçu les articles",
    "reason": "Veuillez indiquer une raison",
    "reason_1": "Retour Sans Questions Pendant {day} Jours",
    "reason_2": "Problèmes de qualité",
    "reason_3": "Autres",
    "quality": "Veuillez indiquer le problème de qualité spécifique",
    "productSerialNumber": "Veuillez indiquer le numéro de série du produit",
    "selectReturnProduct": "Veuillez sélectionner un produit à retourner",
    "returnQuantity": "Veuillez indiquer le nombre de retours/remplacements",
    "shippingAddress": "Adresse d'expédition",
    "returnAddress": "Adresse de retour",
    "revise": "Modifier",
    "uploadVideo": "Télécharger une vidéo/image",
    "uploadLoading": "Téléchargement",
    "videoLimit": "La taille du fichier ne peut pas dépasser les {limit}M!",
    "uploadVideoError": "Echec du téléchargement du fichier",
    "failureDetermination": "Dépannage de la qualité du produit",
    "steps_1": "1. Étapes de dépannage de la station d'alimentation.",
    "steps_1_content": "1) Appuyez sur l'écran pour voir si le témoin lumineux est allumé.\n2) Veuillez réinitialiser la station d'alimentation et la placer quelque part pendant un certain temps avant de recharger l'appareil.\n---Maintenez la pression sur le bouton « AFFICHER » pendant 10 secondes si la station d'alimentation est une E160/E240/E500/E1000.\n---Maintenez la pression sur le bouton « AFFICHER+USB » pendant 10 secondes si la station d'alimentation est E1500/E2000.\n3) Appuyez sur le bouton situé sur le dessus de la prise CA/CC pour vérifier si la lumière est allumée avant de brancher votre appareil et de le faire charger.\n4) Vérifiez si l'appareil peut être rechargé normalement.\n\nLa station d'alimentation est défectueuse si les étapes ci-dessus ne fonctionnent pas.\n\n",
    "steps_2": "2. Étapes de dépannage du panneau solaire",
    "steps_2_content": "1) Veuillez utiliser le chargeur CA fourni pour charger la station d'alimentation et voir si ça peut fonctionner.\n2) Veuillez effectuer le test sous un fort ensoleillement en milieu de journée. Connectez le panneau solaire à la station d'alimentation, assurez-vous qu'il n'y a pas d'ombre projetée sur le panneau.\n\nLa station d'alimentation est défectueuse si elle peut être rechargée par le chargeur CA mais ne fonctionne pas avec le panneau solaire.",
    "learnMore": "En savoir plus",
    "shrink": "Rétrécissement",
    "noReason_30_day": "1. Jackery propose une garantie de remboursement de 30 jours pour les produits achetés directement sur Jackery.com. Nous sommes convaincus que vous allez adorer votre nouveau produit Jackery. Toutefois, si vous n'êtes pas satisfait pour une raison quelconque, il vous suffit de demander un retour pour un remplacement ou un remboursement dans un délai de 30 jours.\n\n2. Remarque : les retours sans motif dans un délai supérieur à 30 jours ne seront pas acceptés.\n\n3. Pour que votre produit soit retourné dans le cadre de la politique de retour de 30 jours, veuillez vérifier que : Assurez-vous que le produit est retourné dans l'emballage d'origine, avec tous les matériaux inclus, dans un état neuf ou comme neuf.",
    "selectDelivery": "Veuillez sélectionner les modalités de retour",
    "enterTrackNumber": "Veuillez saisir le numéro de suivi",
    "illegal_trackNumber": "Veuillez saisir le bon numéro de suivi",
    "enterDescription": "Veuillez saisir une description spécifique",
    "comingSoon": 'À venir',
    "cancelled": 'Annulé',
    "points": "points",
    "comingSoonTip": '（Jackery Points will be issued on the 31st day after the product is shipped）',
    "pointsBalance": "Solde de points",
    "tipTitle": 'Avantages pour les membres',
    "shopNow": "Acheter maintenant",
    "tip1": `① <a> En passant commande </a> sur le site officiel de Jackery, vous bénéficierez automatiquement d'une extension de garantie (jusqu'à 5 ans) et obtiendrez des Jackery Points (1 euro dépensé = 1 point gagné).`,
    "tip2": `② <a> Si vous enregistrez les produits </a> que vous avez achetés sur des canaux non officiels, vous prolongerez votre garantie (jusqu'à 5 ans).`,
    "tip3": `③ <a> Si vous passez une commande de plus de {num} </a> sur le site officiel de Jackery après l'enregistrement, vous pouvez obtenir un cadeau gratuit pour une fois.`
  },
  "bank": {
    "name": "Nom de la banque",
    "branchName": "nom de la branche",
    "type": "Type de carte bancaire",
    "account": "Numéro de la carte bancaire",
    "realName": "Nom du titulaire de la carte"
  },
  "return": {
    "orderNumber": "Numéro de commande",
    "status": "Statut de retour/remplacement",
    "attachment": "Pièce jointe",
    "confirmRevoke": "Voulez-vous annuler la demande de retour/remplacement?",
    "returnPolicy_link": "https://fr.jackery.com/pages/policy",
    "returnPolicy_1": "Pour les FAQs relatives à l'application de la réparation, des retours, des remplacements et des échanges, veuillez vous référer à la",
    "returnPolicy_2": "Politique après-vente.",
    "returnPolicy_3": "Si vous avez des questions, veuillez contacter",
    "returnPolicy_4": "support{'@'}jackery.com",
    "returnPolicy_5": ""
  },
  "devices": {
    "empty": "Aucune information sur l'appareil",
    "more": "Plus",
    "extendedWarrantyDeadline": "Date de fin de garantie",
    "warrantyStatus": "Vérifiez votre statut (extension de garantie)",
    "existWarranty": "Garantie prolongée",
    "nonExistentWarranty": "Garantie non prolongée",
    "userManual": "Manuel de l'utilisateur",
    "warrantyInfo": "Cliquez sur 'Soumettre' pour activer votre extension de garantie.",
    "newsletter": "S'abonner à notre bulletin d'information",
    "warrantySuccess": "Garantie prolongée",
    "download": "Télécharger",
    "none": "Aucun",
    "addNewDevice": "Ajouter un nouvel appareil",
    "shopNow": "Acheter maintenant"
  },
  "address": {
    "empty": "Aucune adresse enregistrée ~",
    "add": "Ajouter une nouvelle adresse",
    "edit": "Modifier l'adresse",
    "userName": "Nom d'utilisateur",
    "address1": "Adresse 1",
    "address2": "Adresse 2",
    "country": "Pays/Région",
    "country_code": "Code pays/code postal",
    "province": "État/Province/Région",
    "province_code": "État/Province/Code postal",
    "city": "Ville",
    "zip": "Code postal",
    "phone": "Numéro de téléphone",
    "company": "Société",
    "default": "Définir l'adresse de livraison par défaut",
    "isDefault": "Adresse par défaut",
    "error": "L'adresse de livraison est erronée ou les coordonnées sont incomplètes, veuillez vérifier"
  },
  "character": {
    "comma": ","
  },
  // Following is server language pack
  "msg": {
    "success": "Effectué avec succès"
  },
  "user": {
    "log": {
      "expire": "Veuillez vous reconnecter"
    }
  },
  "message": {
    "order_status_shipping": "Expédié",
    "order_status_completed": "Terminé",
    "order_status_processing": "Traitement",
    "order_status_archived": "Expédié",
    "order_status_canceled": "Annulé",
    "order_status_unpaid": "Non payé",
    "device_not_found": "L'appareil n'existe pas",
    "device_disable_can_not_set_warranty": "L'appareil actuel n'est pas valide et l'extension de garantie ne peut pas être définie",
    "device_was_warranty": "L'appareil actuel a été étendu.",
    "device_not_support_warranty": "L'appareil actuel ne prend pas en charge la garantie prolongée"
  },
  "create": {
    "address": {
      "fail": "Échec de l'ajout de l'adresse"
    }
  },
  "activation": {
    "email": {
      "expire": "Le message a expiré"
    }
  },
  "select_country": {
    "title": "Sélectionnez Votre Pays/Région"
  },



  // 会员首页 多语言
  "member_index" : {
    "common": {
      "login": "Connexion",
      "register": "Inscrivez-vous",
      "already_a_member": "Êtes-vous déjà membre ?",
      "points": "Points",
      "gift_card": "gift card",
      "redeem": "Utiliser",
      "rules": "Règles",
      "copy_successfully": "copié",
      "outOfStock": "En rupture de stock",
      "quick_login": "Se connecter/S'inscrire"
    },
    // Banner 标题
    "banner": {
      "title": `Adhésion<br/>Jackery`,
      "description": `Gagnez des points Jackery, bénéficiez d’avantages exclusifs, faites quelque chose de significatif avec Jackery`,
      "points_balance": "Solde de points",
      "coming_soon": "Bientôt disponible",
      "my_rewards": "Mes récompenses",
      "my_activity": "Mon activité",
      "unused": "Inutilisé",
      "to_be_redeemed": "Convertible",
      "no_unused_rewards": "Pas de récompenses disponibles",
      "no_convertible_rewards": "Aucun avantage convertible",
      "no_activity_records": "Aucun enregistrement sur les activités de points actuellement",
      "time": "Temps",
      "action": "Action",
      "points": "Points",
    },
    "section_tabs": {
      "membership_benefits": "Avantages de l’adhésion",
      "redeem_points": "Échanger des points",
      "earn_points": "Gagner des points",
      "guide": "Consignes",
      "faq": "FAQ",
    },
    // Benefits
    "membership_benefits": {
      "title": "Avantages exclusifs aux membres Jackery",
      "banner_text": "Gagnez des points Jackery avec vos achats et échangez des récompenses exclusives",
      "coupon_benefit": "Obtenez {num} de rabais lors de votre première inscription",
      "giftcard_benefit": "Cadeau gratuit pour votre commande de plus de {num} pour une fois",
      "product_benefit": "Recevez régulièrement des rappels d’entretien des produits",
      "gift_benefit": "Obtenez 15% de réduction une fois la garantie expirée"
    },
    // Redeem Points
    "redeem_points": {
      "title": "Obtenez des récompenses en<br/>échangeant des points Jackery",
      "unusedTips": "Utilisez-le(s) avant la date d'expiration !",
      "pointTips": "Vous avez {0} points Jackery.",
      "category_tabs": {
        "coupon": "Coupon",
        "gift_card": "Carte-cadeau",
        "jackery_products": "Produit Jackery",
        "virtualCard": "Extras",
      },
      "code": "Code",
      "redeem_confirm_title": "{0} points seront utilisés. Ils ne sont pas remboursables une fois traités.",
      "redeem_confirm_subtitle": "",
      "redeem_confirm_content": "Êtes-vous sûr de vouloir procéder?",
      "successfully_redeemed": "Remboursé avec succès",
      "information_tips": "Les informations concernant la carte-cadeau vous ont été envoyées par e-mail, veuillez vérifier votre boîte de réception.",
      "copy_and_use": "Copier et utiliser",
      "day": "J",
      "hour": "H",
      "minute": "Min",
      "seconds": "S",
      "count_down_info": "L'événement est terminé et la récompense ne peut pas être échangée. Veuillez rafraîchir la page."

    },
    // 获取积分方式
    "earn_points": {
      "title": "Comment gagner des points",
      "go_now": "Allez-y",
      "points_earned": "Points gagnés",
      "membership_registration": "Inscription des membres",
      "membership_registration_desc": "{0} points Jackery lorsque vous vous inscrivez.",
      "email_subscribtion": "Abonnement par e-mail",
      "email_subscribtion_desc": "{0} points Jackery en vous abonnant à Jackery par Email.",
      "sms_subscribtion": "Abonnement SMS",
      "sms_subscribtion_desc": "{0} points Jackery en vous abonnant à Jackery par SMS.",
      "fill_in_your_birthday": "Inscrivez votre anniversaire",
      "fill_in_your_birthday_desc": `
        Obtenez {0} Points en indiquant votre date de naissance.<br/>
        Gagnez {1} points à chaque anniversaire et obtenez le double de points d’achat.
      `,
      "extend_your_warranty": "Prolongez votre garantie",
      "extend_your_warranty_desc": `
        Obtenez {0} Points en enregistrant des produits sur le site officiel de Jackery à chaque fois.
        <br/>
        (Pour les clients ayant acheté via des canaux tiers.)
      `,
      "shop_and_earn": "Achetez et gagnez",
      "shop_and_earn_desc": `
        Faites du shopping, 1 euro dépensé = 1 point gagné.<br/>
        {0} points gagnés pour les {1} commandes.<br/>
        {2} points pour les commandes totalisant plus de {3}.
      `,
      "tip": "Les abonnements multi-sites sont considérés uniquement comme un seul point d'abonnement."
    },
    "guide": {
      "title": "Comment ça marche",
      "step_title_1": "1. S’inscrire",
      "step_content_1": "Créez un compte Jackery et profitez des avantages exclusifs de l’adhésion.",
      "step_title_2": "2. Gagner des points",
      "step_content_2": `Effectuez des tâches pour gagner des points (telles que remplir des informations personnelles \/ acheter des produits \/ participer à des événements, etc.)`,
      "step_title_3": "3. Obtenir des récompenses",
      "step_content_3": `Échangez des offres exclusives (telles que des coupons, des cartes-cadeaux, etc.) avec vos points Jackery.`,
      "learn_more": "En savoir plus",
    },
    // 注册 指引页
    "register_guide": {
      "title": "Découvrez comment obtenir <br/>vos Récompenses Membre de Jackery?",
      "step_1_title": "Étape 1 - Inscrivez-vous et connectez-vous",
      "step_1_subtitle_1": "[S'inscrire]",
      "step_1_content_1": `① Rendez-vous sur la page d'inscription ou appuyez sur « Créer un compte » disponible sur le site <a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=FR" target="_blank">account.jackery.com</a>`,
      "step_1_content_2": "② Renseignez votre adresse de courriel et votre mot de passe.",
      "step_1_content_3": "③ Confirmez votre nouveau mot de passe et introduisez votre code de vérification obtenu sur votre courriel.",
      "step_1_content_4": `④ Cochez « J'accepte la politique de confidentialité et les conditions de service de Jackery » et « J'accepte de recevoir les dernières nouvelles, offres, promotions et informations relatives aux produits de Jackery ». Veuillez lire la politique de confidentialité et les conditions d'utilisation de Jackery en vue de protéger vos droits.`,
      "step_1_content_5": `⑤ Cliquez sur « Envoyer » pour terminer votre inscription.<br/>(Il convient de passer cette étape ou de se connecter si vous êtes déjà inscrit.)`,
      "step_1_subtitle_2": "[Se connecter]",
      "step_1_content_6": `① Il existe 3 façons pour se connecter:`,
      "step_1_content_7": `<a class="text-primary hover:text-primary" href="https://account.jackery.com/login?CR=FR" target="_blank">Accédez à la page</a> de connexion`,
      "step_1_content_8": `Appuyez sur « Se connecter maintenant » disponible sur le site <a class="text-primary hover:text-primary" href="https://account.jackery.com/register?CR=FR" target="_blank">account.jackery.com</a>`,
      "step_1_content_9": `Cliquez sur l'icône « Compte » figurant dans le coin supérieur droit de la page d'accueil.`,
      "step_1_content_10": `② Renseignez votre adresse de courriel et votre mot de passe. Il vous est également possible de vous connecter à l'aide de vos comptes Google ou Amazon.`,
      "step_1_content_11": `③ Cliquez sur « SE CONNECTER » pour accéder à votre espace client.`,
      "step_1_img_1_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/1_ad1a2aed-acbf-48b4-949c-fb3bfd280b95.png?v=1690167664",
      "step_1_img_2_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/2_73356a02-7f66-4060-9079-e5a3e356c259.png?v=1690167663",
      "step_1_img_3_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/4_4fade96a-11fe-460a-9bbb-ad29240fda97.png?v=1690167663",
      "step_1_img_4_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/3_a95b414a-ea4c-4c6c-a75f-8ef687881c60.png?v=1690167663",
      "step_1_img_5_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/5_b0d898de-0784-44c8-8de8-b5808f04287e.png?v=1690167664",
      "step_1_img_6_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/6.1.png?v=1690167663",
      "step_1_img_1_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/1_41ce4616-35aa-4cde-a90a-7e45b68a6153.png?v=1690167939",
      "step_1_img_2_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/2_f620aa5f-541c-4112-82ba-490a3dd93e65.png?v=1690167939",
      "step_1_img_3_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/3.1.png?v=1690167939",
      "step_1_img_4_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/3.2.png?v=1690167939",
      "step_1_img_5_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/3.3.png?v=1690167942",
      "step_1_img_6_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/3.4.png?v=1690167938",

      "step_2_title": "Étape 2 – Gagnez des points Jackery",
      "step_2_content_1": `<a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=FR" target="_blank">Sur la page d'adhésion</a>, consultez la rubrique « Moyens de gagner des points » et apprenez comment gagner des points Jackery.`,
      "step_2_img_1_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/6.2.png?v=1690167660",
      "step_2_img_1_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/3.5.png?v=1690167939",

      "step_3_title": "Étape 3 - Consultation des points Jackery",
      "step_3_content_1": `Une fois connecté à votre compte, il vous est possible de consulter votre solde de points via <a class="text-primary hover:text-primary" href="https://account.jackery.com/account/overview?CR=FR" target="_blank">votre espace client</a> ou votre <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=FR" target="_blank">page de membre</a>.`,
      "step_3_content_2": `① Espace client : Vous disposez de trois options pour vérifier vos points : « Aperçu », « Mon profil » et « Mon portefeuille ».`,
      "step_3_content_3": `② Si vous cliquez sur « Solde de points », vous accédez à la <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=FR" target="_blank">page des membres</a>, où vous pouvez également consulter votre solde de points.`,
      "step_3_content_4": `Deux types de points sont disponibles : Les points crédités et les points en cours d'attribution.`,
      "step_3_content_5": `Les points crédités figurent dans le « Solde de points » et peuvent être échangés.`,
      "step_3_content_6": `Les points en cours d'attribution figurent dans la section « Prochainement » et ne peuvent pas être échangés.`,
      "step_3_content_7": `Par contre, à la date spécifiée, les points de la section « Prochainement » seront transférés dans le « Solde de points ».`,
      "step_3_content_8": `Les points gagnés lors d'un achat, par exemple, seront affichés dans la section « Prochainement » tout de suite après l'expédition de la marchandise. Ces points seront ensuite transférés dans le « Solde de points » 30 jours plus tard.`,
      "step_3_img_1_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/7_2fd37d71-565f-4c6c-b4f1-303a884328c9.png?v=1690167660",
      "step_3_img_2_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/8_dea89087-13db-4e47-b0a8-5998a7e90a60.png?v=1690167661",
      "step_3_img_3_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/9_f17c40bd-ea78-40a9-8356-0888c83ffb47.png?v=1690167661",
      "step_3_img_4_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/10_456e2b2f-04b7-4374-aff0-e32a330f1646.png?v=1690167662",
      "step_3_img_1_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/4.1.png?v=1690167940",
      "step_3_img_2_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/4.2.png?v=1690167940",
      "step_3_img_3_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/4.3.png?v=1690167939",
      "step_3_img_4_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/4.4.png?v=1690167941",

      "step_4_title": "Étape 4 - Échange des points Jackery",
      "step_4_content_1": `① Connectez-vous à <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=FR" target="_blank">votre page de membre</a>, cliquez sur « Mes récompenses - à échanger » ou consultez le tableau « Obtenez des récompenses par échange de vos points »`,
      "step_4_content_2": `La liste des récompenses disponibles sera mise en évidence et vous pourrez vous en servir avec votre solde de points actuel.`,
      "step_4_content_3": `② Faites votre choix parmi les récompenses proposées et cliquez sur « Échanger », appuyez sur « Confirmer » dans la fenêtre contextuelle et vous obtiendrez les récompenses souhaitées.`,
      "step_4_img_1_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/11_a3fb0718-57ca-40fe-9c87-c26ccbcb963d.png?v=1690167662",
      "step_4_img_2_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/12_34bdc209-5b14-4b9a-8eb9-f3cd983fdd0e.png?v=1690167662",
      "step_4_img_3_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/13_66eddb94-dcbc-41e9-a904-4ce432c8b258.png?v=1690167660",
      "step_4_img_4_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/14.png?v=1690167660",
      "step_4_img_1_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/4.5.png?v=1690167941",
      "step_4_img_2_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/4.6.png?v=1690167939",
      "step_4_img_3_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/5_4535208f-f2fd-421c-ba04-5b8371c1f9d4.png?v=1690167941",
      "step_4_img_4_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/7_96ce436f-728d-4446-9660-b291ccbbda26.png?v=1690167941",

      "step_5_title": "Étape 5 - Utilisation des récompenses",
      "step_5_content_1": `Pour échanger des points Jackery contre des bons de réduction ou des cartes-cadeaux, il vous suffit de cliquer sur « Copier et utiliser » pour que le code soit copié. Vous pouvez faire vos achats sur le site officiel de Jackery, utiliser le code au moment du paiement et réaliser des économies sur vos achats.`,
      "step_5_content_2": `Vous pouvez obtenir gratuitement le produit Jackery si vous avez échangé des points Jackery contre un produit Jackery en introduisant le code de réduction au moment de la validation de votre commande.`,
      "step_5_img_1_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/15_d1d70b04-4b94-42df-a722-30adcee6a02d.png?v=1690167661",
      "step_5_img_2_pc": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/16_5b7ec646-98cd-4b5e-926f-9cfe951952ba.png?v=1690167661",
      "step_5_img_1_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/8_d97f2686-84b0-4456-b9ec-4a35bd0d9d41.png?v=1690167941",
      "step_5_img_2_mb": "https://cdn.shopify.com/s/files/1/0695/1105/3598/files/9_6116466c-d745-4b3c-9b49-cef07f903363.png?v=1690167938",
    },
    "faq": {
      "title": "FOIRE AUX QUESTIONS",
      "membership": {
        "title": "Adhésion à Jackery",
        "question_1": "1. Qu'est-ce que l'adhésion à Jackery?",
        "answer_1": `L'adhésion à Jackery constitue un programme de points grâce auquel nos clients (comme vous !) gagnent des points sur le site <a class="text-primary hover:text-primary" href="https://fr.jackery.com">fr.jackery.com</a>. Vos points peuvent être échangés contre des récompenses. L'adhésion est totalement gratuite. Pour nous, c'est une façon de vous remercier et de vous montrer à quel point nous apprécions votre fidélité.`,
        "question_2": "2. Comment puis-je devenir membre de Jackery?",
        "answer_2": `
          Pour devenir membre de Jackery, vous devez créer un compte Jackery.
          <br/>
          Si vous n'en avez pas, inscrivez-vous dès maintenant. Vous trouverez ci-dessus un guide <a class="text-primary hover:text-primary" href="/index/guide" target="_blank">étape par étape</a> pour vous aider à comprendre comment procéder.
          <br/>
          Si vous avez déjà un compte sur le site officiel de Jackery, vous pouvez l'activer ou vous connecter à votre compte existant.
        `,
        "question_3": "3. Dois-je me réinscrire sur l'autre site de Jackery si je me suis déjà inscrit?",
        "answer_3": "Une fois que l'utilisateur s'est inscrit, son compte est compatible avec tous les sites officiels de Jackery.",
        "question_4": "4. Si j'ai plusieurs comptes Jackery, puis-je les fusionner en un seul?",
        "answer_4": "Si vous inscrivez avec différentes adresses e-mail, l'identifiant Jackery sera différent et ne pourra pas être fusionné.",
        "question_5": "5. Comment puis-je annuler mon adhésion à Jackery?",
        "answer_5": `
          Contactez notre service clientèle pour annuler votre compte. Les points seront remis à zéro.
          <br/>
          Les utilisateurs déconnectés peuvent redevenir membres de Jackery par le biais de la procédure d'inscription et les points seront recalculés.
          <br/>
          Si vous souhaitez vous réinscrire en tant que membre, afin de garantir une connexion réussie, veuillez utiliser une adresse e-mail différente pour l'inscription.
        `,
      },
      "earn_points": {
        "title": "Gagner des points",
        "question_1": "1. Comment gagner des points?",
        "answer_1": `
          Lorsque vous devenez membre de Jackery, vous pouvez gagner des points en accomplissant des tâches spécifiques sur le site officiel. Voici quelques informations à ce sujet.
          <br/>
          <br/>
          ① Enregistrement de l'adhésion : si vous êtes inscrit avant avril 2022, vous devez activer votre adhésion en vous connectant et en vérifiant votre compte. Vous pouvez gagner des points pour votre première inscription ou activation.
          <br/>
          ② Abonnement à l'adresse e-mail : vous pouvez gagner des points en vous abonnant une fois à l'adresse e-mail ; il n'y a pas de points pour un réabonnement après un désabonnement. Les abonnements multi-sites sont considérés uniquement comme un seul point d'abonnement.
          <br/>
          ③ Abonnement SMS : vous pouvez gagner des points en vous abonnant une fois aux services SMS. Pour cela, vous devez entrer votre numéro de téléphone dans « Mon compte-Mon profil » ou sur la page « Extension de la garantie », mais pas seulement en vous abonnant à partir du pied de page du site web.Les abonnements multi-sites sont considérés uniquement comme un seul point d'abonnement.
          <br/>
          ④ Indiquez votre date de naissance : vous pouvez gagner des points en renseignant votre date d'anniversaire. Obtenez des points à chaque anniversaire et doublez vos points shopping. Vous ne pouvez modifier votre date d'anniversaire qu'une seule fois.
          <br/>
          ⑤ Extension de la garantie : une fois que vous avez étendu la garantie du produit à partir du site Web officiel avec succès, les points seront attribués conformément aux règles.
          <br/>
          ⑥ Points d'achat : une fois que vous avez acheté des produits sur le site officiel de Jackery, vous pouvez obtenir des points en fonction de la valeur totale des commandes. En outre, la 2e/5e/8e commande ou la valeur d'achat atteignant 5000€/8000€/12000€ vous permettent d'obtenir des points supplémentaires respectivement. Le montant des commandes passées sur des plateformes tierces ne sera calculé pour les points que si ces commandes ont été enregistrées pour l'extension de garantie. (1 euro dépensé = 1 point gagné)
          <br/>
          ⑦ Joindre des activités de points supplémentaires : vous pouvez obtenir des points en participant à des activités de points supplémentaires sur le site officiel de Jackery. Pour plus d'informations, veuillez vous référer à la description de l'activité sur la page d'adhésion à Jackery.
        `,
        "question_2": "2. Quand les points seront-ils crédités sur le compte?",
        "answer_2": `
          Il existe deux types d'heures d'arrivée des points :
          <br/>
          <br/>
          ① Délivrance rapide : Une fois que vous avez accompli les tâches spécifiées sur le site officiel, telles que l'enregistrement, la souscription, le remplissage de la déclaration d'anniversaire et l'extension de la garantie, les points seront crédités sur votre compte dans les 24 heures.
          <br/>
          ② Délivrance différée : Les points liés aux achats seront émis en tant que « points à venir » après l'expédition des marchandises. 30 jours plus tard, ils deviendront actifs en tant que « solde de points ». (Lorsqu'une commande comprend plusieurs produits, la date d'expédition du premier produit prévaut). Le nombre de points que vous recevez pour une commande admissible est basé sur son paiement total réel.
          <br/>
          <br/>
          Il convient de noter que les points en suspens ne peuvent pas être utilisés pour obtenir des avantages sur le site officiel.
        `,
        "question_3": "3. Que faire si mes points n'ont pas été correctement crédités sur mon compte?",
        "answer_3": `
          Si vous avez des questions concernant votre compte de membre et votre solde de points, veuillez contacter notre service clientèle à l'adresse <a class="text-primary hover:text-primary" href="mailto:hello.eu{'@'}jackery.com">hello.eu{'@'}jackery.com</a>.
        `,
        "question_4": "4. Les points expirent-ils?",
        "answer_4": `
          Les points Jackery sont valables pendant deux ans. Les points gagnés par les utilisateurs expireront dans 2 ans à compter du lancement officiel du programme d'adhésion（29 juin 2023), et les points expirés seront déduits de leur compte.
          <br/>
          Afin de protéger vos droits et vos intérêts, veuillez vérifier et échanger vos points disponibles à temps.
        `,
        "question_5": "5. Recevrai-je des points pour des transactions effectuées avant le lancement du programme d'affiliation Jackery?",
        "answer_5": "Oui! Le 29 juin 2023, l'adhésion à Jackery sera officiellement lancée. Vous recevrez des points originaux correspondants à vos achats ou à l'accomplissement de tâches spécifiques sur le site Web officiel de Jackery (à partir du 1er janvier 2020), à condition que vous inscriviez en tant que membre de Jackery. Les points originaux expireront dans 2 ans (à compter du 29 juin 2023).",
        "question_6": "6. Est-ce que les taxes ou les frais d'expédition peuvent être crédités aux points?",
        "answer_6": "Non. Lors du calcul des points, le membre n'est pas tenu de payer les frais d'expédition et toutes les taxes, y compris, mais sans s'y limiter, les taxes fédérales, les taxes d'État et les taxes locales ou les taxes d'utilisation.",
        "question_7": "7. Qu'advient-il de mes points si j'annule la commande, si je retourne le produit ou si je demande un remboursement partiel d'un produit?",
        "answer_7": "En cas d'annulation, de remboursement ou de retour d'une commande, les points obtenus pour cet achat seront annulés ou diminués. Les points seront déduits pour les remboursements partiels causés par des ajustements de prix.",
        "question_8": "8. Déclaration spéciale concernant les points intersites / le taux de change / la méthode d'affichage des points",
        "answer_8": `
          Les points Jackery peuvent être gagnés et utilisés sur tous les sites officiels de Jackery.
          <br/>
          Si vous disposez de soldes de points sur les différents sites officiels de Jackery, le système affichera la valeur totale des points après conversion des soldes sur les différents sites par le biais des taux de change.
          <br/>
          La conversion du nombre total de points se fera sur la base du dernier taux de change du dollar américain le 1er de chaque mois, de sorte que le solde de points changera en fonction du taux de change chaque mois.
          <br/>
          Les points sont sous forme de nombres entiers, les fractions seront comptabilisées dans la valeur des points, mais ne seront pas affichées et ne pourront pas être échangées.
        `,
      },
      "redeem": {
        "title": "Échanger des points",
        "setting1": "Pour les utilisateurs, l'échange peut se faire jusqu'à {time} fois au cours des {num} derniers jours.",
        "setting2": "Les utilisateurs ne peuvent échanger que {time} fois.",
        "setting3": "Les utilisateurs peuvent échanger des avantages tant qu'ils disposent d'un solde de points suffisant.",
        "question_1": "1. Comment échanger des points Jackery contre des avantages?",
        "answer_1": `
          Vous pouvez vous connecter sur la page d'accueil des membres (membership.jackery.com). Les avantages qui peuvent être échangés sont mis en évidence. Vous pouvez sélectionner les avantages et cliquer sur « Échanger ».
          <br/>
          Si vous connectez à votre centre de comptes, vous pouvez cliquer sur « Solde de points » pour accéder à la page d'accueil des membres et échanger vos points contre des avantages.
          <br/>
          Les points ne sont pas remboursables une fois qu'ils ont été échangés. Veuillez vous assurer que vous souhaitez échanger vos points avant de procéder à l'échange.
        `,
        "question_2": "2. Contre quels avantages les points Jackery peuvent-ils être échangés?",
        "answer_2": `
          Nous vous offrons une multitude d'avantages. Les points peuvent être échangés contre des coupons, des cartes-cadeaux, des produits Jackery, des cadeaux intéressants et bien plus encore. Les avantages sont uniquement valables sur le site où ils ont été collectés ou échangés et ne peuvent pas être utilisés sur d'autres sites. Veuillez utiliser les récompenses pendant la période de validité. Pour plus de détails, consultez la page d'accueil des membres. La limite d'échange autorisée pour les utilisateurs est fixée à un nombre limité de fois au cours d'une période donnée ; une alerte vous sera transmise par le système lorsque la limite d'échange sera atteinte. Dès votre entrée dans un nouveau cycle, il vous sera possible d'échanger à nouveau des récompenses.
          <br/>
          <br/>
          Remarques
          <br/>
          ① Bon d’achat Jackery : Chaque coupon a une réduction spécifique, un montant minimum de commande, des produits applicables et une période de validité. Veuillez vérifier les informations détaillées de chaque coupon lorsque vous l'utilisez. Les coupons ne peuvent pas être combinés ou empilés avec d'autres offres, y compris les ventes, les coupons, et autres. {0}
          <br/>
          ② Carte cadeau Jackery : Chaque carte cadeau a une dénomination et une période de validité spécifiques. Veuillez vérifier les informations complémentaires de chaque carte cadeau lors de son utilisation. {1}
          <br/>
          ③Produit Jackery：Cette offre n'est pas cumulable avec d'autres promotions, y compris les soldes et les coupons. Pour bénéficier de l'offre, il suffit de saisir le code et l'adresse de courriel correspondante au moment du paiement. Veuillez noter que chaque bon de réduction pour un produit Jackery ne peut être utilisé que pour l'achat d'un article spécifique. {2}
          <br/>
          ④ Cartes-cadeaux autres que celles de Jackery : Il existe un nombre limité de cartes-cadeaux autres que celles de Jackery. Lorsque l'inventaire total a été réclamé, vous pouvez recevoir un message de « rupture de stock » et vous ne pourrez pas échanger vos points. Les cartes-cadeaux autres que celles de Jackery ne s'affichent pas lorsqu'elles sont en rupture de stock. Pour plus de détails sur les règles d'utilisation des cartes-cadeaux autres que celles de Jackery, veuillez consulter le site officiel de la marque correspondante. {3}
        `,
        "question_3": "3. Comment vérifier les avantages que j'ai échangés?",
        "answer_3": `
          ① Connectez-vous au centre de compte (<a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=FR">account.jackery.com</a>), vérifiez-le dans « Mon portefeuille ». Ou connectez-vous à la page d'accueil des membres (<a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=FR">membership.jackery.com</a>), vérifiez dans « Mes récompenses ».
          <br/>
          ② Vous recevrez des courriels d'avis de collecte de récompenses, y compris des coupons, des cartes-cadeaux, des produits Jackery, etc.
        `,
        "question_4": "4. Mes récompenses, avantages et points peuvent-ils être convertis en espèces?",
        "answer_4": `
          Les récompenses, avantages et points obtenus dans le cadre du programme d'adhésion à Jackery n'ont pas de valeur monétaire, ne peuvent pas être convertis en espèces et ne peuvent pas être transférés à quelqu'un d'autre. (À l'exception de la carte-cadeau que vous pouvez envoyer à vos amis ou aux membres de votre famille).
          <br/>
          Il n'est pas permis de vendre à un tiers les avantages que vous avez échangés.
          <br/>
          Jackery a le droit d'intenter une action en justice contre les transactions non autorisées.
        `,
        "question_5": "5. Dois-je payer des frais d'expédition pour les produits échangés?",
        "answer_5": "Dans la plupart des cas, nous offrons des frais d'expédition gratuits pour les produits échangés. Toutefois, il se peut que vous deviez payer des frais d'expédition pour des produits spécifiques.",
        "question_6": "6. Puis-je annuler, retourner ou échanger des récompenses échangées avec des points?",
        "answer_6": `
          Une fois l'échange effectué, il ne peut être annulé et les récompenses ne peuvent être ni retournées ni échangées.
          <br/>
          Si le produit arrive endommagé, veuillez contacter le service clientèle à <a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=FR" target="_blank">support.jackery.com</a> dans les 7 jours.
        `,
        "question_7": "7. Les produits échangés peuvent-ils être expédiés avec des produits que j'ai achetés chez Jackery?",
        "answer_7": `
          Non. Les produits échangés (un article obtenu par l'échange de points ou par tirage au sort) seront expédiés séparément et livrés dans un délai de 60 jours ouvrables.
          <br/>
          Vous devez soumettre ou confirmer les informations relatives à l'adresse afin que le site officiel puisse organiser la livraison à temps.
        `,
      },
      "others": {
        "title": "Autres",
        "question_1": "1. Contactez-nous pour obtenir de l'aide",
        "answer_1": `
        Si vous avez d'autres questions, veuillez envoyer un courriel à notre service clientèle à l'adresse <a class="text-primary hover:text-primary" href="mailto:hello.eu{'@'}jackery.com">hello.eu{'@'}jackery.com</a>, visitez <a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=FR" target="_blank">support.jackery.com</a> pour obtenir de l'aide, ou cliquez pour nous contacter.
        `,
        "question_2": "2. Traitement des violations des règles relatives aux points de membres de Jackery",
        "answer_2": `En cas de malveillance ou de mensonge de la part d'un utilisateur dans l'obtention de points, l'utilisation de points ou l'obtention d'avantages, Jackery se réserve le droit de geler ou d'annuler les points de l'utilisateur, de cesser de fournir des services ou d'annuler le compte de l'utilisateur, selon le cas, et de ne pas accepter la demande de réinscription de l'utilisateur.`,
        "question_3": "3. Déclaration spéciale",
        "answer_3": `
          Tous les droits relatifs à l'adhésion à Jackery sont réservés par Jackery.
          <br/>
          Les avantages de l'adhésion peuvent changer périodiquement ; veuillez vous référer à la page d'adhésion pour les détails.
        `,
        "question_4": "4. Enregistrement malveillant (y compris l'utilisation de fausses informations ou la création de plusieurs comptes pour obtenir des récompenses supplémentaires) entraînera une disqualification",
        "answer_4": `
        Jackery se réserve tous les droits d'annuler les récompenses ou commandes obtenues par les méthodes suivantes :
          <br/>
          ① Utilisation de fausses informations : Il est strictement interdit d'utiliser de faux noms, adresses, adresses e-mail ou autres informations personnelles pour créer des comptes.
          <br/>
          ② Création de plusieurs comptes : Il est strictement interdit à un utilisateur unique de créer plusieurs comptes pour obtenir des récompenses ou des réductions supplémentaires. Cela inclut l'utilisation de différentes adresses e-mail ou de toute information d'identité pour enregistrer plusieurs comptes.
          <br/>
          ③ Outils d'enregistrement automatisés : L'utilisation de tout outil automatisé ou script pour l'enregistrement en masse de comptes est strictement interdite.
          <br/>
          ④ Comportement malveillant : Tout autre comportement jugé malveillant including exploitation of system vulnerabilities or engagement in fraudulent transactions, is strictly prohibited.
        `,
      }
    }
  },
  // overview
  "overview": {
    "unit": 'points',
    "pointsBalance": "Solde de points",
    "comingSoon": "À venir",
    "benefitsForYou": "Avantages pour vous",
    "welcomeGift": "Cadeau de bienvenue",
    "welcomeGiftInfo": "100% gagnant, des coupons / cartes cadeaux / articles réels vous attendent!",
    "firstRegistration": "Première inscription",
    "firstRegistrationInfo": "Obtenez {num} de réduction lors de votre première inscription à Jackery.",
    "largeOrderBenefits": "Avantages pour les grosses commandes",
    "largeOrderBenefitsInfo": "Cadeau gratuit pour toute commande supérieure à {num}.",
    "referral": "Référer un ami",
    "referralInfo": "Offrez une réduction à vos amis et recevez des récompenses lorsqu'ils s'inscrivent et achètent !",
  },
  // 我的钱包
  "wallet": {
    "unit": 'points',
    "jackeryPointsBalance": "Solde de points",
    "comingSoon": "À venir",
    "pointsDetails": "Mon activité",
    "redeemMyCoins": "Échanger mes points",
    "coupon": "Coupon",
    "giftCard": "Carte cadeau",
    "jackeryProducts": "Produit Jackery",
    "extras": "Extras",
    "used": "Utilisé",
    "expired": "Expiré",
    "daysLeft": "{num} jours restants",
    "code": "Code",
    "rules": "Règles",
    "copyAndUse": "Copier et utiliser",
    "noCoupon": "Pas de coupons disponibles",
    "noGiftCard": "Pas de carte cadeau disponible",
    "noJackeryProducts": "Aucun coupon de produit Jackery n'est disponible",
    "noExtras": "Pas d'extras disponibles",
    "redeemMyPoints": "Échanger mes points",
    "viewMore": "Voir Plus",
    "couponRules": {
      "one": {
        "title": "1. Qu'est-ce qu'un coupon ?",
        "step1": "1) Les coupons sont des méthodes de retour clients que Jackery distribue aux utilisateurs via des activités promotionnelles, de marketing ciblé, d'échange de points de membre ou autres formes pour réduire les prix d'achat.",
        "step2": "2) Les coupons de Jackery existent sous forme électronique et peuvent être utilisés pour acheter des produits sur le site officiel de Jackery.",
        "step3": `3) Lors de l’achat et du paiement d'une commande, entrez le code de réduction du "coupon Jackery". Le montant du panier sera automatiquement déduit du montant total de la commande. Le coupon ne peut pas être converti en espèces.`,
      },
      "two": {
        "title": "2. Comment vérifier les coupons ?",
        "step1": "1) Inscrivez-vous en tant que membre Jackery et vérifiez les coupons (y compris les informations inutilisées, utilisées et expirées) dans le Centre utilisateur - Mon portefeuille - Coupons.",
        "step2": "2) Veuillez vous référer à la période de validité du coupon. Veuillez l’utiliser pendant la période de validité, aucun remboursement ne sera effectué s’il est utilisé en retard.",
      },
      "three": {
        "title": "3. Comment utiliser le coupon :",
        "step1": "1) Chaque coupon a une remise spécifique, un montant minimum de commande, des produits applicables et une période de validité. Veuillez vérifier les informations détaillées de chaque coupon lors de leur utilisation.",
        "step2": "2) Emplacements applicables : Le le coupon est valable sur le site où il a été collecté ou échangé et ne peut pas être utilisés sur plusieurs sites à la fois. ",
        "step3": "3) Un seul coupon peut être utilisé pour une même commande et est non cumulable avec des promotions ; Si une commande comprend plusieurs articles, le coupon ne s'applique qu'aux articles éligibles.",
        "step4": "4) Certains coupons sont liés à un compte et sont uniquement destinés à un usage personnel. L'adresse e-mail sur la page de commande doit être cohérente avec l'adresse e-mail du destinataire du coupon et ne peut pas être transférée ; Certains coupons peuvent être offerts à d'autres utilisateurs, veuillez vous référer aux instructions d'utilisation du coupon pour plus de détails.",
        "step5": "5) Le coupon peut être utilisé avec des cartes-cadeaux.",
        "step6": "6) Les membres peuvent échanger des points contre des coupons. Après déduction des points, les points et les coupons sont irrévocables. Il peut y avoir une limite sur le nombre d'échanges de points de membres avec des coupons dans un certain laps de temps",
        "step7": "7) Si la commande utilise un coupon, le coupon ne peut pas être retourné en cas de remboursement."
      },
      "four": {
        "title": "4. Traitement de la violation des règles d'utilisation des coupons.",
        "step1": "Si l'utilisateur utilise un coupon qui est falsifié, acheté, vendu ou transféré d'une autre personne, Jackery a le droit de geler ou d'invalider le coupon, Jackery a le droit de geler ou d'invalider les coupons détenus par les utilisateurs concernés, d'annuler les commandes qui ont utilisé des coupons, annuler la qualification du coupon de l'utilisateur, cesser de fournir des services ou annuler le compte de l'utilisateur, et a le droit de ne pas accepter la demande de réinscription de l'utilisateur."
      }
    },
    "giftRules": {
      "one": {
        "title": "1. Qu'est-ce qu'une carte cadeau Jackery ?",
        "step1": "1) Les cartes-cadeaux sont des méthodes de retour clients que Jackery distribue aux utilisateurs via des activités promotionnelles, de marketing ciblé, d'échange de points de membre et d'autres formes pour réduire les paiements d'achat.",
        "step2": "2) Les cartes cadeaux lancées par Jackery existent sous forme électronique et peuvent être utilisées pour acheter des produits sur le site officiel de Jackery.",
        "step3": "3) Lors de l'achat d'ordres de paiement, entrez le code de la carte-cadeau Jackery et le montant de la carte sera automatiquement déduit du montant total de la commande. Les cartes-cadeaux ne peuvent pas être converties en espèces.",
      },
      "two": {
        "title": "2. Comment voir les cartes-cadeaux?",
        "step1": "1) Inscrivez-vous en tant que membre Jackery et vérifiez les cartes-cadeaux (y compris les informations inutilisées, utilisées et expirées) dans le Centre utilisateur - Mon portefeuille - Cartes-cadeaux.",
        "step2": "2) Veuillez vous référer à la durée de validité de la carte cadeau. Veuillez l'utiliser pendant la période de validité et Jackery ne rembourse aucun coupon expiré.",
      },
      "three": {
        "title": "3. Instructions d'utilisation des cartes-cadeaux :",
        "step1": "1) Chaque carte-cadeau a une dénomination et une durée de validité spécifiques. Veuillez vérifier les informations de chaque carte-cadeau avant de l'utiliser.",
        "step2": "2) Emplacements applicables : la carte-cadeau est valable sur le site où elle a été collectée ou échangée et ne peut pas être utilisée sur plusieurs sites à la fois.",
        "step3": "3) Il n'y a pas de montant minimum de commande requis lors de l'utilisation d'une carte-cadeau. Cela s'applique à tous les produits.",
        "step4": "4) Les cartes-cadeaux ne sont pas limitées à l'utilisation personnel d'un compte et peuvent être transférées en cadeau à des amis.",
        "step5": "5) Plusieurs cartes-cadeaux peuvent être utilisées pour la même commande, peuvent être combinées avec des promotions et peuvent aussi être utilisées avec des coupons.",
        "step6": "6) Les membres peuvent échanger des points contre des cartes-cadeaux, et après déduction des points, les points et les cartes-cadeaux sont irrévocables.",
        "step7": "7) Il peut y avoir une limite sur le nombre d'échanges de points de membres avec des cartes-cadeaux dans un certain laps de temps. Le système vous notifiera lorsque le nombre de fois sera épuisé.",
        "step8": "8) Si la commande utilise une carte cadeau, la carte cadeau ne peut pas être retournée en cas de retour d'article.",
      },
      "four": {
        "title": "4. Traitement de la violation des règles d'utilisation des cartes-cadeaux.",
        "step1": "Si vous utilisez un coupon qui est falsifié, acheté, vendu ou transféré par une autre personne, Jackery a le droit de geler ou d'invalider la carte-cadeau, d'annuler les commandes utilisant des cartes-cadeaux, d'annuler la qualification de la carte-cadeau de l'utilisateur, de cesser de fournir des services ou d'annuler compte de l'utilisateur et a le droit de ne pas accepter la demande de réinscription de l'utilisateur."
      }
    },
    "productRules": {
      "one": {
        "title": "1. Qu'est-ce qu'un coupon de produit Jackery?",
        "step1": "1) Les cadeaux de produits Jackery sont un type de coupon que Jackery distribue aux utilisateurs via des activités promotionnelles, de marketing ciblé, d'échange de points de membre et d'autres formulaires de rétroaction aux clients.",
        "step2": "2) Le produit Jackery, lancé par Jackery, existe en électronique sous forme de coupons et peut être acheté via une réduction de crédit totale ou partielle pour les produits correspondants sur le site officiel de Jackery.",
        "step3": "3) Lors de l'achat et du paiement d'une commande, entrez le code de réduction du produit Jackery. Le prix de la carte sera automatiquement déduit du montant total de la commande. Les coupons ne peuvent pas être convertis en espèces.",
      },
      "two": {
        "title": "2. Comment vérifier le coupon du produit Jackery?",
        "step1": "1) Inscrivez-vous en tant que membre Jackery et vérifiez les coupons des produits Jackery (y compris les informations inutilisées, utilisées et expirées) dans le Centre utilisateur - Mon portefeuille - Produit Jackery.",
        "step2": "2) Veuillez vous référer à la période de validité du coupon du produit Jackery. Veuillez l'utiliser pendant la période de validité. Jackery ne rembourse aucun coupon expiré.",
      },
      "three": {
        "title": "3. Instructions pour l'utilisation des coupons de produits Jackery :",
        "step1": "1) Chaque coupon de produit Jackery a une période de validité spécifique, il est nécessaire de vérifier ses informations en détails lors de son utilisation.",
        "step2": "2) Emplacements applicables : coupons de produits Jackery réclamés ou échangés sur ce site sont uniquement destinés à être utilisés sur ce site et ne peuvent pas être utilisés sur d'autres sites.",
        "step3": "3) Un seul coupon peut être utilisé pour une même commande et il est non cumulable avec des promotions ; Si plusieurs produits apparaissent sur la même commande, le coupon n'est valable que pour le produit correspondant et n'est pas valable pour les autres produits.",
        "step4": "4) Le coupon Jackery Product est lié à un compte et est uniquement destiné à un usage personnel. L'adresse e-mail sur la page de paiement doit être cohérente avec l'adresse e-mail du destinataire du coupon et ne peut pas être transférée.",
        "step5": "5) Les membres peuvent utiliser des points pour échanger des coupons de produits Jackery. Après déductions de points, les points et les coupons sont irrévocables. Il peut y avoir une limite sur le nombre d'échanges de points de membres avec des coupons dans un certain laps de temps.",
        "step6": "7) Si la commande utilise un coupon, le coupon ne peut pas être retourné en cas de retour du produit."
      },
      "four": {
        "title": "4. Conditions d'échange de cadeaux Jackery",
        "step1": "1) Connectez-vous au site officiel de Jackery et accédez à la page de détails du produit correspondant, cliquez pour acheter",
        "step2": "2) Entrez le code de réduction correspondant sur la page de paiement (collez le code {XXX} lors de la vérification)",
        "step3": "3) Veuillez noter que l'e-mail sur la page de paiement est l'e-mail de réception des coupons"
      },
      "five": {
        "title": "5. Traitement de la violation des règles d'utilisation des coupons.",
        "step1": "Si vous utilisez un coupon qui est falsifié, acheté, vendu ou transféré par une autre personne, Jackery a le droit de geler ou d’invalider les coupons, d'annuler les commandes qui ont utilisé des coupons, d'annuler la qualification du coupon de l'utilisateur, d'arrêter de fournir des services ou d'annuler le compte, et a le droit de ne pas accepter la demande de réinscription de l'utilisateur."
      }
    },
    "extraRules": {
      "one": {
        "title": "1. Qu'est-ce que Jackery Extras ?",
        "step1": "1) Jackery Extras, ce sont des cadeaux de marques autres que Jackery, y compris des bons, des coupons, des cartes-cadeaux et des cadeaux physiques.",
        "step2": "2) Jackery distribue aux utilisateurs via des activités promotionnelles, de marketing ciblé, d'échange de points de membre et d'autres formulaires de rétroaction aux clients. Après avoir échangé des points pour des Jackery Extras, les points seront déduits et les points et les cadeaux ne pourront pas être remboursé.",
      },
      "two": {
        "title": "2. Comment voir vos Jackery Extras ?",
        "step1": "1) Inscrivez-vous en tant que membre Jackery et consultez-le dans le Centre utilisateur - Mon portefeuille - Extras.",
        "step2": "2) Utilisez vos offres pendant la période de validité. Cela ne peut pas être réémis une fois que cela a expiré.",
        "step3": "3) Les détails de l'offre seront envoyés par e-mail. Le demandeur peut consulter les informations pertinentes depuis sa boîte aux lettres."
      },
      "three": {
        "title": "3. Réclamer des cadeaux Jackery Extras",
        "step1": {
          "title": "1) Règles lors de la réclamation d'un cadeau physique :",
          "tip1": "① Le cadeau physique non Jackery doit être clairement rempli dans l'adresse.",
          "tip2": "② Votre article sera envoyé sous 30 à 90 jours après l'avoir réclamé.",
          "tip3": "③ Les détails d'expédition, y compris le numéro de suivi, seront envoyés par e-mail une fois l'article expédié. ",
          "tip4": "④ Si les prix physiques reçus sont endommagés ou présentent d'autres problèmes, n'hésitez pas à contacter notre service client.",
        },
        "step2": {
          "title": "2) Considérations sur les cartes-cadeaux virtuelles de marque autre que Jackrey",
          "tip1": `① Les détails des cartes-cadeaux virtuelles de marque autre que Jackry, y compris "quoi et comment", seront envoyés par e-mail.`,
          "tip2": "② Le délai de réception des cartes cadeaux virtuelles de marques autres que Jacekry est de XX ans, activé à réception. Après l'activation, la période de validité de la carte-cadeau sera déterminée en fonction de la période de validité spécifiée par la marque correspondante. Veuillez vous référer au site Web de la marque correspondante pour plus de détails",
          "tip3": "③ Les règles d'utilisation spécifiques aux cartes-cadeaux virtuelles de marques autres que Jacekry se trouvent dans l'introduction des règles de la plate-forme d'échange de cartes-cadeaux",
        }
      },
      "four": "4. En cas de litige, Jackery se réserve le droit de décision finale."
    }
  },
  // My Service
  "service": {
    'imgText1': 'Bienvenue sur le',
    'imgText2': "Service d'assistance Jackery",
    'pcImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_f2d45c3c-6199-4b74-85d8-bc1977a5d9b9.png?v=1683191312',
    'mbImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_efc7e4e4-a728-40e4-ab7b-31aab55839af.png?v=1683191345',
    'text': `Aller sur Service d'assistance Jackery pour un service intelligent`,
    'contactUs': 'Nous contacter',
    'viewSupport': `Accéder au service d'assistance`,
  },
  // My Jackery Life
  "jackeryLife": {
    'title': 'Jackery Life',
    'imgText1': 'Jackery Life',
    'imgText2': 'Vivre avec Jackery Solar',
    'pcImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/2_2bf016ce-d9a0-4e4b-a163-e4272aba015d.png?v=1683189189',
    'mbImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/Jackery_life_8ab97bcf-fd91-4e1d-a1d4-8c378f824fdb.png?v=1683189235',
    'text': 'Partagez vos histoires avec Jackery sur Jackery Life, et courez la chance de gagner de grands prix !',
    'learnMore': 'En savoir plus',
  },
  "wishlist": {
    "empty": "Aucun produit dans la liste d'envies",
    "shopNow": "Faire du shopping maintenant",
    "modalTitle": "Êtes-vous sûr(e) de vouloir retirer ce produit de votre liste d'envies ?",
    "modalCancel": "Annuler",
    "modalOk": "Confirmer",
    "removeToast": "Le produit a été retiré de votre liste d'envies.",
    "createdDesc": "Date d'ajout la plus récente",
    "createdAsc": "Date d'ajout la plus ancienne",
    "priceDesc": "Prix du plus élevé au plus bas",
    "priceAsc": "Prix du plus bas au plus élevé",
    "purchased": "Acheté",
    "buyNow": "Acheter maintenant",
    "selectAll": "Tout sélectionner",
    "selected": "Sélectionné",
    "amount": "Montant total",
    "addOn": "Ajouté le",
    "notGoodsGotoBuy": "Veuillez sélectionner un article",
    "viewMoreHref": "https://fr.jackery.com/"
  },
  "old-invite-new": {
    "myRewardsTitle": "Mes Récompenses",
    "myRewardsSubTitle": "Gérez et utilisez les récompenses réclamées ici. Pour les récompenses expirées et utilisées, veuillez les vérifier dans Mon Portefeuille.",
    "myRewardsNoData": "Invitez vos amis à s'abonner ou à acheter, et réclamez vos récompenses sur la page d'accueil ci-dessous.",
    "viewMore": "Voir Plus",
    "myReferralsTitle": "Mes recommandations",
    "myReferralsSubTitle": "Suivez la progression des référenceset réclamez les récompenses. Cliquez sur la récompense pour plus de détails.",
    "myReferralsNoData": "Invitez vos amis à s'abonner ou à effectuer un achat, ensuite réclamez vos récompenses ici.",
    "claim": "Réclamer",
    "claimed": "Réclamé",
    "expired": "Expiré",

    // 补充
    "myReferral": "Ma référence",
    "enterEmail": "Saisissez votre adresse de courriel",
    "invalidEmail": "Adresse courriel invalide",
    "messageSent": "Message envoyé",
    "copy": "Copiez",
    "copied": "Copié",
    "copiedSuccess": "copié avec succès.",
    "friendEmail": "Entrez l'adresse courriel de votre ami(e)",
    "referee": "Référent",
    "referralType": "Type de recommandation",
    "rewards": "Récompenses",
    "status": "Statut",
    "instagramCopySuccessTip": "Le lien de parrainage a copié, collé et partagé sur Instagram",
  }
}
